import React, { useState } from 'react';
import FormClients from '../component/FormClients';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { postClient } from '../redux/action';

export default function CreateClient({ setAddClient, setId }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [disabled, setDisabled] = React.useState(false);
    const [state, setState] = useState({
        name: '',
        material: '',
        address: '',
        neighborhood: '',
        city: '',
        telephone: '',
        method_shipping: '',
        details: '',
        identification: ''
    })

    const [errors, setErrors] = useState({
        name: {
            bol: false,
            msg: ''
        },
        material: {
            bol: false,
            msg: ''
        },
        address: {
            bol: false,
            msg: ''
        },
        neighborhood: {
            bol: false,
            msg: ''
        },
        city: {
            bol: false,
            msg: ''
        },
        telephone: {
            bol: false,
            msg: ''
        },
        method_shipping: {
            bol: false,
            msg: ''
        },
        identification: {
            bol: false,
            msg: ''
        }
    })

    const onSubmit = async (e) => {
        e.preventDefault();

        const err = {}

        Object.keys(state).filter(x => x !== "details").map(x => {
            if (x === "name") {
                if (!state[x]) {
                    return err[x] = {
                        bol: true,
                        msg: 'Este campo no puede estar vacío'
                    };
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    };
                }
            }else {
                return err[x] = {
                    bol: false,
                    msg: ''
                };
            }
        })

        setErrors(err);

        if (Object.values(err).filter(x => x.bol === true).length === 0) {
            setDisabled(true)
            setTimeout(() => {
                setDisabled(false);
            }, 1500)

            if (history.location.pathname.includes('clients')) {
                dispatch(postClient(state))
                setTimeout(()=> {
                    history.push(`/clients`)
                }, 800)
            } else {
                setTimeout(()=> {
                    setAddClient(false)
                }, 1000)
                dispatch(postClient(state, setId))
            }
        }
    }

    return (
        <FormClients
            title={"Agregar cliente"}
            state={state}
            setState={setState}
            errors={errors}
            back={'/clients'}
            onSubmit={onSubmit}
            disabled={disabled}
        />
    )
}
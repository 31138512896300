import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import InputControlled from './InputControlled';

export default function FormVendors({ title, state, errors, setState, back, onSubmit, id, setModal, disabled }) {
    const history = useHistory();

    return (
        <>
            <div className={history.location.pathname.includes('vendors') ? "card mb-3" : null}>
                {
                    history.location.pathname.includes('vendors') ? (
                        <div className="card-header mt-1" 
                            // style={history.location.pathname.includes('vendors') ? { padding: "1.5rem 2.5rem 0" } : null}
                        >
                            <div className="card-title">
                                <h2>
                                    {title}
                                </h2>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }
                <div className="card-body pt-1">
                    <Row>
                        <div className="" 
                            // style={history.location.pathname.includes('vendors') ? { padding: "1.5rem 2.5rem 0" } : null}
                        >
                            <form style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                                <Row>
                                    <Col sm="12" md="6">
                                        <InputControlled
                                            classContainer={'form-group'}
                                            classLabel={'form-label fs-6'}
                                            textLabel={'Nombre'}
                                            typeBtn={'text'}
                                            nameBtn={'name'}
                                            styleBtn={errors.name.bol ?
                                                "form-control p-form  br-btn-t error-input" :
                                                "form-control p-form  br-btn-t"
                                            }
                                            valueBtn={state.name}
                                            setValue={setState}
                                            state={state}
                                            read={history.location.pathname.includes('vendors/view') ?
                                                true : undefined
                                            }
                                            classError={errors.name.bol ?
                                                "invalid-input" :
                                                "valid-input"
                                            }
                                            textError={errors.name.msg}
                                        />
                                    </Col>
                                    <Col sm="12" md="6">
                                        <InputControlled
                                            classContainer={'form-group'}
                                            classLabel={'form-label fs-6'}
                                            textLabel={'Material o servicio que ofrece'}
                                            typeBtn={'text'}
                                            nameBtn={'material'}
                                            styleBtn={errors.material.bol ?
                                                "form-control p-form  br-btn-t error-input" :
                                                "form-control p-form  br-btn-t"
                                            }
                                            valueBtn={state.material}
                                            setValue={setState}
                                            state={state}
                                            read={history.location.pathname.includes('vendors/view') ?
                                                true : undefined
                                            }
                                            classError={errors.material.bol ?
                                                "invalid-input" :
                                                "valid-input"
                                            }
                                            textError={errors.material.msg}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12" md="4">
                                        <InputControlled
                                            classContainer={'form-group'}
                                            classLabel={'form-label fs-6'}
                                            textLabel={'Dirección'}
                                            typeBtn={'text'}
                                            nameBtn={'address'}
                                            styleBtn={errors.address.bol ?
                                                "form-control p-form  br-btn-t error-input" :
                                                "form-control p-form  br-btn-t"
                                            }
                                            valueBtn={state.address}
                                            setValue={setState}
                                            state={state}
                                            read={history.location.pathname.includes('vendors/view') ?
                                                true : undefined
                                            }
                                            classError={errors.address.bol ?
                                                "invalid-input" :
                                                "valid-input"
                                            }
                                            textError={errors.address.msg}
                                        />
                                    </Col>
                                    <Col sm="12" md="4">
                                        <InputControlled
                                            classContainer={'form-group'}
                                            classLabel={'form-label fs-6'}
                                            textLabel={'Barrio'}
                                            typeBtn={'text'}
                                            nameBtn={'neighborhood'}
                                            styleBtn={errors.neighborhood.bol ?
                                                "form-control p-form  br-btn-t error-input" :
                                                "form-control p-form  br-btn-t"
                                            }
                                            valueBtn={state.neighborhood}
                                            setValue={setState}
                                            state={state}
                                            read={history.location.pathname.includes('vendors/view') ?
                                                true : undefined
                                            }
                                            classError={errors.neighborhood.bol ?
                                                "invalid-input" :
                                                "valid-input"
                                            }
                                            textError={errors.neighborhood.msg}
                                        />
                                    </Col>
                                    <Col sm="12" md="4">
                                        <InputControlled
                                            classContainer={'form-group'}
                                            classLabel={'form-label fs-6'}
                                            textLabel={'Ciudad'}
                                            typeBtn={'text'}
                                            nameBtn={'city'}
                                            styleBtn={errors.city.bol ?
                                                "form-control p-form  br-btn-t error-input" :
                                                "form-control p-form  br-btn-t"
                                            }
                                            valueBtn={state.city}
                                            setValue={setState}
                                            state={state}
                                            read={history.location.pathname.includes('vendors/view') ?
                                                true : undefined
                                            }
                                            classError={errors.city.bol ?
                                                "invalid-input" :
                                                "valid-input"
                                            }
                                            textError={errors.city.msg}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12" md="6">
                                        <InputControlled
                                            classContainer={'form-group'}
                                            classLabel={'form-label fs-6'}
                                            textLabel={'Forma de envío'}
                                            typeBtn={'text'}
                                            nameBtn={'method_shipping'}
                                            styleBtn={errors.method_shipping.bol ?
                                                "form-control p-form  br-btn-t error-input" :
                                                "form-control p-form  br-btn-t"
                                            }
                                            valueBtn={state.method_shipping}
                                            setValue={setState}
                                            state={state}
                                            read={history.location.pathname.includes('vendors/view') ?
                                                true : undefined
                                            }
                                            classError={errors.method_shipping.bol ?
                                                "invalid-input" :
                                                "valid-input"
                                            }
                                            textError={errors.method_shipping.msg}
                                        />
                                    </Col>
                                    <Col sm="12" md="6">
                                        <InputControlled
                                            classContainer={'form-group'}
                                            classLabel={'form-label fs-6'}
                                            textLabel={'Forma de pago'}
                                            typeBtn={'text'}
                                            nameBtn={'method_payment'}
                                            styleBtn={errors.method_payment.bol ?
                                                "form-control p-form  br-btn-t error-input" :
                                                "form-control p-form  br-btn-t"
                                            }
                                            valueBtn={state.method_payment}
                                            setValue={setState}
                                            state={state}
                                            read={history.location.pathname.includes('vendors/view') ?
                                                true : undefined
                                            }
                                            classError={errors.method_payment.bol ?
                                                "invalid-input" :
                                                "valid-input"
                                            }
                                            textError={errors.method_payment.msg}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12" md="6">
                                        <InputControlled
                                            classContainer={'form-group'}
                                            classLabel={'form-label fs-6'}
                                            textLabel={'Forma de encargo'}
                                            typeBtn={'text'}
                                            nameBtn={'method_order'}
                                            styleBtn={errors.method_order.bol ?
                                                "form-control p-form  br-btn-t error-input" :
                                                "form-control p-form  br-btn-t"
                                            }
                                            valueBtn={state.method_order}
                                            setValue={setState}
                                            state={state}
                                            read={history.location.pathname.includes('vendors/view') ?
                                                true : undefined
                                            }
                                            classError={errors.method_order.bol ?
                                                "invalid-input" :
                                                "valid-input"
                                            }
                                            textError={errors.method_order.msg}
                                        />
                                    </Col>
                                    <Col sm="12" md="6">
                                        <InputControlled
                                            classContainer={'form-group'}
                                            classLabel={'form-label fs-6'}
                                            textLabel={'Teléfono'}
                                            typeBtn={'text'}
                                            nameBtn={'telephone'}
                                            styleBtn={errors.telephone.bol ?
                                                "form-control p-form  br-btn-t error-input" :
                                                "form-control p-form  br-btn-t"
                                            }
                                            valueBtn={state.telephone}
                                            setValue={setState}
                                            state={state}
                                            read={history.location.pathname.includes('vendors/view') ?
                                                true : undefined
                                            }
                                            classError={errors.telephone.bol ?
                                                "invalid-input" :
                                                "valid-input"
                                            }
                                            textError={errors.telephone.msg}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12" md="12">
                                        <div className="form-group">
                                            <label className="form-label fs-6">Detalles</label>
                                            <textarea
                                                name={'details'}
                                                className={history.location.pathname.includes('vendors/view') ? "form-control p-form btn-height-6 br-btn-t resize-none btn-disabled" : "form-control p-form btn-height-6 br-btn-t resize-none"}
                                                value={state.details}
                                                onChange={e => setState({ ...state, [e.target.name]: e.target.value })}
                                                readOnly={history.location.pathname.includes('vendors/view') ? true : undefined}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </Row>
                </div>
            </div>
            <Row className='mb-3'>
                <div
                        className={window.innerWidth > 991 ? "col" : null}
                        style={
                            history.location.pathname.includes('view') ?
                                { display: "flex", justifyContent: "space-between" } :
                                window.innerWidth < 992 ? { width: "75%" } :
                                    null
                        }
                    >
                        {
                            id ? (
                                <>
                                    <Link to={back}>
                                        <button type="submit" className="btn btn-outline-secondary  br-btn-t mr-1">Volver</button>
                                    </Link>
                                    <button
                                        className="btn btn-primary  br-btn-t color-white"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setModal(true)
                                        }}
                                    >
                                        Borrar
                                    </button>
                                </>
                            ) : null
                        }
                    </div>

            {
                history.location.pathname.includes('view') ?
                    null : (
                        <div
                            style={
                                window.innerWidth > 991 ?
                                    null : { width: null }
                            }
                            className={
                                window.innerWidth > 991 ?
                                    "col d-flex justify-content-flex-end" :
                                    "d-flex justify-content-flex-end"
                            }
                        >
                            {
                                history.location.pathname.includes('vendors') ? (
                                    !id ? (
                                        <Link to={back}>
                                            <button type="submit" className="btn btn-outline-secondary  br-btn-t mr-1">
                                                {
                                                    history.location.pathname.includes('view') ?
                                                        'Volver' : 'Cancelar'
                                                }
                                            </button>
                                        </Link>
                                    ) : null
                                ) : (
                                    null
                                )
                            }
                            {
                                !disabled ? (
                                    <button type="submit" id='save-btn' className="btn background-secondary  br-btn-t color-white" onClick={e => {
                                        onSubmit(e);
                                    }}>Guardar</button>
                                ) : (
                                    <button type="submit" className="btn background-secondary  br-btn-t color-white" disabled={true}>
                                        <span className="spinner-border spinner-border-sm">
                                            <span className="sr-only"></span>
                                        </span>
                                    </button>
                                )
                            }
                        </div>
                    )
            }
            </Row>
        </>
    )
}
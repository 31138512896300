import React from 'react';
import { Table, Row, Col, Badge } from 'reactstrap';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Dropdown from './DropDownMenu';
import { priceRetails } from '../utils/functions'; 
import { getConfigSupply } from '../redux/action';
import { useDispatch } from 'react-redux';


export default function TableProducts({ data, toEdit, setModal, modal, setId, setDeleteAll, setModalDelete, disabled, showIsActive, isWholesale }) {
    const [checkbox, setCheckbox] = React.useState({})
    const [all, setAll] = React.useState(false)
    const [modalProduce, setModalProduce] = React.useState(null)
    const [modalProduceTitle, setModalProduceTitle] = React.useState('')
    const [producing, setProducing] = React.useState(null)
    const [producingError, setProducingError] = React.useState(null)
    const [quantityToProduce, setQuantityToProduce] = React.useState(1)
    const dispatch = useDispatch();

    React.useEffect(() => {
        setCheckbox({})
        dispatch(getConfigSupply())
    }, [])

    const onChecked = (a) => {
        setCheckbox({
            ...checkbox,
            [a]: !checkbox[a]
        })
    }

    const handleDelete = () => {
        if (all) {
            const arr = []
            data.map(el => arr.push(el.id))
            setDeleteAll(arr)
        } else {
            setDeleteAll(Object.keys(checkbox).filter(x => checkbox[x] === true))
        }
        setModalDelete(true)
        return setCheckbox({})
    }

    const setProduce = (quantity, product) => {
        if(quantity < 1 || quantity == '' || quantity == null || isNaN(quantity)) {
            setProducingError({message: 'La cantidad no puede ser inferior a 1'});
            return;
        }
        
        setProducing(true);
        const myHeaders = new Headers();

        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/products/${product.id}/produce/`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({quantity_to_produce: quantity}),
        })
            .then(resp => resp.json())
            .then(data => {
                setProducing(false)
                if(data.type === 'without_stock') {
                    setProducingError(data)
                }
                else {
                    setModalProduce(null)
                    setProducingError(null)
                }
            })
    }

    const setDiscard = (quantity, product) => {
        if(quantity < 1 || quantity == '' || quantity == null || isNaN(quantity)) {
            setProducingError({message: 'La cantidad no puede ser inferior a 1'});
            return;
        }
        
        setProducing(true);
        const myHeaders = new Headers();

        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/products/${product.id}/discard/`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({quantity_to_discard: quantity}),
        })
            .then(resp => resp.json())
            .then(data => {
                setProducing(false)
                if(data !== '') {
                    setProducingError(data)
                }
                else {
                    setModalProduce(null)
                    setProducingError(null)
                }
            })
    }

    return (
        <>
            <Row>
                <Col className="mb-1" sm="12">
                    <Table responsive className="align-middle">
                        <thead>
                            <tr>
                                <th className="text-align-center checkboxContainer">
                                    <label className="containerCheckbox">
                                        <input
                                            type="checkbox"
                                            className={
                                                Object.values(checkbox).filter(x => x === true).length === data.length
                                                ? "checked" : ""
                                            }
                                            onClick={() => {
                                                setAll(!all)
                                                const obj = {}
                                                data.map(el => {
                                                    return obj[el.id] = !all
                                                })
                                                setCheckbox(obj)
                                            }}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </th>
                                <th>Nombre</th>
                                <th>Tipo</th>
                                {
                                    window.innerWidth < 992 ? (
                                        null
                                    ) : (
                                        <>
                                            <th>Precio minorista</th>
                                            {isWholesale ? <th>Precio mayorista</th> : null}
                                            <th>Costo</th>
                                            <th>Stock</th>
                                            <th>Detalle</th>
                                            <th>Estado</th>
                                        </>
                                    )
                                }
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data && data.map((e, i = -1) => {
                                    i++
                                    return (
                                        <tr key={e.id} >
                                            <th className="text-align-center checkboxContainer">
                                                {
                                                    checkbox[e.id] ? (
                                                        <label className="containerCheckbox">
                                                            <input
                                                                type="checkbox"
                                                                className="checked"
                                                                onClick={() => {
                                                                    onChecked(e.id)
                                                                }}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    ) : (
                                                        <label className="containerCheckbox">
                                                            <input
                                                                type="checkbox"
                                                                onClick={() => {
                                                                    onChecked(e.id)
                                                                }}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    )
                                                }
                                            </th>
                                            <th>
                                                <Link to={`/products/edit/${e.id}`}>
                                                    {e.name}
                                                </Link>
                                            </th>
                                            <th
                                                style={
                                                    e.product_type === "resale" ?
                                                        { color: "#6610f2" } :
                                                        { color: "rgb(218, 18, 117)" }
                                                }
                                            >
                                                {
                                                    e.product_type === "resale" ? "Reventa" : "Producción"
                                                }
                                            </th>
                                            {
                                                window.innerWidth < 992 ? (
                                                    null
                                                ) : (
                                                    <>
                                                        <th>{e.sale_price.toFixed(2)}</th>

                                                        {isWholesale ?
                                                        <th>
                                                            {
                                                                //e.product_type === "production" ? (
                                                                //    // priceRetails(e)
                                                                //    e.price_retails.toFixed(2)
                                                                //) : "-"
                                                                e.price_retails.toFixed(2)
                                                            }
                                                        </th>
                                                        : null}

                                                        <th>{e.cost_price.toFixed(2)}</th>
                                                        <th>{e.current_stock_in_units || "-"}</th>
                                                        <th>{e.details || "-"}</th>
                                                    </>
                                                )
                                            }
                                            <th><Badge className={e.is_active ? 'background-secondary' : ''}>{e.is_active ? 'Activo' : 'Desactivado'}</Badge></th>
                                            <th className="text-end">
                                                <Dropdown setId={setId} toEdit={toEdit} e={e} setModal={setModal} modal={modal} modalProduce={modalProduce} setModalProduce={(data, title) => {
                                                    setModalProduce(data);
                                                    setModalProduceTitle(title);
                                                    setProducingError(null);
                                                }} />
                                            </th>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </Col>
                {
                    Object.values(checkbox).filter(x => x === true).length && showIsActive !== 'true,false' ? (
                        <Col>
                            <footer>
                                {
                                    !producing ? (
                                        <button
                                            className="btn background-secondary br-btn-t color-white color-white"
                                            onClick={() => handleDelete()}
                                        >
                                            {showIsActive === 'true' ? 'Desactivar' : 'Activar'} seleccionados
                                        </button>
                                    ) : (
                                        <button type="submit" className="btn background-secondary btn-height-3 br-btn-t color-white" disabled={true}>
                                            <span className="spinner-border spinner-border-sm">
                                                <span className="sr-only"></span>
                                            </span>
                                        </button>
                                    )
                                }
                            </footer>
                        </Col>
                    ) : null
                }
            </Row>


            <Modal show={modalProduce} className="modal-danger">
                <Modal.Header>
                    <h5 className="modal-title color-primary">{modalProduceTitle} {modalProduce?.name}</h5>
                    <button type="button" className="close color-primary" onClick={() => setModalProduce(null)}>
                        <span>×</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label className="form-label fs-6">Cantidad a {modalProduceTitle.toLocaleLowerCase()}</label>
                        <input
                            type={'number'}
                            className={'form-control'}
                            value={quantityToProduce}
                            onChange={(e) => setQuantityToProduce(parseInt(e.target.value))}
                        />
                        {producingError?.message ? <p className='text-danger'>{producingError.message}</p> : null}
                        {
                            producingError?.type === 'without_stock' ? 
                                <div>
                                    <p className='text-danger mb-0'>No se puede producir porque no hay suficiente stock de:</p>
                                    <div>
                                        {producingError.supplies.map((item) => {
                                            return (
                                                <small className='text-danger d-flex align-items-center'>
                                                    <span className="material-icons text-danger fs-6">link</span>
                                                    <Link to={`/supplies/edit/${item.id}`} className='text-danger'>{item.name}</Link>
                                                </small>
                                            )
                                        })}
                                        {producingError.packaging.map((item) => {
                                            return (
                                                <small className='text-danger d-flex align-items-center'>
                                                    <span className="material-icons text-danger fs-6">link</span>
                                                    <Link to={`/supplies/edit/${item.id}`} className='text-danger'>{item.name}</Link>
                                                </small>
                                            )
                                        })}
                                    </div>
                                </div>
                            : null
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className="btn btn-outline-secondary btn-height-3 br-btn-t mr-1" onClick={() => setModalProduce(null)}>Cancelar</button>
                    {
                        !producing ? (
                            <button
                                type="submit"
                                className="btn btn-primary btn-height-3 br-btn-t color-white"
                                onClick={(e) => {
                                    modalProduceTitle === 'Producir' ? setProduce(quantityToProduce, modalProduce) : setDiscard(quantityToProduce, modalProduce)
                                }}
                            >
                                {modalProduceTitle}
                            </button>
                        ) : (
                            <button type="submit" className="btn background-secondary btn-height-3 br-btn-t color-white" disabled={true}>
                                <span className="spinner-border spinner-border-sm">
                                    <span className="sr-only"></span>
                                </span>
                            </button>
                        )
                    }
                </Modal.Footer>
            </Modal>            
        </>
    )
}
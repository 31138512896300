import React, { useRef } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { useDispatch } from 'react-redux';
import { uploadImage } from '../redux/action';

export default function FilesUpload({setLoading, imagesApi, classContainer, buttonText, classBtn, classIcon, classChild, setImages, images, state, setUploadingImage }) {
    const inputRef = useRef(null);
    
    const dispatch = useDispatch();
    const handleChange = (e) => {
        let quantityFiles = 0;

        setLoading(true)
        dispatch(uploadImage(e.target.files, state, imagesApi, ()=>{
            setLoading(false)
            quantityFiles++

            if(quantityFiles === e.target.files.length) {
                const arr = []
    
                e?.target?.files?.length && Object.keys(e?.target?.files).map(el => {
                    if (!images?.filter(x => x.name === e?.target?.files[el].name).length) {
                        return arr.push(e?.target?.files[el])
                    } else {
                        return null
                    }
                })
                setImages(images?.concat(arr))
                inputRef.current.value = '';
            }
        }))
    }
    



    return (
        <div className={classContainer}>
            <div className={`${classChild} files-dropzone`}>
                <input ref={inputRef} id="files-img" type="file" accept="image/*" multiple style={{ visibility: 'hidden' }} onChangeCapture={e => handleChange(e)} />
                {
                    buttonText ? buttonText : (
                        <>
                            <label htmlFor="files-img" className={classBtn} id='images-button' style={{cursor: 'pointer', fontSize: 14}}>
                            </label>
                            <AddIcon className={classIcon} />
                        </>
                    )
                }
            </div>
        </div>
    )
}
import React from 'react';

const LogoTXT = ({ size = 1, color, style}) => {
    return (
        <h1 style={{fontSize: 22 * size, textAlign: 'center', color: color ? color : null, ...style}}>
            <b style={{fontSize: 26 * size, color: color ? color : null}}>Calcu</b> <br />
            La Solución Emprendedora <br />
            <small style={{fontSize: 14 * size, color: color ? color : null}}>De Flor de Emprendedora</small>
        </h1>
    )
}

export default LogoTXT;
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Button from './Button'
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

export default function FormClients({ title, state, errors, setState, back, onSubmit, id, setModal, disabled }) {
    const history = useHistory();

    return (
        <>
            <div className={history.location.pathname.includes('clients') ? "card mb-3" : null} >
                {
                    history.location.pathname.includes('clients') ? (
                        <div 
                            className="card-header mt-1" 
                            // style={history.location.pathname.includes('clients') ? { padding: "1.5rem 2.5rem 0" } : null}    
                        >
                            <div className="card-title">
                                <h2>
                                    {title}
                                </h2>
                            </div>
                        </div>
                    ) : null
                }
                <div className="card-body pt-1">
                    <Row>
                        <div 
                            // className={`card-body`} 
                            // style={history.location.pathname.includes('clients') ? { padding: "1.5rem 2.5rem 0" } : null}
                        >
                            <form className="d-flex flex-direction-column justify-content-sb">
                                <Row>
                                    <Col sm="12" md="12" >
                                        <div className="form-group">
                                            <label className="form-label fs-6">Nombre</label>
                                            <Button
                                                type={'text'}
                                                name={'name'}
                                                style={errors.name.bol ? "form-control p-form  br-btn-t error-input" : "form-control p-form  br-btn-t"}
                                                value={state.name}
                                                setValue={setState}
                                                state={state}
                                                read={history.location.pathname.includes('clients/view') ? true : undefined}
                                            />
                                            <div className={errors.name.bol ? "invalid-input" : "valid-input"}>
                                                {errors.name.msg}
                                            </div>
                                        </div>
                                    </Col>
                                    {/* <Col sm="12" md="6" >
                                        <div className="form-group">
                                            <label className="form-label fs-6">Material o servicio que ofrece</label>
                                            <Button
                                                type={'text'}
                                                name={'material'}
                                                style={errors.material.bol ? "form-control p-form  br-btn-t error-input" : "form-control p-form  br-btn-t"}
                                                value={state.material}
                                                setValue={setState}
                                                state={state}
                                                read={history.location.pathname.includes('clients/view') ? true : undefined}
                                            />
                                            <div className={errors.material.bol ? "invalid-input" : "valid-input"}>
                                                {errors.material.msg}
                                            </div>
                                        </div>
                                    </Col> */}
                                </Row>
                                <Row>
                                    <Col sm="12" md="4" >
                                        <div className="form-group">
                                            <label className="form-label fs-6">Dirección</label>
                                            <Button
                                                type={'text'}
                                                name={'address'}
                                                style={errors.address.bol ? "form-control p-form  br-btn-t error-input" : "form-control p-form  br-btn-t"}
                                                value={state.address}
                                                setValue={setState}
                                                state={state}
                                                read={history.location.pathname.includes('clients/view') ? true : undefined}
                                            />
                                            <div className={errors.address.bol ? "invalid-input" : "valid-input"}>
                                                {errors.address.msg}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="12" md="4" >
                                        <div className="form-group">
                                            <label className="form-label fs-6">Barrio</label>
                                            <Button
                                                type={'text'}
                                                name={'neighborhood'}
                                                style={errors.neighborhood.bol ? "form-control p-form  br-btn-t error-input" : "form-control p-form  br-btn-t"}
                                                value={state.neighborhood}
                                                setValue={setState}
                                                state={state}
                                                read={history.location.pathname.includes('clients/view') ? true : undefined}
                                            />
                                            <div className={errors.neighborhood.bol ? "invalid-input" : "valid-input"}>
                                                {errors.neighborhood.msg}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="12" md="4" >
                                        <div className="form-group">
                                            <label className="form-label fs-6">Ciudad</label>
                                            <Button
                                                type={'text'}
                                                name={'city'}
                                                style={errors.city.bol ? "form-control p-form  br-btn-t error-input" : "form-control p-form  br-btn-t"}
                                                value={state.city}
                                                setValue={setState}
                                                state={state}
                                                read={history.location.pathname.includes('clients/view') ? true : undefined}
                                            />
                                            <div className={errors.city.bol ? "invalid-input" : "valid-input"}>
                                                {errors.city.msg}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl="4" lg="6" md="4" >
                                        <div className="form-group">
                                            <label className="form-label fs-6">Forma de envío</label>
                                            <Button
                                                type={'text'}
                                                name={'method_shipping'}
                                                style={errors.method_shipping.bol ? "form-control p-form  br-btn-t error-input" : "form-control p-form  br-btn-t"}
                                                value={state.method_shipping}
                                                setValue={setState}
                                                state={state}
                                                read={history.location.pathname.includes('clients/view') ? true : undefined}
                                            />
                                            <div className={errors.method_shipping.bol ? "invalid-input" : "valid-input"}>
                                                {errors.method_shipping.msg}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl="4" lg="6" md="4" >
                                        <div className="form-group">
                                            <label className="form-label fs-6">Teléfono</label>
                                            <Button
                                                type={'text'}
                                                name={'telephone'}
                                                style={errors.telephone.bol ? "form-control p-form  br-btn-t error-input" : "form-control p-form  br-btn-t"}
                                                value={state.telephone}
                                                setValue={setState}
                                                state={state}
                                                read={history.location.pathname.includes('clients/view') ? true : undefined}
                                            />
                                            <div className={errors.telephone.bol ? "invalid-input" : "valid-input"}>
                                                {errors.telephone.msg}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl="4" lg="12" md="4" >
                                        <div className="form-group">
                                            <label className="form-label fs-6">Documento de Identidad</label>
                                            <Button
                                                type={'number'}
                                                name={'identification'}
                                                style={errors.identification.bol ? "form-control p-form  br-btn-t error-input" : "form-control p-form  br-btn-t"}
                                                value={state.identification}
                                                setValue={setState}
                                                state={state}
                                                read={history.location.pathname.includes('clients/view') ? true : undefined}
                                            />
                                            <div className={errors.identification.bol ? "invalid-input" : "valid-input"}>
                                                {errors.identification.msg}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12" md="12" >
                                        <div className="form-group">
                                            <label className="form-label fs-6">Detalles</label>
                                            <textarea
                                                name={'details'}
                                                className={history.location.pathname.includes('clients/view') ? "form-control p-form btn-height-6 br-btn-t resize-none btn-disabled" : "form-control p-form btn-height-6 br-btn-t resize-none"}
                                                value={state.details}
                                                onChange={e => setState({ ...state, [e.target.name]: e.target.value })}
                                                readOnly={history.location.pathname.includes('clients/view') ? true : undefined}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                {/* <Row style={window.innerWidth > 991 ? null : { display: "flex" }}>
                                <div
                                    className={window.innerWidth > 991 ? "col" : null}
                                    style={
                                        history.location.pathname.includes('view') ?
                                            { display: "flex", justifyContent: "space-between" } :
                                            window.innerWidth < 992 ? { width: "75%" } :
                                                null
                                    }
                                >
                                    {
                                        id ? (
                                            <>
                                                <Link to={back}>
                                                    <button type="submit" className="btn btn-outline-secondary  br-btn-t mr-1">Cancelar</button>
                                                </Link>
                                                <button
                                                    className="btn btn-primary  br-btn-t color-white"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        setModal(true)
                                                    }}
                                                >
                                                    Borrar
                                                </button>
                                            </>
                                        ) : null
                                    }
                                </div>
                                {
                                    history.location.pathname.includes('view') ?
                                        null : (
                                            <div
                                                style={
                                                    window.innerWidth > 991 ?
                                                        null : { width: "25%" }
                                                }
                                                className={
                                                    window.innerWidth > 991 ?
                                                        "col d-flex justify-content-flex-end" :
                                                        "d-flex justify-content-flex-end"
                                                }
                                            >
                                                {
                                                    history.location.pathname.includes('clients') ? (
                                                        !id ? (
                                                            <Link to={back}>
                                                                <button type="submit" className="btn btn-outline-secondary  br-btn-t mr-1">
                                                                    {
                                                                        history.location.pathname.includes('view') ?
                                                                            'Volver' : 'Cancelar'
                                                                    }
                                                                </button>
                                                            </Link>
                                                        ) : null
                                                    ) : (
                                                        null
                                                    )
                                                }
                                                {
                                                    !disabled ? (
                                                        <button type="submit" className="btn background-secondary  br-btn-t color-white" onClick={e => {
                                                            onSubmit(e);
                                                        }}>Guardar</button>
                                                    ) : (
                                                        <button type="submit" className="btn background-secondary  br-btn-t color-white" disabled={true}>
                                                            <span className="spinner-border spinner-border-sm">
                                                                <span className="sr-only"></span>
                                                            </span>
                                                        </button>
                                                    )
                                                }
                                            </div>
                                        )
                                }
                            </Row> */}
                            
                            </form>
                        </div>
                    </Row>
                </div>
            </div>
            <Row className='mb-3'>
                <div
                    className={window.innerWidth > 991 ? "col" : null}
                    style={
                        history.location.pathname.includes('view') ?
                            { display: "flex", justifyContent: "space-between" } :
                            window.innerWidth < 992 ? { width: "75%" } :
                                null
                    }
                >
                    {
                        id ? (
                            <>
                                <Link to={back}>
                                    <button type="submit" className="btn btn-outline-secondary  br-btn-t mr-1">Volver</button>
                                </Link>
                                {id ? 
                                <button
                                    className="btn btn-primary  br-btn-t color-white"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setModal(true)
                                    }}
                                >
                                    Borrar
                                </button>
                                : null}
                            </>
                        ) : null
                    }
                </div>
                
            {
                history.location.pathname.includes('view') ?
                    null : (
                        <div
                            style={
                                window.innerWidth > 991 ?
                                    null : { width: null }
                            }
                            className={
                                window.innerWidth > 991 ?
                                    "col d-flex justify-content-flex-end" :
                                    "d-flex justify-content-flex-end"
                            }
                        >
                            {
                                history.location.pathname.includes('clients') ? (
                                    !id ? (
                                        <Link to={back}>
                                            <button type="submit" className="btn btn-outline-secondary  br-btn-t mr-1">
                                                {
                                                    history.location.pathname.includes('view') ?
                                                        'Volver' : 'Cancelar'
                                                }
                                            </button>
                                        </Link>
                                    ) : null
                                ) : (
                                    null
                                )
                            }
                            {
                                !disabled ? (
                                    <button type="submit" id='save-btn' className="btn background-secondary  br-btn-t color-white" onClick={e => {
                                        onSubmit(e);
                                    }}>Guardar</button>
                                ) : (
                                    <button type="submit" className="btn background-secondary  br-btn-t color-white" disabled={true}>
                                        <span className="spinner-border spinner-border-sm">
                                            <span className="sr-only"></span>
                                        </span>
                                    </button>
                                )
                            }
                        </div>
                    )
            }
            </Row>
        </>
    )
}
import React from 'react';

export default function Pagination({ page, setPage, next, previous, pageData, data }) {
    return (
        <>
            <nav className="d-flex mt-1 justify-content-center">
                <ul className="pagination mb-0">
                    {
                        !previous && !next ? (
                            null
                        ) :
                            !previous ? (
                                <li className={`page-item first-item`}>
                                    <button
                                        disabled
                                        className="page-link "
                                    >{"<"}</button>
                                </li>
                            ) : (
                                <li className={`page-item first-item`}>
                                    <button
                                        className="page-link "
                                        onClick={(e) => {
                                            setPage(page - 1);
                                        }}
                                    >{"<"}</button>
                                </li>
                            )
                    }
                    {
                        !previous && !next ? (
                            null
                        ) : (
                            <li className="page-item active">
                                <button className={`page-link`}>{page}</button>
                            </li>
                        )
                    }
                    {
                        !previous && !next ? (
                            null
                        ) :
                            !next ? (
                                <li className="page-item last-item">
                                    <button
                                        disabled
                                        className={`page-link`}
                                    >{">"}</button>
                                </li>
                            ) : (
                                <li className="page-item last-item">
                                    <button
                                        className={`page-link`}
                                        onClick={(e) => {
                                            setPage(page + 1)
                                        }}
                                    >{">"}</button>
                                </li>
                            )
                    }
                </ul>
            </nav>
            <div className="d-flex justify-content-center">{page} de {Math.ceil(data.count / 10)}</div>
        </>
    )
}
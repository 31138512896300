import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import CardTotal from '../component/CardTotal';
import ModalComponent from '../component/Modal';
import TableResponsive from '../component/Table';
import { getPayUser } from '../redux/action';

export const Invested = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState()
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [modalDelete, setModalDelete] = useState(false)
    const [modal, setModal] = useState(false)
    const [disabled, setDisabled] = useState(false);
    const [sumTotal, setSumTotal] = useState(null)
    const [deleteAll, setDeleteAll] = useState();
    const [reload, setReload] = useState(false);
    const [id, setId] = useState();
    useEffect(() => {
        dispatch(getPayUser())
    }, [dispatch])
    useEffect(() => {
        setDisabled(false)
    }, [])

    useEffect(()=> {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')
        fetch(`${process.env.REACT_APP_URL_API}/api/inverted/total`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setSumTotal(json.amount__sum ? json.amount__sum : 0)
            })
    }, [reload, search, disabled, page])

    useEffect(() => {
        const myHeaders = new Headers();

        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')
        if( search.length >= 1) {
            return
        } else {
            fetch(`${process.env.REACT_APP_URL_API}/api/inverted/?page=${page}`, {
                'method': "GET",
                'headers': myHeaders
            })
                .then(resp => resp?.json())
                .then(json => {
                    if(json.detail) {
                        setPage(page-1)
                    } else {
                        setData(json)
                    }
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload, dispatch, disabled, page, search])

    const handleSubmit = (e) => {
        e.preventDefault();
        setDisabled(true)
        setTimeout(() => {
            setDisabled(false);
        }, 1500)
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')
        fetch(`${process.env.REACT_APP_URL_API}/api/inverted/?search=${e.target.value}`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setData(json)
            })
    }

    const pageData = (e, url) => {
        e.preventDefault();
        setDisabled(true);
        setTimeout(() => {
            setDisabled(false);
        }, 1500)

        const myHeaders = new Headers();

        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${url}`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setData(json)
            })
    }

    const onDeleteAll = (e) => {
        e.preventDefault();
        setDisabled(true);
        setTimeout(() => {
            setDisabled(false);
        }, 1500)

        //dispatch(deleteAllExpenses(deleteAll))
        setDeleteAll([])
    }

    const onDelete = (e) => {
        e.preventDefault();
        setDisabled(true);
        setTimeout(() => {
            setDisabled(false);
        }, 1500)
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        //dispatch(deleteExpenses(id))
        setReload(!reload)
    }

    return (
        <>
            {
                modalDelete ? (
                    <ModalComponent
                        open={modalDelete}
                        title={deleteAll && deleteAll.length === 1 ? "Eliminar egreso" : "Eliminar egresos"}
                        body={deleteAll && deleteAll.length === 1 ? "¿Esta segura de eliminar este egreso?" : `¿Esta segura de eliminar ${deleteAll.length} egresos?`}
                        setModal={setModalDelete}
                        onDelete={onDeleteAll}
                    />
                ) : null
            }
            {
                modal ? (
                    <ModalComponent
                        open={modal}
                        title={"Eliminar egreso"}
                        body={"¿Esta segura de eliminar este egreso?"}
                        setModal={setModal}
                        onDelete={onDelete}
                    />
                ) : null
            }
    
            <CardTotal
                total={sumTotal}
                title={'Total invertido'}
            />

            <TableResponsive
                data={data}
                search={search}
                setSearch={setSearch}
                handleSubmit={handleSubmit}
                toCreate={"/invested/create"}
                toEdit={'/invested/edit'}
                modal={modal}
                setModal={setModal}
                setId={setId}
                page={page}
                setPage={setPage}
                next={data?.next}
                previous={data?.previous}
                pageData={pageData}
                reload={reload}
                setReload={setReload}
                id={id}
                setDeleteAll={setDeleteAll}
                setModalDelete={setModalDelete}
                disabled={disabled}
            />
        </>
    )
}

import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import { useDispatch } from 'react-redux';
import { cancelPremium } from '../redux/action';

export const ModalCancelPremium = ({ open, title, setModal, body, button  }) => {
    const [ isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const handleDeletePay = (e) => {
        setIsLoading(true)
        e.preventDefault()

        dispatch(cancelPremium(setModal))
    }
    return (
        <Modal show={open} className="modal-danger">
            <Modal.Header>
                <h5 className="modal-title color-primary">{title}</h5>
                <button type="button" className="close color-primary" onClick={() => setModal(false)}>
                    <span>×</span>
                </button>
            </Modal.Header>

            <Modal.Body>
                {body}
            </Modal.Body>

            <Modal.Footer>
                <button type="submit" className="btn btn-outline-secondary btn-height-3 br-btn-t mr-1" onClick={() => setModal(false)}>Cancelar</button>
                <button type="submit" className="btn btn-outline-secondary btn-height-3 br-btn-t mr-1" disabled={isLoading?"disabled":""} onClick={(e) => handleDeletePay(e)}>{button}</button>
            </Modal.Footer>
        </Modal>
    )
}

import React from 'react';
import Modal from 'react-bootstrap/Modal'

export default function ModalPremium({ open, title, setModal, body, type='modal-danger' }) {
    return (
        <Modal show={open} className={type}>
            <Modal.Header>
                <h5 className="modal-title color-primary">{title}</h5>
                <button type="button" className="close color-primary" onClick={() => setModal(false)}>
                    <span>×</span>
                </button>
            </Modal.Header>

            <Modal.Body>
                {body}
            </Modal.Body>

            <Modal.Footer>
                <button type="submit" className="btn btn-outline-secondary btn-height-3 br-btn-t mr-1" onClick={() => setModal(false)}>Aceptar</button>
            </Modal.Footer>
        </Modal>
    )
}

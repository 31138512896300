import React, { useState, useEffect } from 'react';
import StepWizard from "react-step-wizard";
import FormStep from '../component/FormStep';
import NavFormStep from '../component/NavFormStep';
import { useHistory, useParams } from 'react-router-dom';
import {Modal, Row, Col, Form, ModalBody} from 'react-bootstrap';
import { Logged } from '../utils/authentication';
import { toast } from 'react-toastify';

const labels = {
    educacion_escuela_infantil_label: 'Escuela Infantil',
    educacion_actividad_extracurricular1_label: 'Actividad extracurricular 1',
    educacion_actividad_extracurricular2_label: 'Actividad extracurricular 2',
    educacion_cursos_label: 'Cursos o capacitaciones',
}

const initialState = {
    name: '',
    gastos_personales_alquiler: '',
    gastos_personales_expensas: '',
    gastos_personales_luz: '',
    gastos_personales_gas: '',
    gastos_personales_agua: '',
    gastos_personales_cable: '',
    gastos_personales_internet: '',
    gastos_personales_celular: '',
    gastos_personales_abl: '',
    gastos_personales_medicina_prepaga: '',
    gastos_personales_sesiones_de_terapia: '',
    gastos_personales_gastos_bancarios: '',
    // gastos_personales_option_1: '',
    // gastos_personales_option_2: '',
    // gastos_personales_option_3: '',
    // gastos_personales_option_4: '',
    // gastos_personales_option_5: '',
    // gastos_personales_option_6: '',
    transporte_privado_patente: '',
    transporte_privado_seguro: '',
    transporte_privado_combustible: '',
    transporte_publico: '',
    educacion_escuela_infantil: '',
    educacion_escuela_infantil_label: labels.educacion_escuela_infantil_label,
    educacion_actividad_extracurricular1: '',
    educacion_actividad_extracurricular1_label: labels.educacion_actividad_extracurricular1_label,
    educacion_actividad_extracurricular2: '',
    educacion_actividad_extracurricular2_label: labels.educacion_actividad_extracurricular2_label,
    educacion_cursos: '',
    educacion_cursos_label: labels.educacion_cursos,
    gastos_comerciales_alquiler: '',
    gastos_comerciales_expensas: '',
    gastos_comerciales_luz: '',
    gastos_comerciales_gas: '',
    gastos_comerciales_agua: '',
    gastos_comerciales_cable: '',
    gastos_comerciales_internet: '',
    gastos_comerciales_celular: '',
    gastos_comerciales_abl: '',
    gastos_comerciales_deuda: '',
    gastos_comerciales_reinversion: '',
    gastos_comerciales_sueldo_empleados: '',
    gastos_comerciales_tienda_online: '',
    gastos_comerciales_publicidad: '',
    consumo_comida: '',
    consumo_alimentos_mascota: '',
    consumo_articulos_limpieza: '',
    extras_deuda: '',
    extras_fondo_reserva: '',
    extras_ahorros: '',
    extras_esparcimiento_o_salidas: '',
    sueldo_promedio: '',
    sueldo_final: '',
    sueldo_adicional: '',
    horas_trabajo_dia: '',
    dias_trabajo: '',
    finished: false,
}

export default function SettingInitial() {
    const controller = new AbortController()
    const signal = controller.signal

    const [modal, setModal] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [showModalSueldo, setShowModalSueldo] = useState(localStorage.modal_sueldo ? false : true)
    const history = useHistory()
    const { id } = useParams();
    const [modalNeedHelp, setModalNeedHelp] = useState(true)
    const [modalGastosComerciales, setModalGastosComerciales] = useState(false);
    const [isStep, setIsStep] = useState(true);
    const [modalDeleteCustomField, setModalDeleteCustomField] = useState(null);
    const [initialStep, setInitialStep] = useState(1)

    const saveShowModalSueldo = () => {
        localStorage.modal_sueldo = 'true';
        setShowModalSueldo(false)
    }

    const [extraInputGastosPersonales, setExtraInputGastosPersonales] = useState({
        option_1: {
            bol: false,
            name: 'Opción 1'
        },
        option_2: {
            bol: false,
            name: 'Opción 2'
        },
        option_3: {
            bol: false,
            name: 'Opción 3'
        },
        option_4: {
            bol: false,
            name: 'Opción 4'
        },
        option_5: {
            bol: false,
            name: 'Opción 5'
        },
        option_6: {
            bol: false,
            name: 'Opción 6'
        }
    })

    const [extraInputGastosComerciales, setExtraInputGastosComerciales] = useState({
        option_1: {
            bol: false,
            name: 'Opción 1'
        },
        option_2: {
            bol: false,
            name: 'Opción 2'
        },
        option_3: {
            bol: false,
            name: 'Opción 3'
        },
        option_4: {
            bol: false,
            name: 'Opción 4'
        },
        option_5: {
            bol: false,
            name: 'Opción 5'
        },
        option_6: {
            bol: false,
            name: 'Opción 6'
        }
    })

    const [addInputPersonales, setAddInputPersonales] = useState({
        option_1: {
            bol: false,
            name: 'Opción 1'
        },
        option_2: {
            bol: false,
            name: 'Opción 2'
        },
        option_3: {
            bol: false,
            name: 'Opción 3'
        },
        option_4: {
            bol: false,
            name: 'Opción 4'
        },
        option_5: {
            bol: false,
            name: 'Opción 5'
        },
        option_6: {
            bol: false,
            name: 'Opción 6'
        }
    })

    const [addInputComerciales, setAddInputComerciales] = useState({
        option_1: {
            bol: false,
            name: 'Opción 1'
        },
        option_2: {
            bol: false,
            name: 'Opción 2'
        },
        option_3: {
            bol: false,
            name: 'Opción 3'
        },
        option_4: {
            bol: false,
            name: 'Opción 4'
        },
        option_5: {
            bol: false,
            name: 'Opción 5'
        },
        option_6: {
            bol: false,
            name: 'Opción 6'
        }
    })

    const [state, setState] = useState(initialState)

    const [errors, setErrors] = useState({
        transporte_privado_patente: {
            bol: false,
            msg: ''
        },
        transporte_privado_seguro: {
            bol: false,
            msg: ''
        },
        transporte_privado_combustible: {
            bol: false,
            msg: ''
        },
        transporte_publico: {
            bol: false,
            msg: ''
        },
        educacion_escuela_infantil: {
            bol: false,
            msg: ''
        },
        educacion_escuela_infantil_label: {
            bol: false,
            msg: ''
        },
        educacion_actividad_extracurricular1: {
            bol: false,
            msg: ''
        },
        educacion_actividad_extracurricular1_label: {
            bol: false,
            msg: ''
        },
        educacion_actividad_extracurricular2: {
            bol: false,
            msg: ''
        },
        educacion_actividad_extracurricular2_label: {
            bol: false,
            msg: ''
        },
        educacion_cursos: {
            bol: false,
            msg: ''
        },
        educacion_cursos_label: {
            bol: false,
            msg: ''
        },
        gastos_comerciales_alquiler: {
            bol: false,
            msg: ''
        },
        gastos_comerciales_expensas: {
            bol: false,
            msg: ''
        },
        gastos_comerciales_luz: {
            bol: false,
            msg: ''
        },
        gastos_comerciales_gas: {
            bol: false,
            msg: ''
        },
        gastos_comerciales_agua: {
            bol: false,
            msg: ''
        },
        gastos_comerciales_cable: {
            bol: false,
            msg: ''
        },
        gastos_comerciales_internet: {
            bol: false,
            msg: ''
        },
        gastos_comerciales_celular: {
            bol: false,
            msg: ''
        },
        gastos_comerciales_abl: {
            bol: false,
            msg: ''
        },
        gastos_comerciales_deuda: {
            bol: false,
            msg: ''
        },
        gastos_comerciales_reinversion: {
            bol: false,
            msg: ''
        },
        gastos_comerciales_sueldo_empleados: {
            bol: false,
            msg: ''
        },
        gastos_comerciales_tienda_online: {
            bol: false,
            msg: ''
        },
        gastos_comerciales_publicidad: {
            bol: false,
            msg: ''
        },
        gastos_personales_sesiones_de_terapia: {
            bol: false,
            msg: ''
        },
        consumo_comida: {
            bol: false,
            msg: ''
        },
        consumo_alimentos_mascota: {
            bol: false,
            msg: ''
        },
        consumo_articulos_limpieza: {
            bol: false,
            msg: ''
        },
        extras_deuda: {
            bol: false,
            msg: ''
        },
        extras_fondo_reserva: {
            bol: false,
            msg: ''
        },
        extras_ahorros: {
            bol: false,
            msg: ''
        },
        extras_esparcimiento_o_salidas: {
            bol: false,
            msg: ''
        },
        sueldo_promedio: {
            bol: false,
            msg: ''
        },
        sueldo_final: {
            bol: false,
            msg: ''
        },
        sueldo_adicional: {
            bol: false,
            msg: ''
        },
        horas_trabajo_dia: {
            bol: false,
            msg: ''
        },
        dias_trabajo: {
            bol: false,
            msg: ''
        }
    })

    const handleInputsPersonales = () => {
        setAddInputPersonales(extraInputGastosPersonales)
        setModal(!modal)
    }

    const handleInputsComerciales = () => {
        setAddInputComerciales(extraInputGastosComerciales)
        setModalGastosComerciales(!modalGastosComerciales)
    }

    useEffect(() => {
        const token = Logged();
        if(!token) {
            toast.success('Debe iniciar sesión nuevamente', {
                className: "Toastify__toast--error"
            })
            setTimeout(() => {
                return history.push('/login')
            }, 50)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps

        const myHeaders = new Headers();

        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/entrepreneur-salary/?number=${id}`, {
            method: 'GET',
            headers: myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                let data = json[0]?.data
                let name = json[0]?.name
                setState({...initialState, ...data, name})
                setLoaded(true)

                if(data?.finished) setIsStep(false)
            })
    }, [id])

    useEffect(() => {
        if(loaded) save()
    }, [state])

    const save = (finished) => {
        const myHeaders = new Headers();

        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        let _state = {...state};
        
        if(finished) {
            _state.finished = true
            setState(_state)
        }

        // controller.abort()

        fetch(`${process.env.REACT_APP_URL_API}/api/entrepreneur-salary/`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({number: id, data: _state, name:(_state.name) ? _state.name : `Sueldo Soci@s ${id}`}),
            signal: signal,
        })
            .then(resp => resp.json())
            .then(json => {
                if(finished) {
                    _state.finished = true
                    setIsStep(false)
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                }
            })
    }

    const setCustomField = (group) => {
        let lastNumber = 0
        Object.keys(state).forEach((field) => {
            if(field.includes(group + '_option_')) {
                let number = field.replace(group + '_option_', '')
                number = parseInt(number)
                if(number > lastNumber) lastNumber = number;
            }
        })
        lastNumber += 1;

        let field = group + '_option_' + lastNumber.toString()
        setState({...state, [field + '_label']: 'Opción ' + lastNumber, [field]: ''})
    }

    const deleteCustomField = (field) => {
        console.log(field, field + '_label')
        let _state = {...state}
        delete _state[field]
        delete _state[field + '_label']
        setState(_state)
        setModalDeleteCustomField(null)
    }

    return (
        <>
            {
                modalGastosComerciales ? ( 
                    <Modal show={modalGastosComerciales}>
                        <Modal.Header>Seleccione los campos que quiera agregar Comerciales</Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col className="d-flex mb-1">
                                    <Form.Check 
                                        type="checkbox" 
                                        onClick={() => setExtraInputGastosComerciales({...extraInputGastosComerciales, option_1: {...extraInputGastosComerciales.option_1, bol: !extraInputGastosComerciales.option_1.bol}})}
                                        className="margin-auto"
                                        defaultChecked={extraInputGastosComerciales.option_1.bol}
                                    />
                                    <input 
                                        className="form-control p-form btn-sm"
                                        type="text"
                                        value={extraInputGastosComerciales.option_1.name}
                                        onChange={(e) => 
                                            setExtraInputGastosComerciales({
                                                ...extraInputGastosComerciales, 
                                                option_1: {
                                                    ...extraInputGastosComerciales.option_1,
                                                    name: e.target.value
                                                }
                                            })
                                        }
                                    />
                                </Col>
                                <Col className="d-flex mb-1">
                                    <Form.Check 
                                        type="checkbox" 
                                        onClick={() => setExtraInputGastosComerciales({...extraInputGastosComerciales, option_2: {...extraInputGastosComerciales.option_2, bol: !extraInputGastosComerciales.option_2.bol}})}
                                        className="margin-auto"
                                        defaultChecked={extraInputGastosComerciales.option_2.bol}
                                    />
                                    <input 
                                        className="form-control p-form btn-sm"
                                        type="text"
                                        value={extraInputGastosComerciales.option_2.name}
                                        onChange={(e) => 
                                            setExtraInputGastosComerciales({
                                                ...extraInputGastosComerciales, 
                                                option_2: {
                                                    ...extraInputGastosComerciales.option_2,
                                                    name: e.target.value
                                                }
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex mb-1">
                                    <Form.Check 
                                        type="checkbox" 
                                        onClick={e => setExtraInputGastosComerciales({...extraInputGastosComerciales, option_3: {...extraInputGastosComerciales.option_3, bol: !extraInputGastosComerciales.option_3.bol}})} 
                                        className="margin-auto"
                                        defaultChecked={extraInputGastosComerciales.option_3.bol}
                                    />
                                    <input 
                                        className="form-control p-form btn-sm"
                                        type="text"
                                        value={extraInputGastosComerciales.option_3.name}
                                        onChange={(e) => 
                                            setExtraInputGastosComerciales({
                                                ...extraInputGastosComerciales, 
                                                option_3: {
                                                    ...extraInputGastosComerciales.option_3,
                                                    name: e.target.value
                                                }
                                            })
                                        }
                                    />
                                </Col>
                                <Col className="d-flex mb-1">
                                    <Form.Check 
                                        type="checkbox" 
                                        onClick={e => setExtraInputGastosComerciales({...extraInputGastosComerciales, option_4: {...extraInputGastosComerciales.option_4, bol: !extraInputGastosComerciales.option_4.bol}})} 
                                        className="margin-auto"
                                        defaultChecked={extraInputGastosComerciales.option_4.bol}
                                    />
                                    <input 
                                        className="form-control p-form btn-sm"
                                        type="text"
                                        value={extraInputGastosComerciales.option_4.name}
                                        onChange={(e) => 
                                            setExtraInputGastosComerciales({
                                                ...extraInputGastosComerciales, 
                                                option_4: {
                                                    ...extraInputGastosComerciales.option_4,
                                                    name: e.target.value
                                                }
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex mb-1">
                                    <Form.Check 
                                        type="checkbox" 
                                        onClick={e => setExtraInputGastosComerciales({...extraInputGastosComerciales, option_5: {...extraInputGastosComerciales.option_5, bol: !extraInputGastosComerciales.option_5.bol}})}
                                        className="margin-auto"
                                        defaultChecked={extraInputGastosComerciales.option_5.bol}
                                    />
                                    <input 
                                        className="form-control p-form btn-sm"
                                        type="text"
                                        value={extraInputGastosComerciales.option_5.name}
                                        onChange={(e) => 
                                            setExtraInputGastosComerciales({
                                                ...extraInputGastosComerciales, 
                                                option_5: {
                                                    ...extraInputGastosComerciales.option_5,
                                                    name: e.target.value
                                                }
                                            })
                                        }
                                    />
                                </Col>
                                <Col className="d-flex mb-1">
                                    <Form.Check 
                                        type="checkbox" 
                                        onClick={e => setExtraInputGastosComerciales({...extraInputGastosComerciales, option_6: {...extraInputGastosComerciales.option_6, bol: !extraInputGastosComerciales.option_6.bol}})}
                                        className="margin-auto"
                                        defaultChecked={extraInputGastosComerciales.option_6.bol}
                                    />
                                    <input 
                                        className="form-control p-form btn-sm"
                                        type="text"
                                        value={extraInputGastosComerciales.option_6.name}
                                        onChange={(e) => 
                                            setExtraInputGastosComerciales({
                                                ...extraInputGastosComerciales, 
                                                option_6: {
                                                    ...extraInputGastosComerciales.option_6,
                                                    name: e.target.value
                                                }
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Row>
                                <Col className="d-flex">
                                    <button 
                                        type="button"
                                        onClick={() => setModalGastosComerciales(false)} 
                                        className="btn btn-secondary btn-block btn-height-3 br-btn-t"
                                    >
                                        Cancelar
                                    </button>
                                </Col>
                                <Col className="d-flex">
                                    <button 
                                        type="button" 
                                        className="btn btn-primary btn-block btn-height-3 br-btn-t"
                                        onClick={() => handleInputsComerciales()}
                                    >
                                        Agregar
                                    </button>
                                </Col>
                            </Row>
                        </Modal.Footer>
                    </Modal>
                ) : null 
            }
            {
                modal ? ( 
                    <Modal show={modal}>
                        <Modal.Header>Seleccione los campos que quiera agregar</Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col className="d-flex mb-1">
                                    <Form.Check 
                                        type="checkbox" 
                                        onClick={() => setExtraInputGastosPersonales({...extraInputGastosPersonales, option_1: {...extraInputGastosPersonales.option_1, bol: !extraInputGastosPersonales.option_1.bol}})}
                                        className="margin-auto"
                                        defaultChecked={extraInputGastosPersonales.option_1.bol}
                                    />
                                    <input 
                                        className="form-control p-form btn-sm"
                                        type="text"
                                        value={extraInputGastosPersonales.option_1.name}
                                        onChange={(e) => 
                                            setExtraInputGastosPersonales({
                                                ...extraInputGastosPersonales, 
                                                option_1: {
                                                    ...extraInputGastosPersonales.option_1,
                                                    name: e.target.value
                                                }
                                            })
                                        }
                                    />
                                </Col>
                                <Col className="d-flex mb-1">
                                    <Form.Check 
                                        type="checkbox" 
                                        onClick={() => setExtraInputGastosPersonales({...extraInputGastosPersonales, option_2: {...extraInputGastosPersonales.option_2, bol: !extraInputGastosPersonales.option_2.bol}})}
                                        className="margin-auto"
                                        defaultChecked={extraInputGastosPersonales.option_2.bol}
                                    />
                                    <input 
                                        className="form-control p-form btn-sm"
                                        type="text"
                                        value={extraInputGastosPersonales.option_2.name}
                                        onChange={(e) => 
                                            setExtraInputGastosPersonales({
                                                ...extraInputGastosPersonales, 
                                                option_2: {
                                                    ...extraInputGastosPersonales.option_2,
                                                    name: e.target.value
                                                }
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex mb-1">
                                    <Form.Check 
                                        type="checkbox" 
                                        onClick={() => setExtraInputGastosPersonales({...extraInputGastosPersonales, option_3: {...extraInputGastosPersonales.option_3, bol: !extraInputGastosPersonales.option_3.bol}})}
                                        className="margin-auto"
                                        defaultCheck={extraInputGastosPersonales.option_3.bol}
                                    />
                                    <input 
                                        className="form-control p-form btn-sm"
                                        type="text"
                                        value={extraInputGastosPersonales.option_3.name}
                                        onChange={(e) => 
                                            setExtraInputGastosPersonales({
                                                ...extraInputGastosPersonales, 
                                                option_3: {
                                                    ...extraInputGastosPersonales.option_3,
                                                    name: e.target.value
                                                }
                                            })
                                        }
                                    />
                                </Col>
                                <Col className="d-flex mb-1">
                                    <Form.Check 
                                        type="checkbox" 
                                        onClick={e => setExtraInputGastosPersonales({...extraInputGastosPersonales, option_4: {...extraInputGastosPersonales.option_4, bol: !extraInputGastosPersonales.option_4.bol}})} 
                                        className="margin-auto"
                                        defaultChecked={extraInputGastosPersonales.option_4.bol}
                                    />
                                    <input 
                                        className="form-control p-form btn-sm"
                                        type="text"
                                        value={extraInputGastosPersonales.option_4.name}
                                        onChange={(e) => 
                                            setExtraInputGastosPersonales({
                                                ...extraInputGastosPersonales, 
                                                option_4: {
                                                    ...extraInputGastosPersonales.option_4,
                                                    name: e.target.value
                                                }
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex mb-1">
                                    <Form.Check 
                                        type="checkbox" 
                                        onClick={e => setExtraInputGastosPersonales({...extraInputGastosPersonales, option_5: {...extraInputGastosPersonales.option_5, bol: !extraInputGastosPersonales.option_5.bol}})}
                                        className="margin-auto"
                                        defaultChecked={extraInputGastosPersonales.option_5.bol}
                                    />
                                    <input 
                                        className="form-control p-form btn-sm"
                                        type="text"
                                        value={extraInputGastosPersonales.option_5.name}
                                        onChange={(e) => 
                                            setExtraInputGastosPersonales({
                                                ...extraInputGastosPersonales, 
                                                option_5: {
                                                    ...extraInputGastosPersonales.option_5,
                                                    name: e.target.value
                                                }
                                            })
                                        }
                                    />
                                </Col>
                                <Col className="d-flex mb-1">
                                    <Form.Check 
                                        type="checkbox" 
                                        onClick={e => setExtraInputGastosPersonales({...extraInputGastosPersonales, option_6: {...extraInputGastosPersonales.option_6, bol: !extraInputGastosPersonales.option_6.bol}})}
                                        className="margin-auto"
                                        defaultChecked={extraInputGastosPersonales.option_6.bol}
                                    />
                                    <input 
                                        className="form-control p-form btn-sm"
                                        type="text"
                                        value={extraInputGastosPersonales.option_6.name}
                                        onChange={(e) => 
                                            setExtraInputGastosPersonales({
                                                ...extraInputGastosPersonales, 
                                                option_6: {
                                                    ...extraInputGastosPersonales.option_6,
                                                    name: e.target.value
                                                }
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Row>
                                <Col className="d-flex">
                                    <button 
                                        type="button"
                                        onClick={() => setModal(false)} 
                                        className="btn btn-secondary btn-block btn-height-3 br-btn-t"
                                    >
                                        Cancelar
                                    </button>
                                </Col>
                                <Col className="d-flex">
                                    <button 
                                        type="button" 
                                        className="btn btn-primary btn-block btn-height-3 br-btn-t"
                                        onClick={() => handleInputsPersonales()}
                                    >
                                        Agregar
                                    </button>
                                </Col>
                            </Row>
                        </Modal.Footer>
                    </Modal>
                ) : null 
            }
            {
                modalDeleteCustomField ? ( 
                    <Modal show={modalDeleteCustomField}>
                        <Modal.Header>Alerta</Modal.Header>
                        <Modal.Body>
                            ¿Estás seguro de que desea eliminar este campo?
                        </Modal.Body>
                        <Modal.Footer>
                            <Row>
                                <Col className="d-flex">
                                    <button 
                                        type="button"
                                        onClick={() => setModalDeleteCustomField(null)} 
                                        className="btn btn-secondary btn-block btn-height-3 br-btn-t"
                                    >
                                        Cancelar
                                    </button>
                                </Col>
                                <Col className="d-flex">
                                    <button 
                                        type="button" 
                                        className="btn btn-primary btn-block btn-height-3 br-btn-t"
                                        onClick={() => deleteCustomField(modalDeleteCustomField)}
                                    >
                                        Eliminar
                                    </button>
                                </Col>
                            </Row>
                        </Modal.Footer>
                    </Modal>
                ) : null 
            }
            {/* <div className="d-flex align-items-center justify-content-center"> */}
                
                <StepWizard nav={isStep ? <NavFormStep state={state} setErrors={setErrors} /> : null} transitions={"none"} isHashEnabled={false} initialStep={initialStep}>
                    {/* <FormStep title={"Bienvenida"} state={state} setState={setState} errors={errors} addInputPersonales={addInputPersonales}addInputComerciales={addInputComerciales} /> */}
                    <FormStep isStep={isStep} setIsStep={setIsStep} labels={labels} title={"Gastos Personales"} state={state} setState={setState} errors={errors} setCustomField={setCustomField} setModalDeleteCustomField={setModalDeleteCustomField} setModal={setModal} addInputPersonales={addInputPersonales} addInputComerciales={addInputComerciales} />
                    <FormStep isStep={isStep} setIsStep={setIsStep} labels={labels} title={"Transporte"} state={state} setState={setState} errors={errors} setCustomField={setCustomField} setModalDeleteCustomField={setModalDeleteCustomField} addInputPersonales={addInputPersonales} addInputComerciales={addInputComerciales} />
                    <FormStep isStep={isStep} setIsStep={setIsStep} labels={labels} title={"Educación"} state={state} setState={setState} errors={errors} setCustomField={setCustomField} setModalDeleteCustomField={setModalDeleteCustomField} addInputPersonales={addInputPersonales} addInputComerciales={addInputComerciales} />
                    {/* <FormStep isStep={isStep} setIsStep={setIsStep} labels={labels} title={"Gastos comerciales"} state={state} setState={setState} errors={errors} setCustomField={setCustomField} setModalDeleteCustomField={setModalDeleteCustomField} addInputPersonales={addInputPersonales} setGastosComerciales={setModalGastosComerciales} addInputComerciales={addInputComerciales} /> */}
                    <FormStep isStep={isStep} setIsStep={setIsStep} labels={labels} title={"Consumo"} state={state} setState={setState} errors={errors} setCustomField={setCustomField} setModalDeleteCustomField={setModalDeleteCustomField} addInputPersonales={addInputPersonales} addInputComerciales={addInputComerciales} />
                    <FormStep isStep={isStep} setIsStep={setIsStep} labels={labels} title={"Extras"} state={state} setState={setState} errors={errors} setCustomField={setCustomField} setModalDeleteCustomField={setModalDeleteCustomField}  setModal={setModal} addInputPersonales={addInputPersonales} addInputComerciales={addInputComerciales} />
                    <FormStep isStep={isStep} setIsStep={setIsStep} labels={labels} title={"Información Adicional"} state={state} setState={setState} errors={errors} setCustomField={setCustomField} setModalDeleteCustomField={setModalDeleteCustomField} setErrors={setErrors} addInputPersonales={addInputPersonales} addInputComerciales={addInputComerciales} />
                    <FormStep isStep={isStep} setIsStep={setIsStep} labels={labels} title={"Sueldo"} state={state} setState={setState} errors={errors} setCustomField={setCustomField} setModalDeleteCustomField={setModalDeleteCustomField} setErrors={setErrors} addInputPersonales={addInputPersonales} addInputComerciales={addInputComerciales} />
                    <FormStep isStep={isStep} setIsStep={setIsStep} labels={labels} title={"Resultados"} state={state} setState={setState} errors={errors} setCustomField={setCustomField} setModalDeleteCustomField={setModalDeleteCustomField} addInputPersonales={addInputPersonales} addInputComerciales={addInputComerciales} save={save} />
                    <ModalNeedHelp modalNeedHelp={modalNeedHelp} setModalNeedHelp={setModalNeedHelp}/>
                </StepWizard>

                <Modal show={showModalSueldo} onHide={() => saveShowModalSueldo(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Sueldo emprendedor</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>Bienvenida. En esta sección te ayudaré a calcular tu sueldo emprendedor. Sé que este suele ser un tema difícil para emprendedoras como vos pero tranquila que te iré guiando paso a paso.</p>
                        <p>Tené en cuenta que todos los valores que te pediré son de régimen mensual.</p>
                    </Modal.Body>
                </Modal>

            {/* </div> */}
        </>
    )
}

const ModalNeedHelp = ({modalNeedHelp, setModalNeedHelp, goToStep})=>{
    return(
        <Modal show={modalNeedHelp} className="modal-danger">
            <Modal.Header>
                <h5 className="modal-title color-primary">¿Necesitas ayuda?</h5>
                <button type="button" className="close color-primary" onClick={() => {
                    goToStep(1)
                    setModalNeedHelp(null)
                }}>
                    <span>×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                Te puedo ayudar a calcular tu sueldo en base a tus gastos. ¿Qué te parece?
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="submit"
                    className="btn btn-primary br-btn-t color-white"
                    style={{width: '100%', minHeight: '60px'}}
                    onClick={()=>{
                        goToStep(1)
                        setModalNeedHelp(null)
                    }}
                >
                    Sí, ayudame a calcular mi sueldo
                </button>
                <button
                    type="submit"
                    className="btn btn-secondary br-btn-t color-white"
                    style={{width: '100%', minHeight: '60px'}}
                    onClick={()=>{
                        goToStep(7)
                        setModalNeedHelp(null)
                    }}
                >
                    No , ya conozco el valor de mi sueldo y quiero agregarlo directamente
                </button>
            </Modal.Footer>
        </Modal>
    )
}
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import CreateVendor from './createVendor';
import FormSupplies from '../component/FormSupplies';

export default function CreateSupplies({ setSupplyAdded, setAddSupplies }) {
    const history = useHistory();
    const [vendors, setVendors] = useState([])
    const [disabled, setDisabled] = React.useState(false);
    const [idVendor, setId] = useState(null)
    const [addVendors, setAddVendors] = useState(false)
    const [state, setState] = useState({
        name: '',
        unit_measurement: '',
        element_height: 0,
        element_width: 0,
        square_meter: 0,
        cost_unit: '',
        cost_per_unit_of_use: 0,
        provider: '',
        current_stock_in_units: 0,
        current_stock_in_cost: '',
        ideal_stock_in_units: 0,
        stock_replenish: 0,
        stock_replenish_in_cost: '',
        supply_type: 'supply',
    })

    const [errors, setErrors] = useState({
        name: {
            bol: false,
            msg: ''
        },
        unit_measurement: {
            bol: false,
            msg: ''
        },
        element_height: {
            bol: false,
            msg: ''
        },
        element_width: {
            bol: false,
            msg: ''
        },
        square_meter: {
            bol: false,
            msg: ''
        },
        cost_unit: {
            bol: false,
            msg: ''
        },
        cost_per_unit_of_use: {
            bol: false,
            msg: ''
        },
        provider: {
            bol: false,
            msg: ''
        },
        current_stock_in_units: {
            bol: false,
            msg: ''
        },
        current_stock_in_cost: {
            bol: false,
            msg: ''
        },
        ideal_stock_in_units: {
            bol: false,
            msg: ''
        },
        stock_replenish: {
            bol: false,
            msg: ''
        },
        stock_replenish_in_cost: {
            bol: false,
            msg: ''
        }
    })

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/providers/`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setVendors(json)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addVendors])

    React.useEffect(() => {
        setState({
            ...state,
            provider: idVendor
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idVendor])

    const onSubmit = (e) => {
        e.preventDefault();
        const err = {}

        Object.keys(state).map(x => {
            if (
                x === "element_height" ||
                x === "element_width" ||
                x === "square_meter" ||
                x === "stock_replenish" ||
                x === "stock_replenish_in_cost" ||
                x === "current_stock_in_cost" ||
                x === "provider"
            ) {
                return err[x] = {
                    bol: false,
                    msg: ''
                };
            } else {
                if (!state[x]) {
                    if (state[x] === 0) {
                        return err[x] = {
                            bol: false,
                            msg: ''
                        };
                    } else {
                        return err[x] = {
                            bol: true,
                            msg: 'Este campo no puede estar vacío'
                        };
                    }
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    };
                }
            }
        })

        setErrors(err);

        if (Object.values(err).filter(x => x.bol === true).length === 0) {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
            myHeaders.append("Content-Type", 'application/json')
            setDisabled(true)
            fetch(`${process.env.REACT_APP_URL_API}/api/supplies/`, {
                'method': 'POST',
                'body': JSON.stringify(state),
                'headers': myHeaders
            })
                .then(resp => resp.json())
                .then(json => {
                    if (history.location.pathname.includes('product')) {
                        toast.success('Insumo creado exitosamente', {
                            className: "Toastify__toast--success"
                        })
                        if (setAddSupplies) setAddSupplies(false)
                    } else {
                        if (json.id) {
                            setDisabled(false)
                            toast.success('Insumo creado exitosamente', {
                                className: "Toastify__toast--success"
                            })
                            history.push('/supplies')
                        }
                    }
                })
        }
        if (setSupplyAdded) setSupplyAdded(state)
    }

    return (
        <>
            {
                <Modal show={addVendors} className="modal-create">
                    <Modal.Header>
                        <h5 className="modal-title color-primary">{'Agregar proveedor'}</h5>
                        <button type="button" className="close color-primary" onClick={() => setAddVendors(false)}>
                            <span>×</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <CreateVendor setAddVendors={setAddVendors} addVendors={addVendors} setId={setId} />
                    </Modal.Body>
                </Modal>
            }
            <FormSupplies
                title={"Agregar insumo"}
                state={state}
                setState={setState}
                errors={errors}
                back={'/supplies'}
                onSubmit={onSubmit}
                vendors={vendors}
                setAddVendors={setAddVendors}
                addVendors={addVendors}
                idVendor={idVendor}
                setDisabled={setDisabled}
                disabled={disabled}
            />
        </>
    )
}
import React from 'react';
import Button from './Button'

export default function InputControlled(props) {
    return (
        <div className={props.classContainer}>
            <label className={props.classLabel}>{props.textLabel}</label>
            <Button
                type={props.typeBtn}
                name={props.nameBtn}
                style={props.styleBtn}
                value={props.valueBtn}
                setValue={props.setValue}
                state={props.state}
                read={props.read}
            />
            <div className={props.classError}>
                {props.textError}
            </div>
        </div>
    )
}

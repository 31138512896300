import React, { useState, useEffect } from 'react';
import FormSetting from '../component/FormSetting';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getConfigSupply, getPayUser } from '../redux/action';
import Loading from '../component/Loading';


export default function Settings({setReload, reload}) {
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({
        margin_surcharge: {
            bol: false,
            msg: '',
        },
        wholesale: {
            bol: false,
            msg: ''
        },
        supplies: {
            bol: false,
            msg: ''
        },
        margin_wholesaler_surcharge: {
            bol: false,
            msg: ''
        },
        additional_salaries: {
            bol: false,
            msg: ''
        },
        tax: {
            bol: false,
            msg: ''
        }
    })
    const userIsPayment = useSelector(stee => stee?.userPremiumPay)

    useEffect(() => {
        dispatch(getConfigSupply())
        dispatch(getPayUser())
    }, [dispatch])

    const [state, setState] = useState(null)

    useEffect(() => {
        const myHeaders = new Headers();

        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        setLoading(true)

        fetch(`${process.env.REACT_APP_URL_API}/api/config/global/`, {
            'method': "GET",
            'headers': myHeaders,
        })
            .then(resp => resp.json())
            .then(json => {
                setResult(json)
                setLoading(false)
                setState({
                    ...state,
                    margin_wholesaler_surcharge: json?.results?.filter(el => el.key === "margin_wholesaler_surcharge")[0]?.value || 0,
                    margin_surcharge: json?.results?.filter(el => el.key === "margin_surcharge")[0]?.value || 0,
                    wholesale: json?.results?.filter(el => el.key === "wholesale")[0]?.value || undefined,
                    supplies: json?.results?.filter(el => el.key === "supplies")[0]?.value || undefined,
                    additional_salaries: json?.results?.filter(el => el.key === "additional_salaries")[0]?.value || 0,
                    tax: json?.results?.filter(el => el.key === "tax")[0]?.value || 0,
                    quota_financing: json?.results?.filter(el => el.key === "quota_financing")[0]?.value || 0,
                    platform_fee: json?.results?.filter(el => el.key === "platform_fee")[0]?.value || 0,
                })
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const onSubmit = (e) => {
        e.preventDefault();

        const err = {}

        Object.keys(state).map(x => {
            if (x === "margin_surcharge" || x === "margin_wholesaler_surcharge" || x === "additional_salaries" || x === "tax") {
                return err[x] = {
                    bol: false,
                    msg: ''
                };
            } else {
                if (((!state[x] && !userIsPayment?.plan?.is_free) || (!state[x] && userIsPayment?.plan?.is_free && x === 'supplies') ) && x !== 'platform_fee' && x !== 'quota_financing') {
                    return err[x] = {
                        bol: true,
                        msg: 'Debe seleccionar una opción'
                    };
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    };
                }
            }
        })

        setError(err)

        if (Object.values(err).filter(x => x.bol === true).length === 0) {
            setDisabled(true)
            setTimeout(() => {
                setDisabled(false);
            }, 1500)

            const myHeaders = new Headers();

            myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
            myHeaders.append("Content-Type", 'application/json');

            const body = [
                {
                    key: 'margin_surcharge',
                    value: state.margin_surcharge !== '' ? state.margin_surcharge : 0,
                },
                {
                    key: 'wholesale',
                    value: state.wholesale
                },
                {
                    key: 'supplies',
                    value: state.supplies
                },
                {
                    key: 'margin_wholesaler_surcharge',
                    value: state.margin_wholesaler_surcharge !== '' ? state.margin_wholesaler_surcharge : 0
                },
                {
                    key: 'additional_salaries',
                    value: state.additional_salaries !== '' ? state.additional_salaries : 0
                },
                {
                    key: 'tax',
                    value: state.tax !== '' ? state.tax : 0
                },
                {
                    key: 'quota_financing',
                    value: state.quota_financing !== '' ? state.quota_financing : 0
                },
                {
                    key: 'platform_fee',
                    value: state.platform_fee !== '' ? state.platform_fee : 0
                }
            ]

            body.map(async el => {
                if (result.results.filter(arr => arr.key === el.key).length) {
                    const element = result.results.filter(arr => arr.key === el.key)[0]
                    element.value = el.value
                    const resp = await fetch(`${process.env.REACT_APP_URL_API}/api/config/global/${element.id}/`, {
                        'method': "PUT",
                        'headers': myHeaders,
                        'body': JSON.stringify(el)
                    });
                    
                    setReload(!reload)
                    return resp 
                } else {
                    const resp = await fetch(`${process.env.REACT_APP_URL_API}/api/config/global/`, {
                        'method': "POST",
                        'headers': myHeaders,
                        'body': JSON.stringify(el)
                    });
                    setReload(!reload)
                    return resp  
                }
            })

            const event = new Event('build');
            window.dispatchEvent(event);

            toast.success('Cambios modificados exitosamente', {
                className: "Toastify__toast--success"
            });
        }
    }

    return (
        <>
            <FormSetting
                state={state}
                setState={setState}
                disabled={disabled}
                error={error}
                setError={setError}
                onSubmit={onSubmit}
            />
            {loading ? <Loading /> : null}
        </>
    )
}
import React, { useEffect, useState } from 'react'
import ModalComponent from '../component/Modal';
import TableResponsive from '../component/Table';
import { useDispatch } from 'react-redux';
import { deleteAllSupplies, deleteSupplies, getPayUser, getSupplies, pageSupplies } from '../redux/action';
// import { toast } from 'react-toastify';

export default function Supplies() {
    const [search, setSearch] = useState('');
    const [id, setId] = useState();
    const dispatch = useDispatch();
    const [data, setData] = useState()
    const [page, setPage] = useState(1);
    const [reload, setReload] = useState(false);
    const [modal, setModal] = useState(false);
    const [deleteAll, setDeleteAll] = useState([]);
    const [modalDelete, setModalDelete] = useState(false)
    const [disabled, setDisabled] = React.useState(false);

    useEffect(() => {
        dispatch(getSupplies())
        dispatch(getPayUser())
    }, [dispatch])

    useEffect(() => {
        const myHeaders = new Headers();

        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/supplies/?page=${page}`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                if(json.detail) {
                    setPage(page-1)
                } else {
                    setData(json)
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload, dispatch, disabled, page])

    const handleSubmit = (e) => {
        e.preventDefault();
        const myHeaders = new Headers();

        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/supplies/?search=${e.target.value}`, {
            method: 'GET',
            headers: myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setData(json)
            })
    }

    const onDelete = (e) => {
        // e.preventDefault();
        // setDisabled(true);
        // setTimeout(() => {
        //     setDisabled(false);
        // }, 1500)
        // dispatch(deleteSupplies(id))
        // setReload(!reload)

        onDeleteAll(e)
    }

    const onDeleteAll = (e) => {
        e.preventDefault();
        setDisabled(true);
        setTimeout(() => {
            setDisabled(false);
        }, 1500)

        dispatch(deleteAllSupplies(deleteAll))

        setReload(!reload)
        setDeleteAll([]);
    }

    const pageData = (e, url) => {
        e.preventDefault();
        setDisabled(true);
        setTimeout(() => {
            setDisabled(false);
        }, 1500)
        dispatch(pageSupplies(url))
    }

    return (
        <>
            {
                modalDelete ? (
                    <ModalComponent
                        open={modalDelete}
                        title={deleteAll && deleteAll.length === 1 ? "Eliminar item" : "Eliminar items"}
                        body={deleteAll && deleteAll.length === 1 ? "¿Esta segura de eliminar este item?" : `¿Esta segura de eliminar ${deleteAll.length} insumos?`}
                        setModal={setModalDelete}
                        onDelete={onDeleteAll}
                    />
                ) : null
            }
            {
                modal ? (
                    <ModalComponent
                        open={modal}
                        title={"Eliminar item"}
                        body={"¿Esta segura de eliminar este item?"}
                        setModal={setModal}
                        onDelete={onDelete}
                    />
                ) : null
            }
            <TableResponsive
                data={data}
                search={search}
                setSearch={setSearch}
                handleSubmit={handleSubmit}
                toCreate={"/supplies/create"}
                toCreateWorkforce={"/supplies/create-workforce"}
                toEdit={'/supplies/edit'}
                modal={modal}
                setModal={setModal}
                setId={(id) => setDeleteAll([String(id)])}
                page={page}
                setPage={setPage}
                next={data?.next}
                previous={data?.previous}
                pageData={pageData}
                reload={reload}
                setReload={setReload}
                id={id}
                setDeleteAll={setDeleteAll}
                setModalDelete={setModalDelete}
                disabled={disabled}
                deleteAll={deleteAll}
            />
        </>
    )
}
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import CreateVendor from './createVendor';
import FormWithdrawals from '../component/FormWithdrawals';
import ModalCommercialData from '../component/ModalCommercialData';

export default function CreateWithdrawals() {
    const history = useHistory();
    const [reload, setReload] = React.useState(false)
    const [disabled, setDisabled] = React.useState(false);
    const [vendors, setVendors] = React.useState([]);
    const [expensesCommercial, setExpensesCommercial] = React.useState([])
    const [idVendor, setId] = useState(undefined);
    const [input, setInput] = React.useState({
        title: '',
        value: '',
    })
    const [addVendors, setAddVendors] = React.useState(false);
    const [addCommercialData, setAddCommercialData] = React.useState({
        bol: false,
        title: 'Agregue el nombre del gasto que desea agregar y su monto'
    })
    const date = new Date();
    let dateForState;
    if (date.getMonth() < 10) {
        dateForState = `${date.getFullYear()}-0${date.getMonth()+1}-${date.getDate()}`;
    } if (date.getDate() < 10) {
        dateForState = `${date.getFullYear()}-${date.getMonth()+1}-0${date.getDate()}`;
    } else {
        dateForState = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
    }
    const [state, setState] = useState({
        date: `${dateForState}`,
        concept: '',
        amount_whithout_fees: '',
        amount: '',
        method_payment: '',
        provider: '',
        details: '',
        type: 'payment',
        spend: null,
        payment_status: 'pending'
    })

    const [settings, setSettings] = useState(null)

    const [errors, setErrors] = useState({
        date: {
            bol: false,
            msg: ''
        },
        concept: {
            bol: false,
            msg: ''
        },
        amount: {
            bol: false,
            msg: ''
        },
        method_payment: {
            bol: false,
            msg: ''
        },
        status_payment: {
            bol: false,
            msg: ''
        },
        client: {
            bol: false,
            msg: ''
        },
        status_order: {
            bol: false,
            msg: ''
        },
        details: {
            bol: false,
            msg: ''
        }
    })

    React.useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/commertial-expenses?pagination=false`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setExpensesCommercial(json)
            })
    }, [reload])

    React.useEffect(() => {
        setState({
            ...state,
            provider: idVendor
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idVendor])

    React.useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/providers/`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setVendors(json)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addVendors])

    React.useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/providers/`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setVendors(json)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addVendors])

    useEffect(() => {
        const myHeaders = new Headers();

        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/config/global/`, {
            'method': "GET",
            'headers': myHeaders,
        })
            .then(resp => resp.json())
            .then(json => {
                setSettings({
                    ...settings,
                    margin_wholesaler_surcharge: json?.results?.filter(el => el.key === "margin_wholesaler_surcharge")[0]?.value || 0,
                    margin_surcharge: json?.results?.filter(el => el.key === "margin_surcharge")[0]?.value || 0,
                    wholesale: json?.results?.filter(el => el.key === "wholesale")[0]?.value || undefined,
                    supplies: json?.results?.filter(el => el.key === "supplies")[0]?.value || undefined,
                    additional_salaries: json?.results?.filter(el => el.key === "additional_salaries")[0]?.value || 0,
                    tax: json?.results?.filter(el => el.key === "tax")[0]?.value || 0,
                    quota_financing: json?.results?.filter(el => el.key === "quota_financing")[0]?.value || 0,
                    platform_fee: json?.results?.filter(el => el.key === "platform_fee")[0]?.value || 0,
                })
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSubmit = (e) => {
        e.preventDefault();

        const err = {}

        Object.keys(state).filter(x => x !== "details").map(x => {
            if (
                x === "date" ||
                x === "amount"
                //x === "concept"
            ) {
                if (!state[x]) {
                    return err[x] = {
                        bol: true,
                        msg: 'Este campo no puede estar vacío'
                    };
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    };
                }
            } else {
                return err[x] = {
                    bol: false,
                    msg: ''
                };
            }
        })

        setErrors(err);

        if (Object.values(err).filter(x => x.bol === true).length === 0) {
            setDisabled(true)
            setTimeout(() => {
                setDisabled(false);
            }, 1500)
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
            myHeaders.append("Content-Type", 'application/json')

            fetch(`${process.env.REACT_APP_URL_API}/api/expenses/`, {
                'method': "POST",
                'body': JSON.stringify(state),
                'headers': myHeaders
            })
                .then(resp => resp.json())
                .then(json => {
                    if (json.id) {
                        toast.success('Egreso creado exitosamente', {
                            className: "Toastify__toast--success"
                        })
                        history.push(`/withdrawals`)
                    } else {
                        toast.success(json.amount, {
                            className: "Toastify__toast--error"
                        })
                    }
                })
        }
    }

    const onSubmitExpenses = (e) => {
        e.preventDefault();

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        const data = {
            name: input.title,
            value: input.value
        }

        if (expensesCommercial.filter(x => x.name === input.title).length) {
            toast.success("Ya existe un gasto con este nombre", {
                className: "Toastify__toast--error"
            });
        } else (
            fetch(`${process.env.REACT_APP_URL_API}/api/commertial-expenses/`, {
                'method': "POST",
                'body': JSON.stringify(data),
                'headers': myHeaders
            })
                .then(resp => resp.json())
                .then(json => {
                    if (json.id) {
                        toast.success("Gasto agregado exitosamente", {
                            className: "Toastify__toast--success"
                        });
                        setReload(!reload)
                        setInput({
                            title: '',
                            value: '',
                        })
                        setState({
                            ...state,
                            spend: json.id
                        })
                    }
                })
        )
    }

    return (
        <>
            {
                <ModalCommercialData 
                    modal={addCommercialData} 
                    setModal={setAddCommercialData} 
                    input={input}
                    setInput={setInput}
                    setDisabled={setDisabled}
                    disabled={disabled}
                    onSubmit={onSubmitExpenses}
                />
            }
            {
                <Modal show={addVendors} className="modal-create">
                    <Modal.Header>
                        <h5 className="modal-title color-primary">{'Agregar proveedor'}</h5>
                        <button type="button" className="close color-primary" onClick={() => setAddVendors(false)}>
                            <span>×</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <CreateVendor setAddVendors={setAddVendors} addVendors={addVendors} setId={setId} />
                    </Modal.Body>
                </Modal>
            }
            <FormWithdrawals
                title={'Agregar pago'}
                state={state}
                setState={setState}
                back={'/withdrawals'}
                errors={errors}
                vendors={vendors}
                setAddVendors={setAddVendors}
                addVendors={addVendors}
                idVendor={idVendor}
                onSubmit={onSubmit}
                setDisabled={setDisabled}
                disabled={disabled}
                expensesCommercial={expensesCommercial}
                setAddCommercialData={setAddCommercialData}
                settings={settings}
            />
        </>
    )
}
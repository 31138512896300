import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Button from './Button';

export const FormSuppliesWorkforce = ({ title, state, errors, setState, back, onSubmit, id, setModal, disabled, vendors, idVendor, addVendors, setAddVendors }) => {
    const history = useHistory();
    return (
        <>
            <div className={history.location.pathname.includes('product') ? null : "card mb-1"} >
                    <Row>
                        {
                            history.location.pathname.includes('product') ? null : (
                                <div className="card-header">
                                    <div className="card-title" style={{ margin: "0" }}>
                                        <Col sm="12">
                                            <h2 style={{ margin: "0" }}>
                                                {title}
                                            </h2>
                                        </Col>
                                    </div>
                                </div>
                            )
                        }
                        <div className={`card-body`} style={{ padding: "1.5rem 2.5rem 0" }}>
                            <form className="d-flex flex-direction-column justify-content-sb">
                                <Row>
                                    <Col sm="12" md="12" className="mb-1">
                                        <div className="form-group">
                                            <label className="form-label fs-6">Nombre</label>
                                            <Button
                                                type={'text'}
                                                name={'name'}
                                                style={errors?.name?.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                                value={state.name}
                                                setValue={setState}
                                                state={state}
                                                //read={history.location.pathname.includes('clients/view') ? true : undefined}
                                            />
                                            <div className={errors.name.bol ? "invalid-input" : "valid-input"}>
                                                {errors.name.msg}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12" md="4" className="mb-1">
                                        <div className="form-group">
                                            <label className="form-label fs-6">Proveedor</label>
                                            {
                                                history.location.pathname.includes('products/view') ? (
                                                    <input
                                                        type={'text'}
                                                        className="form-control p-form btn-height-3 br-btn-t btn-disabled"
                                                        value={state.provider}
                                                        readOnly
                                                    />
                                                ) : (
                                                    <>
                                                        <select
                                                            onChange={(e) => setState({
                                                                ...state,
                                                                provider: e.target.value
                                                            })}
                                                            value={state.provider ? state.provider : undefined}
                                                            className={errors.provider.bol ? "custom-select btn-height-3 error-input" : "custom-select btn-height-3"}
                                                        >
                                                            <option value={undefined}>Seleccione un proveedor</option>
                                                            {
                                                                vendors.results ? vendors.results.map(el => {
                                                                    return (
                                                                        el.id === idVendor ? (
                                                                            <option key={el.id} default value={el.id}>{el.name}</option>
                                                                        ) : (
                                                                            <option key={el.id} value={el.id}>{el.name}</option>
                                                                        )
                                                                    )
                                                                }) : null
                                                            }
                                                        </select>
                                                        <button
                                                            type='button'
                                                            className="linkModal mt-1 color-primary background-transparent border-none"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setAddVendors(!addVendors)
                                                            }}
                                                        >
                                                            + Agregar proveedor
                                                        </button>
                                                    </>
                                                )
                                            }
                                            <div className={errors.provider.bol ? "invalid-input" : "valid-input"}>
                                                {errors.provider.msg}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="12" md="4">
                                        <div className="form-group">
                                            <label className="form-label fs-6">Hora, Minutos, Unidad</label>
                                            <Button
                                                type={'text'}
                                                name={'work_unit'}
                                                style={errors?.work_unit?.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                                value={state?.work_unit}
                                                setValue={setState}
                                                state={state}
                                            />
                                            <div className={errors?.work_unit?.bol ? "invalid-input" : "valid-input"}>
                                                {errors?.work_unit?.msg}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="12" md="4">
                                        <div className="form-group">
                                            <label className="form-label fs-6">Valor de unidad</label>
                                            <Button
                                                type={'text'}
                                                name={'cost_unit'}
                                                style={errors?.cost_unit?.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                                value={state.cost_unit}
                                                setValue={setState}
                                                state={state}
                                            />
                                            <div className={errors?.cost_unit?.bol ? "invalid-input" : "valid-input"}>
                                                {errors?.cost_unit?.msg}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </Row>
            </div>
            <Row className="mt-2" style={window.innerWidth > 991 ? null : { display: "flex" }}>
                <div
                    className={window.innerWidth > 991 ? "col" : null}
                    style={
                        history.location.pathname.includes('view') ?
                            { display: "flex", justifyContent: "space-between" } :
                            window.innerWidth < 992 ? { width: "75%" } :
                                null
                    }
                >
                    {
                        id ? (
                            <>
                                <Link to={back}>
                                    <button type="submit" className="btn btn-outline-secondary btn-height-3 br-btn-t mr-1">Cancelar</button>
                                </Link>
                                <button
                                    className="btn btn-primary btn-height-3 br-btn-t color-white"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setModal(true)
                                    }}
                                >
                                    Borrar
                                </button>
                            </>
                        ) : null
                    }
                </div>
                {
                    history.location.pathname.includes('view') ?
                        null : (
                            <div
                                style={
                                    window.innerWidth > 991 ?
                                        null : { width: "25%" }
                                }
                                className={
                                    window.innerWidth > 991 ?
                                        "col d-flex justify-content-flex-end" :
                                        "d-flex justify-content-flex-end"
                                }
                            >
                                {
                                    history.location.pathname.includes('supplies') ? (
                                        !id ? (
                                            <Link to={back}>
                                                <button type="submit" className="btn btn-outline-secondary btn-height-3 br-btn-t mr-1">
                                                    {
                                                        history.location.pathname.includes('view') ?
                                                            'Volver' : 'Cancelar'
                                                    }
                                                </button>
                                            </Link>
                                        ) : null
                                    ) : (
                                        null
                                    )
                                }
                                {
                                    !disabled ? (
                                        <button type="submit" className="btn background-secondary btn-height-3 br-btn-t color-white" onClick={e => {
                                            onSubmit(e);
                                        }}>Guardar</button>
                                    ) : (
                                        <button type="submit" className="btn background-secondary btn-height-3 br-btn-t color-white" disabled={true}>
                                            <span className="spinner-border spinner-border-sm">
                                                <span className="sr-only"></span>
                                            </span>
                                        </button>
                                    )
                                }
                            </div>
                        )
                }
            </Row>
        </>
    )
}

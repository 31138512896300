import React, { useState } from 'react';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FlagOutlined from '@material-ui/icons/FlagOutlined';
import { useHistory, Link, Redirect } from 'react-router-dom';
// import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';

export default function MenuSuperior({ setClick, click, img, user }) {
    const [state, setState] = useState(false);
    const history = useHistory();
    const handleSubmit = (e) => {
        e.preventDefault();
        localStorage.removeItem('flor_emprendedora');
        history.push('/login');
    }

    const openAppTour = () => {
        const event = new Event('openapptour');
        window.dispatchEvent(event)
    }

    if (!localStorage.getItem('flor_emprendedora')) {
        return <Redirect to='/login' />
    }

    return (
        <nav className={`navbar header-navbar navbar navbar-shadow align-items-center navbar-light navbar-expand floating-nav ${!click ? "width-colapse-1" : "width-colapse-2"}`}>
            <div className="navbar-container d-flex content align-items-center">
                <div className="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex row">
                    <ul className="navbar-nav nav col">
                        {
                            window.innerWidth < 992 ? (
                                <li className="nav-item">
                                    <button className="nav-link btn" onClick={() => setClick(!click)}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="21px"
                                            height="21px"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-menu"
                                        >
                                            <line x1="3" y1="12" x2="21" y2="12"></line>
                                            <line x1="3" y1="6" x2="21" y2="6"></line>
                                            <line x1="3" y1="18" x2="21" y2="18"></line>
                                        </svg>
                                    </button>
                                </li>
                            ) : null
                        }
                    </ul>
                    <ul className="navbar-nav nav justify-content-flex-end ml-auto col change-the-view pr-0">
                        {/* Descomentar para agregar icono de notificaciónes. */}
                        {/* <li className="dropdown-toggle d-flex align-items-center">
                            <NotificationsNoneIcon style={{ fontSize: "xx-large", margin: "0 1rem" }} />
                        </li> */}
                        <li className="dropdown-toggle d-flex align-items-center app-tour">
                            <FlagOutlined style={{ margin: '0 1rem', cursor: 'pointer' }} onClick={() => openAppTour()} />
                        </li>
                        <li className="dropdown-user nav-item dropdown">
                            <button className="background-transparent  border-none d-flex align-items-center" onClick={() => setState(!state)}>
                                {
                                    window.innerWidth > 991 ? (
                                        <div className="user-nav d-sm-flex user-details-menu">
                                            <p className="user-name font-weight-500 ">{!user ? '' : user.first_name}</p>
                                            <span className="user-status">{!user ? '' : user.email}</span>
                                        </div>
                                    ) : null
                                }
                                <div className="avatar">
                                    {
                                        !img ? (
                                            <div className="avatar-name-user">{!user ? '' : user?.first_name[0]}</div>
                                        ) : (
                                            <img src={img} alt="avatar" height="40" width="40" />
                                        )
                                    }
                                </div>
                            </button>
                            {
                                !state ? null : (
                                    <>
                                        <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%'}} onClick={() => setState(!state)}></div>
                                        <div className="card width-14-vw user-actions dropdown-menu dropdown-menu-right show">
                                            <div className="card-body padding-0">
                                                <div>
                                                    <Link to='/profile' className="d-flex align-items-center user-item dropdown-item padding-1 justify-content-flex-start">
                                                        <PersonIcon className="user-svg" />
                                                        <button
                                                            className="background-transparent border-none d-flex align-items-center ta-center color-inherit"
                                                        >
                                                            Perfil
                                                        </button>
                                                    </Link>
                                                </div>
                                                <div>
                                                    <div className='d-flex align-items-center user-item dropdown-item padding-1 justify-content-flex-start' style={{cursor:'pointer'}} onClick={e => handleSubmit(e)}>
                                                        <ExitToAppIcon className="user-svg" />
                                                        <button className="background-transparent border-none d-flex align-items-center ta-center color-inherit">Salir</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                    
                                    </>

                                )
                            }
                        </li>
                    </ul>
                </div>

            </div>
        </nav>
    )
}
import React, { useState } from 'react'
import { ButtonGroup, Table, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete'


export const TableIncomesSell = ({ dataAdding, handleDeleteProduct, setDataAdding, setModalNotEnoughStock, setModalProductionBlocking, setModalProduce, handleIncrement, setHandleIncrementParams, checksIfThereIsStockAvailableToProduce, msgStockToProduction}) => {

    /* let autoComplete = (inp, arr) => {
        let currentFocus;
        
    } */

    const handleValueDiscount = (e, dataAdding, data) => {
        e.preventDefault()

        const dataChange = dataAdding.filter(e => e.id === data.id)
        const dataFiltred = dataAdding.filter(e => e.id !== data.id)

        let newData;
        if (dataChange) {
            newData = {
                ...data,
                value_discount: (e.target.value.length <= 0)?0:e.target.value
            }
        }

        setDataAdding([
            ...dataFiltred,
            newData,
        ])
    }

    const handleTypeDiscount = (e, dataAdding, data) => {
        e.preventDefault()

        const dataChange = dataAdding.filter(e => e.id === data.id)
        const dataFiltred = dataAdding.filter(e => e.id !== data.id)
        
        let newData;
        if (dataChange) {
            newData = {
                ...data,
                type_discount: e.target.value
            }
        }

        setDataAdding([
            ...dataFiltred,
            newData,
        ])
    }

    const handleDecrement = (e, dataAdding, data) => {
        e.preventDefault();
        
        const dataChange = dataAdding.filter(e => e.id === data.id)
        const dataFiltred = dataAdding.filter(e => e.id !== data.id)

        let haveStock = checksIfThereIsStockAvailableToProduce(data.quantity-1, data)

        if(data.product_type === 'production' && !data.use_stock && haveStock[data.id]) {
            return;
        }

        let newData;
        if (dataChange) {
            newData = {
                ...data,
                quantity: data.quantity-1,
                sub_total: (data.quantity*data.cost_price)-data.cost_price
            }
        }
        
        setDataAdding([
            ...dataFiltred,
            newData,
        ])
    }

    const handleUseStock = (e, dataAdding, data, checked) => {        
        const dataChange = dataAdding.filter(e => e.id === data.id)
        const dataFiltred = dataAdding.filter(e => e.id !== data.id)

        let newData;
        if (dataChange) {
            newData = {
                ...data,
                use_stock: checked,
                quantity: 0
            }
        }
        
        setDataAdding([
            ...dataFiltred,
            newData,
        ])
    }

    dataAdding.sort(function (a, b) {
        return a.id - b.id;
    })

    return (
        <div className='table-incomes-sell'>
            {
                (dataAdding?.length <= 0)?null:(
                    <>
                        <Table responsive className="align-middle">
                            <thead>
                                <tr>
                                    {
                                        window.innerWidth > 991 ? (
                                            null
                                        ) : 
                                            <>
                                                <th>Producto</th>
                                                <th>Cantidad</th>
                                                <th>Descuento</th>
                                                <th>Total</th>
                                            </>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataAdding && dataAdding?.map((data) => {
                                        return (
                                            <>  
                                                <tr key={data.id}>
                                                    <td className='pl-0 pr-3 pt-4 pb-0 w-100'>
                                                        {data.name}
                                                    </td>
                                                    <td className='pl-3 pr-3 pt-4 pb-0'>  
                                                        <div style={{marginTop: -27}}>
                                                            <label className="d-flex" style={{fontSize: '.7rem', marginLeft: 2, marginBottom: (data.product_type === 'production') ? 0 : null}}>
                                                                <span className="d-flex">
                                                                    &nbsp;
                                                                    <input type="checkbox" className="ml-1" style={{visibility: 'hidden'}}/>
                                                                </span>
                                                            </label>
                                                            <ButtonGroup size="small" aria-label="small outlined button group">
                                                                <button className="btn btn-light btn-height br-btn-t" style={{borderColor: '#ced4da'}} disabled={(data.quantity <= 0) || (!data.use_stock) ? "disabled":""} onClick={(e)=>handleDecrement(e, dataAdding, data)}>-</button>
                                                                <button className="btn btn-light btn-height br-btn-t" style={{borderColor: '#ced4da'}} disabled>{data.quantity}</button>
                                                                <button className="btn btn-light btn-height br-btn-t" style={{borderColor: '#ced4da'}} disabled={(data.use_stock && data.quantity >= data.available_quantity || (!data.use_stock)) ? "disabled" : ""} onClick={(e)=>handleIncrement(e, dataAdding, data)}>+</button>
                                                            </ButtonGroup>
                                                        </div>

                                                    </td>
                                                    {
                                                        window.innerWidth < 992 ? (
                                                            null
                                                        ) : (
                                                            <>
                                                                <td className='pl-3 pr-3 pt-4 pb-0'>
                                                                    ${
                                                                        parseFloat(data.sale_price).toFixed(2)
                                                                    }
                                                                </td>
                                                            </>
                                                        )
                                                    }
                                                    <td className='pl-3 pr-3 pt-4 pb-0' style={{width: 150}}>
                                                        <div style={{marginTop: -30}}>
                                                            <label htmlFor="floatingSelect" style={{fontSize: '.7rem', marginLeft: 2}}>Descuento a realizar</label>
                                                            <div className='d-flex'>
                                                                <div className='w-50'>
                                                                    <input
                                                                        type='number'
                                                                        name={'details'}
                                                                        className={"d-flex br-btn-t resize-none border-radius form-control"}
                                                                        style={{
                                                                            width: '100%', 
                                                                            display:'flex', 
                                                                            flexDirection:'column', 
                                                                            height:'100%',
                                                                            border:'1px solid #d8d6de',
                                                                            borderTopLeftRadius: '.25rem',
                                                                            borderBottomLeftRadius: '.25rem',
                                                                            borderTopRightRadius: 0,
                                                                            borderBottomRightRadius: 0,
                                                                            outline:'none',
                                                                        }}
                                                                        value={data.value_discount}
                                                                        onChange={(e)=>handleValueDiscount(e, dataAdding, data)}
                                                                    />
                                                                </div>
                                                                <div className='w-50'>
                                                                    <select
                                                                        onChange={(e)=>handleTypeDiscount(e, dataAdding, data)}
                                                                        value={data.type_discount}
                                                                        className={
                                                                            "custom-select form-floating"
                                                                        }
                                                                        style={{
                                                                            width: '100%', 
                                                                            height: '100%', 
                                                                            color:'black',
                                                                            borderLeft: 'none',
                                                                            borderTopLeftRadius: 0,
                                                                            borderBottomLeftRadius: 0,
                                                                            borderTopRightRadius: '.25rem',
                                                                            borderBottomRightRadius: '.25rem',
                                                                        }}
                                                                    >
                                                                        <option value={'$'}>$</option>
                                                                        <option value={'%'}>%</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='pl-3 pr-0 pt-4 pb-0'>
                                                        <button type="submit" className="btn btn-height-1 color-black" onClick={(e)=>handleDeleteProduct(e, data.id)}>
                                                            <DeleteIcon className="color-primary-light" />
                                                        </button>
                                                    </td>
                                                </tr>
                                                { data.product_type === 'production' ?
                                                    <tr>
                                                        <div className="d-flex align-items-center width-100 justify-content-flex-start" style={{gap:'8px', padding: 0}}>
                                                            <div
                                                                className="form-group d-flex align-items-center width-auto margin-bottom-0 margin-0"
                                                            >
                                                                <div className="custom-control-primary custom-control custom-radio">
                                                                    <input id={`check-1-${data.id}`} type="radio" name={`type-${data.id}`} className="custom-control-input" defaultChecked={true} />
                                                                    <label
                                                                        htmlFor={`check-1-${data.id}`}
                                                                        className="custom-control-label checked"
                                                                        onClick={(e) => {
                                                                            handleUseStock(e, dataAdding, data, true)
                                                                            setModalNotEnoughStock((data.available_quantity) <= 0 ? true : false)
                                                                        }}
                                                                    >
                                                                        Usar stock
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="form-group d-flex align-items-center width-auto margin-bottom-0 margin-0"
                                                            >
                                                                <div className="custom-control-primary custom-control custom-radio">
                                                                    <input id={`check-2-${data.id}`}type="radio" name={`type-${data.id}`} className="custom-control-input" />
                                                                    <label
                                                                        htmlFor={`check-2-${data.id}`}
                                                                        className="custom-control-label checked"
                                                                        onClick={(e) => {
                                                                            handleUseStock(e, dataAdding, data, false)
                                                                            if (data.quantity_per_recipe > 1 ){
                                                                                setModalProductionBlocking(true)
                                                                            }
                                                                            else{
                                                                                setModalProduce(data)
                                                                                data.quantity = 0
                                                                                data.use_stock = false
                                                                                setHandleIncrementParams({
                                                                                    'e': e, 
                                                                                    'dataAdding': dataAdding, 
                                                                                    'data': data
                                                                                })
                                                                            }
                                                                        }}
                                                                    >
                                                                        Producir
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </tr>
                                                : null}
                                                <tr>
                                                    <td colSpan={5} className='pl-0 pt-0'>
                                                        {msgStockToProduction.supplies[data.id] ?
                                                            <p className='text-primary mb-0'><small>{msgStockToProduction.supplies[data.id].msg} <Link to={msgStockToProduction.supplies[data.id].url} className="fw-bold">{msgStockToProduction.supplies[data.id].name}</Link></small></p>
                                                        : null}
                                                        {msgStockToProduction.packaging[data.id] ?
                                                            <p className='text-primary mb-0'><small>{msgStockToProduction.packaging[data.id].msg} <Link to={msgStockToProduction.packaging[data.id].url} className="fw-bold">{msgStockToProduction.packaging[data.id].name}</Link></small></p>
                                                        : null}
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </>
                )
            }
        </div>
    )
}

import React, { useEffect, useRef } from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Tooltip } from 'reactstrap';
import { Row, Col, ButtonGroup, Table, Alert } from 'react-bootstrap';
import DeleteIcon from '@material-ui/icons/Delete'
import { useSelector } from 'react-redux';
import Button from './Button';
import {
    marginContribution,
    saleStockActual,
    costStockActual,
    priceSaleProduction,
    totalPackaging,
    priceCostProduction,
    stockToBeReplenishedCost
} from '../utils/functions';

import { NumericInput } from './NumericInput';

export default function FormReSale({ state, setState, errors, help, setHelp, history, marginSurcharge, group, setGroup, packaging, suppliesProduct, id, setAddPackaging }) {
    const inputSalePrice = useRef(null);

    const [automaticPriceCalculation, setAutomaticPriceCalculation] = React.useState(true);

    const handleDecrement = (e, el) => {
        e.preventDefault()

        const newGroup = group//.slice().find(x => parseInt(x.id) !== parseInt(el.id))
        const newElement = newGroup.slice().find(x => parseInt(x.id) === parseInt(el.id))
        newElement.quantity_group--
        
        setGroup([
            ...newGroup
        ])
    }

    const handleIncrement = (e, el) => {
        e.preventDefault()

        const newGroup = group//.slice().find(x => parseInt(x.id) !== parseInt(el.id))
        const newElement = newGroup.slice().find(x => parseInt(x.id) === parseInt(el.id))
        newElement.quantity_group++
        
        setGroup([
            ...newGroup
        ])
    }
    
    
    // Esta es la formula => (Precio de Costo + Costo del Packaging) + ((Precio de Costo + Costo del Packaging) * Márgen de recargo)
    let subtotalPackaging = 0;
    group?.forEach(element => {
        const sellPrice = element.amount;
        const quantity = element.quantity_group;
        
        subtotalPackaging += sellPrice*quantity
    });

    let totalMargin = (state.wholesaler === false) ? ((parseFloat(state.surcharge_minorista_wholesale) / 100)) : ((parseFloat(state.surcharge_wholesale) / 100))
    
    let subtotalSellPrice = (parseFloat(state.cost_price) + parseFloat(subtotalPackaging)) + ( (parseFloat(state.cost_price) + parseFloat(subtotalPackaging)) * parseFloat(totalMargin ? totalMargin : 0) );
    // subtotalSellPrice = subtotalSellPrice + (subtotalPackaging ? subtotalPackaging : 0)
    let contributionMargin = subtotalSellPrice - (parseFloat(state.cost_price) + parseFloat(subtotalPackaging));

    useEffect(()=>{
        setState({
            ...state,
            contribution_margin: contributionMargin,
            sale_price: state.automatic_price_calculation ? subtotalSellPrice : state.sale_price
        });
    }, [contributionMargin, subtotalSellPrice, state.automatic_price_calculation])

    const userIsPayment = useSelector(stee => stee?.userPremiumPay)

    return (
        <>
            <Row className="mb-1">
                <Col md="12">
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group mb-1 margin-bottom-0" >
                                <label className="form-label fs-6">Inventario</label>
                            </div>
                            <Row>
                                <Col sm="12" md={userIsPayment?.plan?.is_free ? 12 : 6} className="mb-1">
                                    <div className="form-group">
                                        <label className="form-label fs-6">Stock actual en unidades</label>
                                        <HelpOutlineIcon
                                            disabled
                                            style={{ marginLeft: "1rem", fontSize: "large" }}
                                            onMouseEnter={() => {
                                                setHelp('current_stock_in_units')
                                            }}
                                            onMouseLeave={() => {
                                                setHelp('')
                                            }}
                                            onClick={()=> {
                                                setHelp('current_stock_in_units')
                                                setTimeout(() => {
                                                    setHelp('')
                                                }, 1500)
                                                return
                                            }}
                                            id="current_stock_in_units"
                                        />
                                        <Tooltip placement="right" isOpen={help === "current_stock_in_units"} target="current_stock_in_units">
                                            La cantidad de unidades que tenés en stock actualmente.
                                        </Tooltip>
                                        {
                                            history.location.pathname.includes('products/view') ? (
                                                <NumericInput
                                                    value={state.current_stock_in_units}
                                                    readOnly = {true}
                                                    className={"btn-disabled form-control p-form  br-btn-t"}
                                                    decimalScale={0}
                                                />
                                            ) : (
                                                <>
                                                    <NumericInput
                                                        value={state.current_stock_in_units}
                                                        className={errors.current_stock_in_units.bol ? "form-control p-form  br-btn-t error-input" : "form-control p-form  br-btn-t"}
                                                        decimalScale={0}
                                                        onValueChange={e => {
                                                            setState({
                                                                ...state,
                                                                current_stock_in_units: e.value,
                                                                current_stock_in_cost: costStockActual({
                                                                    ...state,
                                                                    current_stock_in_units: e.value,
                                                                    margin_surcharge: state.surcharge_minorista_wholesale
                                                                }),
                                                                sale_actual_stock: saleStockActual({
                                                                    ...state,
                                                                    current_stock_in_units: e.value,
                                                                    margin_surcharge: state.surcharge_minorista_wholesale
                                                                })
                                                            })
                                                        }}
                                                    />
                                                    <div className={errors.current_stock_in_units.bol ? "invalid-input" : "valid-input"}>
                                                        {errors.current_stock_in_units.msg}
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                </Col>
                                {!userIsPayment?.plan?.is_free ?
                                <Col sm="12" md="6" className="mb-1">
                                    <div className="form-group">
                                        <label className="form-label fs-6">Stock ideal (unidades)</label>
                                        <HelpOutlineIcon
                                            disabled
                                            style={{ marginLeft: "1rem", fontSize: "large" }}
                                            onMouseEnter={() => {
                                                setHelp('ideal_stock_in_units')
                                            }}
                                            onMouseLeave={() => {
                                                setHelp('')
                                            }}
                                            onClick={()=> {
                                                setHelp('ideal_stock_in_units')
                                                setTimeout(() => {
                                                    setHelp('')
                                                }, 1500)
                                                return
                                            }}
                                            id="ideal_stock_in_units"
                                        />
                                        <Tooltip placement="right" isOpen={help === "ideal_stock_in_units"} target="ideal_stock_in_units">
                                            Esta es la cantidad de unidades que tu proyecto debería tener de cada producto.
                                        </Tooltip>
                                        <NumericInput
                                            value={state?.ideal_stock_in_units}
                                            readOnly = {history.location.pathname.includes('products/view') ? true : undefined}
                                            className={errors.ideal_stock_in_units.bol ? "form-control p-form  br-btn-t error-input" : "form-control p-form  br-btn-t"}
                                            decimalScale={0}
                                            onValueChange={(e)=>setState({ ...state, ['ideal_stock_in_units']: e.value })}
                                        />
                                        <div className={errors.ideal_stock_in_units.bol ? "invalid-input" : "valid-input"}>
                                            {errors.ideal_stock_in_units.msg}
                                        </div>
                                    </div>
                                </Col>
                                : null}
                            </Row>

                            {!userIsPayment?.plan?.is_free ?
                            <Row>
                                <Col sm="12" md="6" className="mb-1">
                                    <div className="form-group">
                                        <label className="form-label fs-6">Stock actual en $ (costo)</label>
                                        <HelpOutlineIcon
                                            disabled
                                            style={{ marginLeft: "1rem", fontSize: "large" }}
                                            onMouseEnter={() => {
                                                setHelp('current_stock_in_cost')
                                            }}
                                            onMouseLeave={() => {
                                                setHelp('')
                                            }}
                                            onClick={()=> {
                                                setHelp('current_stock_in_cost')
                                                setTimeout(() => {
                                                    setHelp('')
                                                }, 1500)
                                                return
                                            }}
                                            id="current_stock_in_cost"
                                        />
                                        <Tooltip placement="right" isOpen={help === "current_stock_in_cost"} target="current_stock_in_cost">
                                            Este es el valor en dinero de las unidades que tenés en stock, utilizando de base el precio de costo de tu producto.
                                        </Tooltip>
                                        <>
                                            <NumericInput
                                                readOnly = {true}
                                                className={errors.current_stock_in_cost.bol ? "form-control p-form  br-btn-t error-input" : "form-control p-form  br-btn-t"}
                                                value={costStockActual(state).toFixed(2)}
                                                onValueChange={e => {
                                                    setState({
                                                        ...state,
                                                        ['current_stock_in_cost']: e.value
                                                    })
                                                }}
                                            />
                                            <small className="form-text text-muted">Este valor se calcula automáticamente</small>
                                        </>
                                        {/* <Button
                                            type={'number'}
                                            name={'current_stock_in_cost'}
                                            style={errors.current_stock_in_cost.bol ? "form-control p-form  br-btn-t error-input" : "form-control p-form  br-btn-t"}
                                            value={costStockActual(state)}
                                            setValue={setState}
                                            state={state}
                                            read={history.location.pathname.includes('products/view') ? true : undefined}
                                        />
                                        <div className={errors.current_stock_in_cost.bol ? "invalid-input" : "valid-input"}>
                                            {errors.current_stock_in_cost.msg}
                                        </div> */}
                                    </div>
                                </Col>
                                <Col sm="12" md="6" className="mb-1">
                                    <div className="form-group">
                                        <label className="form-label fs-6">Stock a reponer (unidades)</label>
                                        <NumericInput
                                            value={state?.stock_to_be_replenished}
                                            readOnly = {history.location.pathname.includes('products/view') ? true : undefined}
                                            className={errors?.stock_to_be_replenished?.bol ? "form-control p-form  br-btn-t error-input" : "form-control p-form  br-btn-t"}
                                            decimalScale={0}
                                            onValueChange={(e)=>setState({ ...state, ['stock_to_be_replenished']: e.value })}
                                        />
                                        <div className={errors?.stock_to_be_replenished?.bol ? "invalid-input" : "valid-input"}>
                                            {errors.stock_to_be_replenished?.msg}
                                        </div>
                                    </div>
                                </Col>
                                <Col sm="12" md="6" className="mb-1">
                                    <div className="form-group">
                                        <label className="form-label fs-6">Stock actual en $ (venta)</label>
                                        <HelpOutlineIcon
                                            disabled
                                            style={{ marginLeft: "1rem", fontSize: "large" }}
                                            onMouseEnter={() => {
                                                setHelp('sale_actual_stock')
                                            }}
                                            onMouseLeave={() => {
                                                setHelp('')
                                            }}
                                            onClick={()=> {
                                                setHelp('sale_actual_stock')
                                                setTimeout(() => {
                                                    setHelp('')
                                                }, 1500)
                                                return
                                            }}
                                            id="sale_actual_stock"
                                        />
                                        <Tooltip placement="right" isOpen={help === "sale_actual_stock"} target="sale_actual_stock">
                                            Este es el valor en dinero de las unidades que tenés en stock, utilizando de base el precio de venta de tu producto.
                                        </Tooltip>
                                        {
                                            history.location.pathname.includes('products/view') ? (
                                                <input
                                                    type='number'
                                                    className={"form-control p-form  br-btn-t btn-disabled"}
                                                    value={saleStockActual(state)}
                                                    disabled
                                                />
                                            ) : (
                                                <>
                                                    <NumericInput
                                                        value={saleStockActual(state).toFixed(2)}
                                                        readOnly = {true}
                                                        className={errors.sale_actual_stock.bol ? "form-control p-form  br-btn-t error-input" : "form-control p-form  br-btn-t"}
                                                        onValueChange={e => {
                                                            setState({
                                                                ...state,
                                                                ['sale_actual_stock']: e.value
                                                            })
                                                        }}
                                                    />
                                                    <small className="form-text text-muted">Este valor se calcula automáticamente</small>
                                                </>
                                            )
                                        }
                                        <div className={errors.sale_actual_stock.bol ? "invalid-input" : "valid-input"}>
                                            {errors.sale_actual_stock.msg}
                                        </div>
                                    </div>
                                </Col>
                                <Col sm="12" md="6" className="mb-1">
                                    <div className="form-group">
                                        <label className="form-label fs-6">Stock a reponer en $ (costo)</label>
                                        
                                        <>
                                            <input
                                                type='number'
                                                name={'stock_to_be_replenished_in_cost'}
                                                className={errors.stock_to_be_replenished_in_cost?.bol ? "form-control p-form  br-btn-t error-input" : "form-control p-form  br-btn-t"}
                                                value={stockToBeReplenishedCost(state).toFixed(2)}
                                                disabled
                                                onChange={e => {
                                                    setState({
                                                        ...state,
                                                        [e.target.name]: e.target.value
                                                    })
                                                }}
                                            />
                                            <small className="form-text text-muted">Este valor se calcula automáticamente</small>
                                        </>
                                        <div className={errors.stock_to_be_replenished_in_cost?.bol ? "invalid-input" : "valid-input"}>
                                            {errors.stock_to_be_replenished_in_cost?.msg}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            : null}
                        </div>
                    </div>
                </Col>
            </Row>
            
            <div className="card mb-2">
                <div className="card-body">
                    <Row>
                        <div className="form-group mb-1 margin-bottom-0" >
                            <label className="form-label fs-6">Packaging</label>
                        </div>
                        <Col className="mb-1">
                            <Table responsive style={group && group?.length ? { minHeight: "5rem" } : { minHeight: "3rem" }}>
                                <thead>
                                    <tr>
                                        <th className="pl-2 pr-2" style={{ width: "60%" }}>Nombre</th>
                                        <th className="pl-2 pr-2" style={{ width: "10%" }}>Cantidad</th>
                                        <th className="pl-2 pr-2" style={{ width: "20%" }}>Subtotal</th>
                                        <th className="pl-2 pr-2" style={{ width: "10%" }}>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        group?.length ? group.map((el, i = -1) => {
                                            i++
                                            return (
                                                <tr key={i}>
                                                    <td className="pl-2 pr-2">
                                                        <select
                                                            value={el.id}
                                                            onChange={e => {
                                                                const element = packaging.results.filter(el => parseInt(el.id) === parseInt(e.target.value))[0]
                                                                const arr = group.slice()
                                                                arr[i - 1] = {
                                                                    id: element.id,
                                                                    amount: element.cost_unit_real,
                                                                    name: element.name,
                                                                    quantity_group: 1
                                                                }
                                                                setGroup(arr)
                                                                setState({
                                                                    ...state,
                                                                    amount_packaging: totalPackaging(arr),
                                                                    sale_price: state.automatic_price_calculation ? priceSaleProduction(
                                                                        {
                                                                            ...state,
                                                                            amount_packaging: totalPackaging(arr),
                                                                        },
                                                                        suppliesProduct
                                                                    ) : state.sale_price,
                                                                    // cost_price: priceCostProduction(
                                                                    //     {
                                                                    //         ...state,
                                                                    //         amount_packaging: totalPackaging(arr),
                                                                    //     },
                                                                    //     suppliesProduct
                                                                    // )
                                                                })
                                                            }}
                                                            className={errors.group.bol ? "custom-select  error-input" : "custom-select "}
                                                        >
                                                            {
                                                                packaging.results ? packaging.results.map(pack => {
                                                                    return (
                                                                        <option value={pack.id}>{pack.name}</option>
                                                                    )
                                                                }) : null
                                                            }
                                                        </select>
                                                    </td>
                                                    <td className="pl-2 pr-2">
                                                        <ButtonGroup className="btn-group" role="group">
                                                            <button className="btn btn-outline-secondary btn-height br-btn-t" disabled={(el.quantity_group <= 1)?"disabled":""} onClick={(e)=>handleDecrement(e, el)}>-</button>
                                                            <button className="btn btn-outline-secondary btn-height br-btn-t" disabled>{el.quantity_group}</button>
                                                            <button className="btn btn-outline-secondary btn-height br-btn-t" disabled={(el.quantity_group >= 10)?"disabled":""} onClick={(e)=>handleIncrement(e, el)}>+</button>
                                                        </ButtonGroup>
                                                    </td>
                                                    <td className="pl-2 pr-2">
                                                        <NumericInput
                                                            value={el.amount?.toFixed(2)}
                                                            readOnly = {true}
                                                            className="form-control p-form br-btn-t w-100"
                                                        />
                                                    </td>
                                                    <td className="pl-2 pr-2 d-flex flex-direction-column">
                                                        <button
                                                            className="p-2"
                                                            style={{ backgroundColor: "transparent", border: "none" }}
                                                            onClick={e => {
                                                                e.preventDefault()

                                                                if (id) {
                                                                    const arr = state.packaging.filter(x => parseInt(x) !== parseInt(el.id))
                                                                    const arr2 = group.filter(x => parseInt(x.id) !== parseInt(el.id))
                                                                    setState({
                                                                        ...state,
                                                                        packaging: arr,
                                                                        amount_packaging: totalPackaging(arr2)
                                                                    })
                                                                    setGroup(arr2)
                                                                } else {
                                                                    if (group?.length === 1) {
                                                                        setState({
                                                                            ...state,
                                                                            amount_packaging: 0,
                                                                            sale_price: state.automatic_price_calculation ? priceSaleProduction(
                                                                                {
                                                                                    ...state,
                                                                                    amount_packaging: 0,
                                                                                },
                                                                                suppliesProduct
                                                                            ) : state.sale_price,
                                                                            // cost_price: priceCostProduction(
                                                                            //     {
                                                                            //         ...state,
                                                                            //         amount_packaging: 0,
                                                                            //         surcharge_unit: state.surcharge_minorista_wholesale
                                                                            //     },
                                                                            //     suppliesProduct
                                                                            // )
                                                                        })
                                                                        return setGroup([])
                                                                    } else if (group?.length === i) {
                                                                        setState({
                                                                            ...state,
                                                                            amount_packaging: totalPackaging(group.slice(0, i - 1)),
                                                                            sale_price: state.automatic_price_calculation ? priceSaleProduction(
                                                                                {
                                                                                    ...state,
                                                                                    amount_packaging: totalPackaging(group.slice(0, i - 1)),
                                                                                },
                                                                                suppliesProduct
                                                                            ) : state.sale_price,
                                                                            // cost_price: priceCostProduction(
                                                                            //     {
                                                                            //         ...state,
                                                                            //         amount_packaging: totalPackaging(group.slice(0, i - 1)),
                                                                            //         surcharge_unit: state.surcharge_minorista_wholesale
                                                                            //     },
                                                                            //     suppliesProduct
                                                                            // )
                                                                        })
                                                                        return setGroup(group.slice(0, i - 1))
                                                                    } else {
                                                                        const arr1 = group.slice(0, i - 1)
                                                                        const arr2 = group.slice(i, group?.length)
                                                                        setState({
                                                                            ...state,
                                                                            amount_packaging: totalPackaging(arr1.concat(arr2)),
                                                                            sale_price: state.automatic_price_calculation ? priceSaleProduction(
                                                                                {
                                                                                    ...state,
                                                                                    amount_packaging: totalPackaging(arr1.concat(arr2)),
                                                                                },
                                                                                suppliesProduct
                                                                            ) : state.sale_price,
                                                                            // cost_price: priceCostProduction(
                                                                            //     {
                                                                            //         ...state,
                                                                            //         amount_packaging: totalPackaging(arr1.concat(arr2)),
                                                                            //         surcharge_unit: state.surcharge_minorista_wholesale
                                                                            //     },
                                                                            //     suppliesProduct
                                                                            // )
                                                                        })
                                                                        setGroup(arr1.concat(arr2))
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            <DeleteIcon className="color-primary-light" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        }) : null
                                    }
                                </tbody>
                            </Table>
                            <div
                                className={errors.packaging.bol ?
                                    "invalid-input mt-2 mb-1 text-align-center" :
                                    "text-align-center"
                                }
                            >
                                {
                                    errors.packaging.bol ? errors.packaging.msg :
                                        packaging?.length === 0 ? "No se seleccionaron gastos de packaging para este producto" :
                                            null
                                }
                            </div>
                            <Row>
                                <Col md="5"></Col>
                                <Col md="7" sm="12">
                                    {
                                        packaging?.results?.length ? (
                                            <Row className="d-flex">
                                                <Col className="mb-1" style={{flexGrow:1}}>
                                                    <button
                                                        className="linkModal mt-1 color-primary-light background-transparent border-none"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setGroup([
                                                                ...group,
                                                                {
                                                                    id: packaging.results[0].id,
                                                                    amount: packaging.results[0].cost_unit_real,
                                                                    name: packaging.results[0].name,
                                                                    quantity_group: 1
                                                                }
                                                            ])
                                                            setState({
                                                                ...state,
                                                                amount_packaging: totalPackaging([
                                                                    ...group,
                                                                    {
                                                                        id: packaging.results[0].id,
                                                                        name: packaging.results[0].name,
                                                                        amount: packaging.results[0].cost_unit_real
                                                                    }
                                                                ]),
                                                                sale_price: state.automatic_price_calculation ? priceSaleProduction(
                                                                    {
                                                                        ...state,
                                                                        amount_packaging: totalPackaging([
                                                                            ...group,
                                                                            {
                                                                                id: packaging.results[0].id,
                                                                                name: packaging.results[0].name,
                                                                                amount: packaging.results[0].cost_unit_real
                                                                            }
                                                                        ]),
                                                                    },
                                                                    suppliesProduct
                                                                ) : state.sale_price,
                                                                // cost_price: priceCostProduction(
                                                                //     {
                                                                //         ...state,
                                                                //         amount_packaging: totalPackaging([
                                                                //             ...group,
                                                                //             {
                                                                //                 id: packaging.results[0].id,
                                                                //                 name: packaging.results[0].name,
                                                                //                 amount: packaging.results[0].cost_unit_real,
                                                                //                 surcharge_unit: state.surcharge_minorista_wholesale
                                                                //             }
                                                                //         ]),
                                                                //     },
                                                                //     suppliesProduct
                                                                // )
                                                            })
                                                        }}
                                                    >
                                                        + Agregar 
                                                    </button>
                                                </Col>
                                                <Col className="mb-1" style={{flexGrow:1}}>
                                                    <button
                                                        className="linkModal mt-1 color-primary-light background-transparent border-none"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setAddPackaging(true)
                                                        }}
                                                    >
                                                        + Crear 
                                                    </button>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <Row>
                                                <Col sm="6">
                                                    <button
                                                        className="linkModal mt-1 color-primary-light background-transparent border-none"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setAddPackaging(true)
                                                        }}
                                                    >
                                                        + Crear 
                                                    </button>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                {/* <Col></Col> */}
                                <Col className="mb-1" md="6">
                                    <label className="form-label fs-6">Total de packaging</label>
                                    <NumericInput
                                        value={subtotalPackaging.toFixed(2)}
                                        readOnly = {true}
                                        className="form-control p-form  br-btn-t"
                                    />
                                </Col>
                                {/* </Row>
                            <Row> */}
                                {/* <Col></Col> */}
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
            <Row className="mb-1">
                <Col md="12">
                    <div className="card mb-1">
                        <div className="card-body">
                            <div className="form-group mb-1 margin-bottom-0" >
                                <label className="form-label fs-6">Precios</label>
                            </div>
                            <Row>
                                <Col sm="12" md={userIsPayment?.plan?.is_free ? 12 : 12} className="mb-1">
                                    <div className="form-group">
                                        <label className="form-label fs-6">Precio de costo</label>
                                        {
                                            history.location.pathname.includes('products/view') ? (
                                                <input
                                                    type={'number'}
                                                    name={'cost_price'}
                                                    className={"btn-disabled form-control p-form  br-btn-t"}
                                                    value={state.cost_price}
                                                    readOnly
                                                />
                                            ) : (
                                                <>
                                                    <NumericInput
                                                        name={'cost_price'}
                                                        className={errors.cost_price.bol ? "form-control p-form  br-btn-t error-input" : "form-control p-form  br-btn-t"}
                                                        value={state.cost_price}
                                                        onValueChange={e => {
                                                            setState({
                                                                ...state,
                                                                cost_price: parseFloat(e.value),
                                                            })
                                                        }}
                                                        readOnly = {history.location.pathname.includes('products/view') ? true : undefined}
                                                    />
                                                    <div className={errors.cost_price.bol ? "invalid-input" : "valid-input"}>
                                                        {errors.cost_price.msg}
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                </Col>
                                {/* {!userIsPayment?.plan?.is_free ?
                                    <Col sm="12" md="6" className="mb-1">
                                        <div className="form-group">
                                            <label className="form-label fs-6">Costo de packaging</label>
                                            {
                                                history.location.pathname.includes('products/view') ? (
                                                    <input
                                                        type={'number'}
                                                        name={'packaging_cost'}
                                                        className={"btn-disabled form-control p-form  br-btn-t"}
                                                        value={state.packaging_cost}
                                                        readOnly
                                                    />
                                                ) : (
                                                    <>
                                                        <input
                                                            type={'number'}
                                                            name={'packaging_cost'}
                                                            className={errors.packaging_cost.bol ? "form-control p-form  br-btn-t error-input" : "form-control p-form  br-btn-t"}
                                                            value={state.packaging_cost}
                                                            onChange={e => {
                                                                setState({
                                                                    ...state,
                                                                    packaging_cost: e.target.value
                                                                })
                                                            }}
                                                            read={history.location.pathname.includes('products/view') ? true : undefined}
                                                        />
                                                        <div className={errors.packaging_cost.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.packaging_cost.msg}
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </Col>
                                : null} */}
                            </Row>
                            <Row>
                                <Col sm="12" md="6" className="mb-1">
                                    <div className="form-group">
                                        <label className="form-label fs-6">Precio de Venta</label>
                                        <HelpOutlineIcon
                                            disabled
                                            style={{ marginLeft: "1rem", fontSize: "large" }}
                                            onMouseEnter={() => {
                                                setHelp('sale_price')
                                            }}
                                            onMouseLeave={() => {
                                                setTimeout(() => {
                                                    setHelp('')
                                                }, 1500)
                                            }}
                                            id="sale_price"
                                            onClick={()=> {
                                                setHelp('sale_price')
                                                setTimeout(() => {
                                                    setHelp('')
                                                }, 1500)
                                                return
                                            }}
                                        />
                                        <Tooltip placement="right" isOpen={help === "sale_price"} target="sale_price">
                                            Este valor se calcula automáticamente teniendo en cuenta los costos y el márgen de recargo que hayas definido.
                                        </Tooltip>
                                        {history.location.pathname.includes('products/view') ? (
                                            <NumericInput
                                                className={"btn-disabled form-control p-form  br-btn-t"}
                                                value={subtotalSellPrice}
                                                readOnly={true}
                                            />
                                        ) : (
                                            <>
                                                <NumericInput
                                                    ref={inputSalePrice}
                                                    name={'sale_price'}
                                                    className={errors.sale_price.bol ? "form-control p-form  br-btn-t error-input" : "form-control p-form  br-btn-t"}
                                                    value={state?.automatic_price_calculation ? subtotalSellPrice.toFixed(2) : state?.sale_price}
                                                    onValueChange={e => {
                                                        setState({
                                                            ...state,
                                                            sale_price: e.value/* ,
                                                            contribution_margin: marginContribution({
                                                                ...state,
                                                                sale_price: e.target.value,
                                                                sale_actual_stock: saleStockActual({
                                                                    ...state,
                                                                    sale_price: e.target.value,
                                                                    margin_surcharge: state.surcharge_minorista_wholesale
                                                                })
                                                            }), */

                                                        })
                                                    }}
                                                    readOnly = {state?.automatic_price_calculation}
                                                />
                                                <small className="form-text text-muted">Este valor se calcula automáticamente</small>
                                                <div className={errors.sale_price.bol ? "invalid-input" : "valid-input"}>
                                                    {errors.sale_price.msg}
                                                </div>
                                                

                                                <small className="form-text text-muted d-flex align-items-center">
                                                    <label 
                                                        class="custom-checkbox" 
                                                        onChange={e => {
                                                            setState({
                                                                ...state,
                                                                automatic_price_calculation: !state?.automatic_price_calculation,
                                                            })
                                                        }}
                                                    >
                                                        <input type="checkbox" checked={!state?.automatic_price_calculation} />
                                                        <span class="custom-checkbox-checkmark"></span>
                                                        Cambiar el precio manualmente
                                                    </label>
                                                </small>
                                            </>
                                        )
                                        }
                                    </div>
                                </Col>
                                <Col sm="12" md="6">
                                    <div className="form-group">
                                        <label className="form-label fs-6">Márgen de contribución</label>
                                        <HelpOutlineIcon
                                            disabled
                                            style={{ marginLeft: "1rem", fontSize: "large" }}
                                            onMouseEnter={() => {
                                                setHelp('contribution_margin')
                                            }}
                                            onMouseLeave={() => {
                                                setHelp('')
                                            }}
                                            onClick={()=> {
                                                setHelp('contribution_margin')
                                                setTimeout(() => {
                                                    setHelp('')
                                                }, 1500)
                                                return
                                            }}
                                            id="contribution_margin"
                                        />
                                        <Tooltip placement="right" isOpen={help === "contribution_margin"} target="contribution_margin">
                                            Este valor será el que aporta cada producto vendido al pago de tu suedo, gastos de tu emprendimiento y ganancia final.
                                        </Tooltip>
                                        <NumericInput
                                            name={'contribution_margin'}
                                            className={
                                                history.location.pathname.includes('products/view') ?
                                                    "form-control p-form  br-btn-t btn-disabled" :
                                                    "form-control p-form  br-btn-t"
                                            }
                                            value={state.contribution_margin.toFixed(2)}
                                            readOnly
                                        />
                                        <small className="form-text text-muted">Este valor se calcula automáticamente</small>
                                        <div className={errors.contribution_margin.bol ? "invalid-input" : "valid-input"}>
                                            {errors.contribution_margin.msg}
                                        </div>
                                    </div>
                                </Col>

                                
                                {!state.automatic_price_calculation && parseFloat(state.sale_price) <= parseFloat(state.cost_price) && !state.use_manual_pricing ?
                                <Col>
                                    <Alert variant="danger">
                                        No es recomendable vender este producto a este valor. Ya que generaría pérdidas
                                        <div className='mt-1'>
                                            <button type="button" className="btn btn-light btn-sm" onClick={() => setState({...state, use_manual_pricing: true})}>Mantener valor</button>
                                            <button type="button" className="btn btn-light btn-sm ms-2" onClick={() => inputSalePrice.current.focus()}>Corregir</button>
                                        </div>
                                    </Alert>
                                </Col>
                                : null}
                            </Row>
                        </div>
                    </div>
                    <div className='card mb-1'>
                        <div className='card-body'>
                            <div className="form-group margin-bottom-0">
                                <label className="form-label fs-6">Detalles</label>
                                <textarea
                                    name={'details'}
                                    className={"form-control p-form btn-height-6 br-btn-t resize-none"}
                                    value={state.details}
                                    onChange={e =>
                                        setState({
                                            ...state,
                                            details: e.target.value
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}
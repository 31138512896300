import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import CreateClient from './createClient';
import FormIncomes from '../component/FormIncomes';
import ModalComponent from '../component/Modal';
import Loading from '../component/Loading';

export default function EditIncomes() {
    const history = useHistory();
    const [disabled, setDisabled] = React.useState(false);
    const { id } = useParams();
    const [clients, setClients] = React.useState([]);
    const [dataAdding, setDataAdding] = React.useState();
    const [addClient, setAddClient] = React.useState();
    const [modal, setModal] = React.useState(false);
    const [idClient, setClient] = React.useState(undefined);
    const [state, setState] = useState(null);
    const [orderChange,setOrderChange] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [errors, setErrors] = useState({
        date: {
            bol: false,
            msg: ''
        },
        concept: {
            bol: false,
            msg: ''
        },
        amount: {
            bol: false,
            msg: ''
        },
        method_payment: {
            bol: false,
            msg: ''
        },
        status_payment: {
            bol: false,
            msg: ''
        },
        client: {
            bol: false,
            msg: ''
        },
        status_order: {
            bol: false,
            msg: ''
        },
        details: {
            bol: false,
            msg: ''
        }
    })

    React.useEffect(() => {
        setState({
            ...state,
            client: idClient
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idClient])

    React.useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        setLoading(true)

        fetch(`${process.env.REACT_APP_URL_API}/api/orders/${id}`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setState({
                    ...json,
                    order_date: json?.order_date?.substring(0,10),
                    client: json?.client?.id.toString()
                })
                setOrderChange(!orderChange)
                setLoading(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    React.useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/clients/`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setClients(json)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addClient])

    React.useEffect(()=>{
        if(state?.items?.length >= 1) {
            const arr = []
            state?.items?.map(el => {
                return arr.push(el)
            })
            setDataAdding(arr)
        }
    }, [orderChange])
    
    const onSubmit = (e) => {
        e.preventDefault();

        const err = {}
        const clientsChange = clients?.results?.find((e)=> e?.id === parseInt(state?.client));
        Object.keys(state).filter(x => x !== "details").map(x => {
            if (
                x === "date" ||
                x === "amount" ||
                x === "status_order"
            ) {
                if (!state[x]) {
                    return err[x] = {
                        bol: true,
                        msg: 'Este campo no puede estar vacío'
                    };
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    };
                }
            } else {
                return err[x] = {
                    bol: false,
                    msg: ''
                };
            }
        })

        setErrors(err);

        if (Object.values(err).filter(x => x.bol === true).length === 0) {
            setDisabled(true)
            setTimeout(() => {
                setDisabled(false);
            }, 1500)

            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
            myHeaders.append("Content-Type", 'application/json')

            const dataChanged = {
                ...state,
                order_date: `${state.order_date} 00:00:00`,
                client: clientsChange
            }

            fetch(`${process.env.REACT_APP_URL_API}/api/orders/${id}/`, {
                'method': "PATCH",
                'body': JSON.stringify(dataChanged),
                'headers': myHeaders
            })
                .then(resp => resp.json())
                .then(json => {
                    if (json.id) {
                        toast.success('Ingreso modificado exitosamente', {
                            className: "Toastify__toast--success"
                        })
                        setTimeout(()=>{
                            history.push(`/incomes`)
                        }, 800)
                    }else {
                        toast.error('Hubo un error al intentar modificar la venta. Intente nuevamente', {
                            className: "Toastify__toast--error"
                        })
                    }
                })
        }
    }

    const handleDelete = (e) => {
        e.preventDefault();

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/orders/${id}/`, {
            'method': "DELETE",
            'headers': myHeaders
        })
            .then(() => {
                toast.success('Ingreso eliminado exitosamente', {
                    className: "Toastify__toast--success"
                })
                history.push('/incomes')
            })
    }

    return (
        <>
            {
                <ModalComponent
                    open={modal}
                    title={"Eliminar ingreso"}
                    body={"¿Esta segura de eliminar este ingreso?"}
                    setModal={setModal}
                    onDelete={handleDelete}
                />
            }
            {
                <Modal show={addClient} className="modal-create">
                    <Modal.Header>
                        <h5 className="modal-title color-primary">{'Agregar cliente'}</h5>
                        <button type="button" className="close color-primary" onClick={() => setAddClient(false)}>
                            <span>×</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <CreateClient setAddClient={setAddClient} addClient={addClient} setId={setClient} />
                    </Modal.Body>
                </Modal>
            }

            {loading ? <Loading /> : null}

            {
                !state ? (
                    null
                ) : (
                    <FormIncomes
                        title={'Editar ingreso'}
                        state={state}
                        setState={setState}
                        back={'/incomes'}
                        errors={errors}
                        clients={clients}
                        idClient={idClient}
                        setAddClient={setAddClient}
                        onSubmit={onSubmit}
                        setDisabled={setDisabled}
                        disabled={disabled}
                        setModal={setModal}
                        dataAdding={dataAdding}
                        setDataAdding={setDataAdding}
                    />
                )
            }
        </>
    )
}
import {NumericFormat} from 'react-number-format';

export const NumericInput = ({value, name, decimalScale, onValueChange, onChange, className, readOnly, disabled, ref}) =>{
    return(
        <NumericFormat
            value={value}
            name={name}
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            decimalScale={decimalScale}
            displayType="input"
            onValueChange={onValueChange}
            onChange={onChange}
            className={className}
            readOnly={readOnly}
            disabled={disabled}
            ref={ref}
        />
    )
}
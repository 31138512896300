import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import ModalComponent from '../component/Modal';
import Loading from '../component/Loading';
import CreateVendor from './createVendor';
import Modal from 'react-bootstrap/Modal';
import FormSupplies from '../component/FormSupplies';
import { FormSuppliesWorkforce } from '../component/FormSuppliesWorkforce';

export default function EditSupplies () {
    const {id} = useParams();
    const [state, setState] = useState();
    const [modal, setModal] = useState(false);
    const history = useHistory();
    const [idVendor, setId] = useState(null)
    const [addVendors, setAddVendors] = useState(false)
    const [vendors, setVendors] = useState([]);
    const [disabled, setDisabled] = React.useState(false);
    
    const [errors, setErrors] = useState({
        name: {
            bol: false,
            msg: ''
        },
        unit_measurement: {
            bol: false,
            msg: ''
        },
        element_height: {
            bol: false,
            msg: ''
        },
        element_width: {
            bol: false,
            msg: ''
        },
        square_meter: {
            bol: false,
            msg: ''
        },
        cost_unit: {
            bol: false,
            msg: ''
        },
        cost_per_unit_of_use: {
            bol: false,
            msg: ''
        },
        provider: {
            bol: false,
            msg: ''
        },
        current_stock_in_units: {
            bol: false,
            msg: ''
        },
        current_stock_in_cost: {
            bol: false,
            msg: ''
        },
        ideal_stock_in_units: {
            bol: false,
            msg: ''
        },
        stock_replenish: {
            bol: false,
            msg: ''
        },
        stock_replenish_in_cost: {
            bol: false,
            msg: ''
        },
        work_unit: {
            bol: false,
            msg: ''
        },
    })

    const onSubmit = (e) => {
        e.preventDefault();

        const err = {
            margin_surcharge: {
                bol: false,
                msg: ''
            },
            sale_actual_stock: {
                bol: false,
                msg: ''
            }
        }

        Object.keys(state).map(x => {
            if (x === "name") {
                if (!state[x]) {
                    return err[x] = {
                        bol: true,
                        msg: 'Este campo no puede estar vacío'
                    };
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    };
                }
            } else {
                return err[x] = {
                    bol: false,
                    msg: ''
                };
            }
        })

        setErrors(err);

        if (Object.values(err).filter(x => x.bol === true).length === 0) {
            if(state.unit_measurement === "metros_cuadrados") {
                state.unit_measurement = "m"
            }
            setDisabled(true)
            setTimeout(() => {
                setDisabled(false);
            }, 1500)
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
            myHeaders.append("Content-Type", 'application/json')

            fetch(`${process.env.REACT_APP_URL_API}/api/supplies/${id}/`, {
                'method': "PUT",
                'body': JSON.stringify(state),
                'headers': myHeaders
            })
                .then(resp => resp.json())
                .then(json => {
                    if(json.id) {
                        setState(json)
                        toast.success('Insumo modificado exitosamente', {
                            className: "Toastify__toast--success"
                        })

                        history.push('/supplies');
                    } else{
                        toast.success(json.name, {
                            className: "Toastify__toast--error"
                        })
                    }
                })
        }
    }

    useEffect(() => {
        const myHeaders = new Headers();
            myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
            myHeaders.append("Content-Type", 'application/json')

            fetch(`${process.env.REACT_APP_URL_API}/api/supplies/${id}/`, {
                'method': "GET",
                'headers': myHeaders
            })
                .then(resp => resp.json())
                .then(json => {
                    setState({
                        ...json,
                        provider: json.provider?.id?.toString()
                    })
                })
    }, [id])

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/providers/`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setVendors(json)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addVendors])

    const handleDelete = (e) => {
        e.preventDefault();
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')
        setDisabled(true)
        fetch(`${process.env.REACT_APP_URL_API}/api/supplies/${id}/`, {
            'method': "DELETE",
            'headers': myHeaders
        })
            .then((res) => {
                setDisabled(false);
                if(res.status === 204) {
                    toast.success('Item eliminado exitosamente', {
                        className: "Toastify__toast--success"
                    })
                    history.push('/supplies')
                } else {
                    toast.success('No se puede eliminar este item', {
                        className: "Toastify__toast--error"
                    })
                }
            })
    }
    
    return (
        !state ? <Loading /> : (
            <>
                {
                    <Modal show={addVendors} className="modal-create">
                        <Modal.Header>
                                <h5 className="modal-title color-primary">{'Agregar proveedor'}</h5>
                                <button type="button" className="close color-primary" onClick={() => setAddVendors(false)}>
                                    <span>×</span>
                                </button>
                        </Modal.Header>
                        <Modal.Body>
                            <CreateVendor setAddVendors={setAddVendors} addVendors={addVendors} setId={setId} />
                        </Modal.Body>
                    </Modal>
                }
                <ModalComponent
                    open={modal} 
                    title={"Eliminar item"} 
                    body={"¿Esta segura de eliminar este item?"} 
                    setModal={setModal} 
                    onDelete={handleDelete}
                />
                {
                    (state.supply_type === 'workforce')
                        ?(
                            <FormSuppliesWorkforce
                                id={id}
                                title={"Editar mano de obra"}
                                state={state}
                                setState={setState}
                                errors={errors}
                                back={'/supplies'}
                                onSubmit={onSubmit}
                                vendors={vendors}
                                setAddVendors={setAddVendors}
                                addVendors={addVendors}
                                idVendor={idVendor}
                                setDisabled={setDisabled}
                                disabled={disabled}
                                setModal={setModal}
                            />
                        ): (
                            <FormSupplies
                                title={'Editar insumo'}
                                state={state} 
                                setState={setState}
                                errors={errors} 
                                back={'/supplies'} 
                                onSubmit={onSubmit}
                                id={id}
                                vendors={vendors}
                                setAddVendors={setAddVendors}
                                addVendors={addVendors}
                                setModal={setModal}
                                idVendor={idVendor}
                                setDisabled={setDisabled}
                                disabled={disabled}
                            />
                        )
                }
                
            </>
        )
    )
}
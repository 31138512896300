import React, { useEffect, useState } from 'react'
import ModalComponent from '../component/Modal';
import TableResponsive from '../component/Table';
import { toast } from 'react-toastify';
import { deleteAllPackaging, getPackaging, getPayUser } from '../redux/action';
import { useDispatch } from 'react-redux';

export default function Packaging() {
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [id, setId] = useState();
    const [page, setPage] = useState(1);
    const [data, setData] = useState()
    const [reload, setReload] = useState(false);
    const [modal, setModal] = useState(false);
    const [deleteAll, setDeleteAll] = useState();
    const [modalDelete, setModalDelete] = useState(false)
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        dispatch(getPackaging())
        dispatch(getPayUser())
    }, [dispatch])

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        if ( search.length >= 1 ){
            return
        } else {
            fetch(`${process.env.REACT_APP_URL_API}/api/packaging/?page=${page}`, {
                'method': "GET",
                'headers': myHeaders
            })
                .then(resp => resp?.json())
                .then(json => {
                    if(json.detail) {
                        setPage(page-1)
                    } else {
                        setData(json)
                    }
                })
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload, disabled, page, search])

    const handleSubmit = (e) => {
        e.preventDefault();
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')
        fetch(`${process.env.REACT_APP_URL_API}/api/packaging/?search=${e.target.value}`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setData(json)
            })
    }

    const onDelete = (e) => {
        // e.preventDefault();
        // setDisabled(true);
        // setTimeout(() => {
        //     setDisabled(false);
        // }, 1500)
        // const myHeaders = new Headers();
        // myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        // myHeaders.append("Content-Type", 'application/json')

        // fetch(`${process.env.REACT_APP_URL_API}/api/packaging/${id}/`, {
        //     'method': "DELETE",
        //     'headers': myHeaders
        // })
        //     .then(resp => resp.json())
        //     .then(json => {
        //         if(json.length <= 0) {
        //             toast.success('Cliente eliminado exitosamente', {
        //                 className: "Toastify__toast--success"
        //             })
        //         } else {
        //             toast.error('Cliente no se puede eliminar ya que tiene una venta activa', {
        //                 className: "Toastify__toast--error"
        //             })
        //         }
        //     })

        onDeleteAll(e)
    }

    const onDeleteAll = (e) => {
        e.preventDefault();
        setDisabled(true);
        setTimeout(() => {
            setDisabled(false);
        }, 1500)
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')
        dispatch(deleteAllPackaging(deleteAll))
        setDeleteAll([])
    }

    const pageData = (e, url) => {
        e.preventDefault();
        setDisabled(true);
        setTimeout(() => {
            setDisabled(false);
        }, 1500)
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${url}`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setData(json)
            })
    }

    return (
        <>
            {
                modalDelete ? (
                    <ModalComponent
                        open={modalDelete}
                        title={deleteAll && deleteAll.length === 1 ? "Eliminar gasto de packaging" : "Eliminar gastos de packaging"}
                        body={deleteAll && deleteAll.length === 1 ? "¿Esta segura de eliminar este packaging?" : `¿Esta segura de eliminar ${deleteAll.length} packaging?`}
                        setModal={setModalDelete}
                        onDelete={onDeleteAll}
                    />
                ) : null
            }
            {
                modal ? (
                    <ModalComponent
                        open={modal}
                        title={"Eliminar gasto"}
                        body={"¿Esta segura de eliminar este gasto?"}
                        setModal={setModal}
                        onDelete={onDelete}
                    />
                ) : null
            }
            <TableResponsive
                data={data}
                search={search}
                setSearch={setSearch}
                handleSubmit={handleSubmit}
                toCreate={"/packaging/create"}
                toEdit={'/packaging/edit'}
                modal={modal}
                setModal={setModal}
                setId={(id) => setDeleteAll([String(id)])}
                page={page}
                setPage={setPage}
                next={data?.next}
                previous={data?.previous}
                pageData={pageData}
                reload={reload}
                setReload={setReload}
                id={id}
                setDeleteAll={setDeleteAll}
                setModalDelete={setModalDelete}
                disabled={disabled}
            />
        </>
    )
}
import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setNewPassword } from '../redux/action';
import Logo from '../assets/img/logo_lg.png'
import Button from '../component/Button';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import LogoTXT from '../component/LogoTXT';

export const NewPassword = (props) => {

    const { token } = props.match.params;
    const history = useHistory();

    const dispatch = useDispatch();
    const [state, setState] = useState({
        password: '',
        password2: '',
        token
    });

    const [errors, setError] = useState({
        password: {
            bol: false,
            msg: ''
        },
        password2: {
            bol: false,
            msg: ''
        }
    });

    const onSubmit = (e) => {
        e.preventDefault();

        const err = {};

        Object.keys(state).map(x => {
            if (!state[x]) {
                return err[x] = {
                    bol: true,
                    msg: 'Este campo no puede estar vacío'
                }
            } if(x === "password" && state[x]){
                // if (/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/i.test(state[x])) {
                if (state[x].length >= 6) {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    }
                } else {
                    return err[x] = {
                        bol: true,
                        msg: 'Las contraseñas deben contener al menos 6 caracteres.'
                    }
                }
            } if (x === "password2" && state[x]) {
                if (state[x] === state['password']) {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    }
                } else {
                    return err[x] = {
                        bol: true,
                        msg: 'Las contraseña no coinciden. Debes repetir la contraseña anterior.'
                    }
                }
            }

        });

        setError(err);

        if (Object.values(err).filter(x => x.bol === true).length === 0) {
            dispatch(setNewPassword(state, history))
        }
    }

    return (
        <>
            <div className="container brand-container">
                <Row>
                    <Col sm="12">
                        <div className="card" style={{ margin: "auto", borderRadius: ".5rem" }}>
                            <div className="card-body flex-space-around" style={{ minHeight: "400px" }}>
                                <div className="d-flex justify-content-evenly mb-1 align-items-center">
                                    <LogoTXT />
                                    {/* <img src={Logo} alt="logo" className="login-logo" /> */}
                                    {/* <h2 className="brand-text text-sm-start ta-center fs-1 color-primary font-primary" style={{ height: "10%" }}>Flor de Emprendedora</h2> */}
                                </div>
                                <div>
                                    <h4 className="card-title mb-1 text-sm-start fs-4">Restablecer contraseña</h4>
                                </div>
                                <form noValidate onSubmit={e => onSubmit(e)} style={{ height: "70%", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                                    <div className="form-group">
                                        <label htmlFor="reset-password-email" className="form-label fs-6">Ingrese su nueva contraseña</label>
                                        <Button
                                            type={'password'}
                                            name={'password'}
                                            style={errors.password.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                            value={state.password}
                                            setValue={setState}
                                            state={state}
                                        />
                                        <div className={errors.password.bol ? "invalid-input" : "valid-input"}>
                                            {errors.password.msg}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="reset-password-email" className="form-label fs-6">Repetir contraseña</label>
                                        <Button
                                            type={'password'}
                                            name={'password2'}
                                            style={errors.password2.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                            value={state.password2}
                                            setValue={setState}
                                            state={state}
                                        />
                                        <div className={errors.password2.bol ? "invalid-input" : "valid-input"}>
                                            {errors.password2.msg}
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-block btn-height-3 br-btn-t">Restablecer</button>
                                    <hr />
                                    <p className="ta-center mt-2">
                                        <span className="mr-25 fs-6">Volver a</span>
                                        <Link to="/login">
                                            <span className="color-primary fs-6">Loguearte</span>
                                        </Link>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

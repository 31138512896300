import React from 'react';

export default function Button({ type, style, value, setValue, name, state, read, importantStyle, defaultValue}) {
    return (
        read ? (
            <input
                style={importantStyle}
                type={type}
                className={`${style} btn-disabled`}
                value={value}
                onChange={e => setValue({ ...state, [name]: e.target.value })}
                readOnly
            />
        ) : (
            <input
                style={importantStyle}
                type={type}
                className={style}
                value={value}
                onChange={e => setValue({ ...state, [name]: e.target.value })}
                defaultValue={defaultValue}
            />
        )
    )
}
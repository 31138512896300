import React, { useEffect, useState } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import ErrorIcon from '@material-ui/icons/Error';
import { TableIncomesSell } from './TableIncomesSell';
import { Modal } from 'react-bootstrap';
import { getPayUser } from '../redux/action';
import { Tooltip } from 'reactstrap';

export default function FormIncomes({ dataAdding, setDataAdding, title, state, setModal, errors, setState, back, onSubmit, disabled, clients, idClient, setAddClient, setDisabled, modal }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [ search, setSearch ] = useState('');
    const [ help, setHelp] = useState('')
    const [ reload, setReload ] = useState(false);
    const [ originalSubtotalIsBetter, setOriginalSubtotalIsBetter] = useState(false)
    const [ editableSubtotal, setEditableSubtotal] = useState(0)
    const [modalNotEnoughStock, setModalNotEnoughStock] = useState(false)
    const [modalProductionBlocking, setModalProductionBlocking] = useState(false)
    const [modalProduce, setModalProduce] = React.useState(null)
    const [modalProduceTitle, setModalProduceTitle] = React.useState('Producir')
    const [quantityToProduce, setQuantityToProduce] = React.useState(1)
    const [producing, setProducing] = React.useState(null)
    const [producingError, setProducingError] = React.useState(null)
    const [handleIncrementParams, setHandleIncrementParams] = React.useState(null)
    const [msgStockToProduction, setMsgStockToProduction] = useState({
        packaging: {},
        supplies: {}
    });
    
    const [ dataSearch, setDataSearch ] = useState();

    useEffect(() => {
        dispatch(getPayUser())
    }, [dispatch])

    const handleSearchChange = (e) => {
        setSearch(() => e.target.value)
        setReload(!reload)
        return handleSubmit(e)
        /* 
        if (e.target.value.length > 0) {
            return handleSubmit(e)
        } else {
            setDataSearch()
            return setReload(!reload)
        }
        */
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const myHeaders = new Headers();

        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        if (e.target.value.length > 0){
            return (
                fetch(`${process.env.REACT_APP_URL_API}/api/products/?search=${e.target.value}&is_active=true`, {
                    method: 'GET',
                    headers: myHeaders
                })
                    .then(resp => resp.json())
                    .then(json => {
                        setDataSearch(json)
                    })
            )
        }
        else {
            return (
                fetch(`${process.env.REACT_APP_URL_API}/api/products/?is_active=true`, {
                    method: 'GET',
                    headers: myHeaders
                })
                    .then(resp => resp.json())
                    .then(json => {
                        setDataSearch(json)
                    })
            )
        }
    }

    const handleAddProduct = (e, data) => {
        e.preventDefault();
        setDisabled(false)
        setDataSearch('')
        setSearch('')

        console.log("data", data)

        if(dataAdding?.filter(d => d.id === data.id).length >= 1) {
            return 
        }

        let obj = {
            ...data,
            product: data.id,
            quantity: 0,
            available_quantity: data.current_stock_in_units,
            value_discount: 0,
            type_discount: '$',
            sub_total: data.sale_price,
            use_stock: true,
        }

        if (!(dataAdding?.length >= 1)) {
            setDataAdding([obj])
        } else {
            setDataAdding([...dataAdding, obj])
        }

        
    }
    useEffect(()=> {
        const newState = structuredClone(state)
        newState.items = structuredClone(dataAdding)
        setState(newState)
    }, [dataAdding])

    let subTotal = 0;
    dataAdding?.forEach(element => {
        const sellPrice = element.sale_price;
        const quantity = element.quantity;
        const discountType = element.type_discount;
        const discountValue = (discountType === '$') ? element.value_discount : (element.value_discount/100)*(sellPrice*quantity);

        subTotal += (sellPrice*quantity) - discountValue
    });

    useEffect(()=>{
        setEditableSubtotal(state.subtotal)
    }, [state])

    useEffect(()=>{
        setEditableSubtotal(subTotal)
        setState({
            ...state,
            subtotal: subTotal
        })
        setOriginalSubtotalIsBetter(false)
    }, [subTotal])

    const handleDeleteProduct = (e, id) => {
        e.preventDefault();
        
        const filterDataAdding = dataAdding.filter(e => e.id !== id)

        setDataAdding(filterDataAdding)
    }

    const userIsPayment = useSelector(stee => stee?.userPremiumPay)
    
    const setProduce = (quantity, product) => {
        if(quantity < 1 || quantity == '' || quantity == null || isNaN(quantity)) {
            setProducingError({message: 'La cantidad no puede ser inferior a 1'});
            return;
        }
        
        setProducing(true);
        const myHeaders = new Headers();

        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/products/${product.id}/produce/`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({quantity_to_produce: quantity}),
        })
            .then(resp => resp.json())
            .then(data => {
                setProducing(false)
                if(data.type === 'without_stock') {
                    setProducingError(data)
                }
                else {
                    console.log("INCREMENT REF", handleIncrementParams)
                    handleIncrement(handleIncrementParams.e, handleIncrementParams.dataAdding, handleIncrementParams.data)
                    setModalProduce(null)
                    setProducingError(null)
                }
            })
    }

    const setDiscard = (quantity, product) => {
        if(quantity < 1 || quantity == '' || quantity == null || isNaN(quantity)) {
            setProducingError({message: 'La cantidad no puede ser inferior a 1'});
            return;
        }
        
        setProducing(true);
        const myHeaders = new Headers();

        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/products/${product.id}/discard/`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({quantity_to_discard: quantity}),
        })
            .then(resp => resp.json())
            .then(data => {
                setProducing(false)
                if(data !== '') {
                    setProducingError(data)
                }
                else {
                    setModalProduce(null)
                    setProducingError(null)
                }
            })
    }

    const handleIncrement = (e, dataAdding, data) => {
        if (!e || !dataAdding || !data) return 
        
        e.preventDefault();

        const dataChange = dataAdding.filter(e => e.id === data.id)
        const dataFiltred = dataAdding.filter(e => e.id !== data.id)

        let haveStock = checksIfThereIsStockAvailableToProduce(data.quantity+1, data)

        if(data.product_type === 'production' && !data.use_stock && haveStock[data.id]) {
            return;
        }

        let newData;
        if (dataChange) {
            newData = {
                ...data,
                quantity: data.quantity+1,
                sub_total: (data.quantity*data.cost_price)+data.cost_price
            }
        }
        
        setDataAdding([
            ...dataFiltred,
            newData,
        ])
    }

    const checksIfThereIsStockAvailableToProduce = (quantityToProduce, data) => {
        let msg = {...msgStockToProduction}

        if(data.product_type === 'production' && !data.use_stock) {
            data.supplies.forEach((supply) => {
                delete msg.supplies[supply.product]
                let stockSupply = supply.supply_id.current_stock_in_units
                let amountToReduce = quantityToProduce / data.quantity_per_recipe * supply.quantity
                if(amountToReduce > stockSupply) {
                    console.log('supply.id', supply.id)
                    msg.supplies[supply.product] = {
                        name: supply.supply_id.name,
                        msg: 'No hay suficiente stock del insumos',
                        url: `/supplies/edit/${supply.supply_id.id}`
                    }
                }
            })
    
            data.packaging.forEach((pack) => {
                delete msg.packaging[pack.product]
                let stockPackaging = pack.packaging_id.current_stock_in_units
                if(quantityToProduce > stockPackaging) {
                    console.log('pack.id', pack)
                    msg.packaging[pack.product] = {
                        name: pack.packaging_id.name,
                        msg: 'No hay suficiente stock del packaging',
                        url: `/packaging/edit/${pack.packaging_id.id}`
                    }
                }
            })
        }

        setMsgStockToProduction(msg)

        return msg;
    }

    return (
        <>
            <form>
                <Row className="mb-1">
                    <Col md="8">
                        <div className="card mb-1">
                            <div className="card-header mt-1">
                                <div className="card-title">
                                    <h2>
                                        {title}
                                    </h2>
                                </div>
                            </div>
                            <div className="card-body">
                                {
                                    <>
                                    <input
                                        id="filterInput"
                                        autoComplete='off'
                                        style={(dataSearch) ? {borderBottomLeftRadius: 0, borderBottomRightRadius: 0} : null}
                                        className={
                                            errors?.items?.bol ?
                                                "form-control error-input" :
                                                (
                                                    (!dataSearch) ? "form-control" : (
                                                        "form-control border-bottom-0 border-dark"
                                                    )
                                                )
                                        }
                                        value={search}
                                        onChange={(e) => handleSearchChange(e)}
                                        onFocus={(e) => handleSearchChange(e)}
                                        type="text"
                                        placeholder={'Buscar Producto'}
                                    />
                                    {
                                        (!dataSearch) ? null : (
                                            <>
                                                <select
                                                    size={5}
                                                    id=''
                                                    style={(dataSearch) ? {borderTopLeftRadius: 0, borderTopgitRightRadius: 0} : null}
                                                    className={
                                                        errors.status_payment.bol ?
                                                        "custom-select  br-btn-t error-input" :
                                                        "custom-select  br-btn-t border-dark"
                                                    }
                                                    onChange={(e)=>{
                                                        handleAddProduct(e, dataSearch.results[e.target.value])
                                                        setModalNotEnoughStock((dataSearch.results[e.target.value].current_stock_in_units) <= 0 ? true : false)
                                                    }}
                                                >
                                                    {
                                                        dataSearch.results.map((data, key) =>
                                                            <option 
                                                                key={key}
                                                                value={key}
                                                            >
                                                                {data.name}
                                                            </option>
                                                        )
                                                    }
                                                </select>
                                            </>
                                        )
                                    }
                                        <div>
                                            <small className="form-text text-muted">Para elegir un producto debe estar cargado en la parte de productos.</small>
                                        </div>
                                    </>
                                }

                            {
                                (dataAdding)?null:(
                                    <div
                                        className={errors?.items?.bol ?
                                            " invalid-input" :
                                            " valid-input"
                                        }
                                    >
                                        {errors?.items?.msg}
                                    </div>
                                )
                            }
                                {
                                    (!dataAdding) ? null : (
                                        <div 
                                            className={"mt-1"}
                                        >
                                            <TableIncomesSell msgStockToProduction={msgStockToProduction} checksIfThereIsStockAvailableToProduce={checksIfThereIsStockAvailableToProduce} setHandleIncrementParams={setHandleIncrementParams} handleIncrement={handleIncrement} setModalProduce={setModalProduce} setModalProductionBlocking={setModalProductionBlocking} setModalNotEnoughStock={setModalNotEnoughStock} handleDeleteProduct={handleDeleteProduct} dataAdding={dataAdding} setDataAdding={setDataAdding}/>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className='card mb-1'>
                            <div className="card-body">
                                <div className="form-group">
                                    <div style={{display: "flex", gap: "6px"}}>
                                        <label className="form-label fs-6">Subtotal</label>
                                        <ErrorIcon
                                            disabled
                                            style={{fontSize: "large", marginTop: '2px', display: (originalSubtotalIsBetter) ? "block" : "none"}}
                                            onMouseEnter={() => {
                                                setHelp('original_value_less_than_edited_value')
                                            }}
                                            onMouseLeave={() => {
                                                setHelp('')
                                            }}
                                            onClick={()=> {
                                                setHelp('original_value_less_than_edited_value')
                                                setTimeout(() => {
                                                    setHelp('')
                                                }, 1500)
                                                return
                                            }}
                                            id="original_value_less_than_edited_value"
                                        />
                                        
                                        <Tooltip placement="right" isOpen={help === "original_value_less_than_edited_value"} target="original_value_less_than_edited_value">
                                            Este precio de venta es menor al costo del producto.
                                        </Tooltip>
                                    </div>
                                    <input
                                        type={'number'}
                                        name={'contribution_margin'}
                                        className="form-control p-form  br-btn-t mb-1"
                                        value={editableSubtotal}
                                        onChange={(e)=>{
                                            const newValue = parseFloat(e.target.value)
                                            setEditableSubtotal(newValue)

                                            console.log(subTotal > newValue)
                                            if (subTotal > newValue) setOriginalSubtotalIsBetter(true)
                                            else setOriginalSubtotalIsBetter(false)
                                            setState({
                                                ...state,
                                                subtotal: newValue
                                            })
                                        }}
                                    />
                                    <small className="form-text text-muted">Este valor se calcula automáticamente</small>
                                </div>
                            </div>
                        </div>

                        {!userIsPayment?.plan?.is_free ?
                        <div className='card mb-1'>
                            <Row>
                                {/* <Col className="mb-1" md="4" sm="12" style={{marginRight: '-1.5rem'}}>
                                    <div className='card-body'>
                                        <div className="form-group margin-bottom-0">
                                            <label className={'form-label fs-6'}>{'Concepto'}</label>
                                            {
                                                history.location.pathname.includes('view') ||
                                                history.location.pathname.includes('edit') ? (
                                                    <input
                                                        type={'text'}
                                                        name={'concept'}
                                                        className={`${history.location.pathname.includes('edit') ? null : 'btn-disabled'} form-control p-form  br-btn-t`}
                                                        value={(state.concept)?state.concept:''}
                                                        readOnly
                                                    />
                                                ) : (
                                                    <input
                                                        name={'concept'}
                                                        type={'text'}
                                                        className={
                                                            errors.concept.bol ?
                                                            "form-control p-form  br-btn-t error-input" :
                                                            "form-control p-form  br-btn-t"
                                                        }
                                                        value={(state.concept)?state.concept:''}
                                                        onChange={e => {
                                                            setState({
                                                                ...state,
                                                                [e.target.name]: e.target.value
                                                            })
                                                        }}
                                                    />
                                                )
                                            }
                                            <div
                                                className={errors.concept.bol ?
                                                    "invalid-input" :
                                                    "valid-input"
                                                }
                                            >
                                                {errors.concept.msg}
                                            </div>
                                        </div>
                                    </div>
                                </Col> */}
                                <Col className="mb-1" md="6" sm="8" style={{marginRight: '-1.5rem'}}>
                                    <div className="card-body">
                                        <div className="form-group margin-bottom-0">
                                            <label className={'form-label fs-6'}>{'Medio de pago'}</label>
                                            {
                                                history.location.pathname.includes('view') ? (
                                                    <input
                                                        type={'text'}
                                                        name={'method_payment'}
                                                        className={`${history.location.pathname.includes('edit') ? null : 'btn-disabled'} form-control p-form  br-btn-t`}
                                                        value={state.method_payment}
                                                        readOnly
                                                    />
                                                ) : (
                                                    <select
                                                        onChange={(e) => setState({
                                                            ...state,
                                                            method_payment: e.target.value
                                                        })}
                                                        value={state.method_payment}
                                                        className={
                                                            errors.method_payment.bol ?
                                                            "custom-select  error-input" :
                                                            "custom-select  br-btn-t"
                                                        }
                                                    >
                                                        <option value={undefined} default>Seleccione un tipo de pago</option>
                                                        <option value={'efectivo'}>Efectivo</option>
                                                        <option value={'mercado_pago'}>Mercado pago</option>
                                                        <option value={'paypal'}>Paypal</option>
                                                        <option value={'uala'}>Ualá</option>
                                                        <option value={'rapipago'}>Rapipago</option>
                                                        <option value={'transferencia'}>Transferencia bancaria</option>
                                                        <option value={'otro'}>Otro</option>
                                                    </select>
                                                )
                                            }
                                            <div
                                                className={errors.method_payment.bol ?
                                                    "invalid-input" :
                                                    "valid-input"
                                                }
                                            >
                                                {errors.method_payment.msg}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="mb-1" md="6" sm="12">
                                    <div className="card-body">
                                        <div className="form-group margin-bottom-0">
                                            <label className={'form-label fs-6'}>{'Estado del Pago'}</label>
                                            {
                                                history.location.pathname.includes('view') ? (
                                                    <input
                                                        type={'text'}
                                                        name={'status_payment'}
                                                        className={"btn-disabled form-control p-form  br-btn-t"}
                                                        value={state.status_payment}
                                                        readOnly
                                                    />
                                                ) : (
                                                    <select
                                                        onChange={(e) => setState({
                                                            ...state,
                                                            status_payment: e.target.value
                                                        })}
                                                        value={state.status_payment}
                                                        className={
                                                            errors.status_payment.bol ?
                                                            "custom-select  br-btn-t error-input" :
                                                            "custom-select  br-btn-t"
                                                        }
                                                    >
                                                        <option value={undefined} default>Seleccione un estado</option>
                                                        <option value={'payment-pending'}>Pago Pendiente</option>
                                                        <option value={'payment-approved'}>Pago Aprobado</option>
                                                    </select>
                                                )
                                            }
                                            <div
                                                className={errors.status_payment.bol ?
                                                    "invalid-input" :
                                                    "valid-input"
                                                }
                                            >
                                                {errors.status_payment.msg}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        : null}
                        
                        <div className='card mb-1'>
                            <div className='card-body'>
                                <div className="form-group margin-bottom-0">
                                    <label className="form-label fs-6">Detalles</label>
                                    {
                                        history.location.pathname.includes('view') ? (
                                            <textarea
                                                name={'details'}
                                                className={`${history.location.pathname.includes('edit') ? null : 'btn-disabled'} form-control p-form  br-btn-t resize-none`}
                                                value={state.details}
                                                readOnly
                                            />
                                        ) : (
                                            <textarea
                                                name={'details'}
                                                className={"form-control p-form btn-height-6 br-btn-t resize-none"}
                                                value={state.details}
                                                onChange={e =>
                                                    setState({
                                                        ...state,
                                                        [e.target.name]: e.target.value
                                                    })
                                                }
                                            />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md="4">
                        <div 
                            className="card mb-1" 
                            // style={{height: '24.7%'}}}
                        >
                            <div className="card-body">
                                <div className="form-group margin-bottom-0">
                                    <label className="form-label fs-6">Fecha</label>
                                    {
                                        history.location.pathname.includes('view') ? (
                                            <input
                                                type={'date'}
                                                name={'order_date'}
                                                className={"btn-disabled form-control p-form  br-btn-t"}
                                                value={state.order_date}
                                                readOnly
                                            />
                                        ) : (
                                            <input
                                                type="date"
                                                name="order_date"
                                                value={state.order_date}
                                                className={errors?.order_date?.bol ?
                                                    "form-control p-form  br-btn-t error-input" :
                                                    "form-control p-form  br-btn-t"
                                                }
                                                format={'yyyy-MM-dd'}
                                                onChange={e => {
                                                    setState({
                                                        ...state,
                                                        [e.target.name]: e.target.value
                                                    })
                                                }}
                                            />
                                        )
                                    }
                                    <div
                                        className={errors?.order_date?.bol ?
                                            "invalid-input" :
                                            "valid-input"
                                        }
                                    >
                                        {errors?.order_date?.msg}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div 
                            className="card mb-1" 
                            // style={{height: '26.7%'}}
                        >
                            <div className="card-body">
                                <div className="form-group margin-bottom-0">
                                    <label className={'form-label fs-6'}>{'Estado del Pedido'}</label>
                                    {
                                        history.location.pathname.includes('view') ? (
                                            <input
                                                type={'text'}
                                                name={'status_payment'}
                                                className={"btn-disabled form-control p-form  br-btn-t"}
                                                value={state.status_order === "entered" ?
                                                    "Ingresado" : state.status_order === "in-preparation" ?
                                                        "En preparación" : state.status_order === "ready-to-deliver" ?
                                                            "Listo para entregar" : state.status_order === "delivered" ?
                                                                "Entregado" : null
                                                }
                                                readOnly
                                            />
                                        ) : (
                                            <select
                                                name={'status_order'}
                                                className={errors.status_order.bol ?
                                                    "custom-select  error-input" :
                                                    "custom-select "
                                                }
                                                value={state.status_order}
                                                onChange={e => {
                                                    setState({
                                                        ...state,
                                                        [e.target.name]: e.target.value
                                                    })
                                                }}
                                            >
                                                <option default value={undefined}>Seleccione una opción</option>
                                                <option value="entered">Ingresado</option>
                                                <option value="in-preparation">En preparación</option>
                                                <option value="ready-to-deliver">Listo para entregar</option>
                                                <option value="delivered">Entregado</option>
                                            </select>
                                        )
                                    }
                                    <div
                                        className={errors.status_order.bol ?
                                            "invalid-input" :
                                            "valid-input"
                                        }
                                    >
                                        {errors.status_order.msg}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {!userIsPayment?.plan?.is_free ?
                        <div 
                            className='card mb-1' 
                            // style={{height: '33.7%'}}
                        >
                            <div className="card-body ">
                                <div className="form-group margin-bottom-0">
                                    <label className={'form-label fs-6'}>{'Cliente'}</label>
                                    {
                                        history.location.pathname.includes('view') ? (
                                            <input
                                                type={'text'}
                                                name={'status_payment'}
                                                className={`${history.location.pathname.includes('edit') ? null : 'btn-disabled'} form-control p-form  br-btn-t`}
                                                value={clients.results && clients.results.filter(x => x.id === state.client).length ?
                                                    clients.results.filter(x => x.id === state.client)[0].name
                                                    : "-"
                                                }
                                                readOnly
                                            />
                                        ) : (
                                            <select
                                                onChange={(e) => setState({
                                                    ...state,
                                                    client: e.target.value
                                                })}
                                                value={state.client ? state.client : undefined}
                                                className={
                                                    errors.client.bol ?
                                                        "custom-select  error-input" :
                                                        "custom-select "
                                                }
                                            >
                                                <option value={undefined} default>Seleccione un cliente</option>
                                                {
                                                    clients.results ? clients.results.map(el => {
                                                        return (
                                                            el.id === idClient ? (
                                                                <option key={el.id} default value={el.id}>{el.name}</option>
                                                            ) : (
                                                                <option key={el.id} value={el.id}>{el.name}</option>
                                                            )
                                                        )
                                                    }) : null
                                                }
                                            </select>
                                        )
                                    }
                                    {
                                        history.location.pathname.includes('view') ?
                                            null : (
                                                <button
                                                    className="linkModal mt-1 color-primary background-transparent border-none"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setAddClient(true)
                                                    }}
                                                    type='button'
                                                >
                                                    + Agregar cliente
                                                </button>
                                            )
                                    }
                                    <div
                                        className={errors?.client?.bol ?
                                            "invalid-input" :
                                            "valid-input"
                                        }
                                    >
                                        {errors.client.msg}
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}
                    </Col>
                </Row>
            </form>
            <Row className="mb-1">
                <div
                    className={"col"}
                    // style={
                    //     history.location.pathname.includes('view') ?
                    //         { display: "flex", justifyContent: "space-between" } :
                    //         window.innerWidth < 992 ? { width: "75%" } :
                    //             null
                    // }
                >
                    {
                        id ? (
                            <>
                                <Link to={back}>
                                    <button type="submit" className="btn btn-outline-secondary  br-btn-t mr-1">Cancelar</button>
                                </Link>
                                <button
                                    className="btn btn-primary  br-btn-t color-white"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setModal(true)
                                    }}
                                >
                                    Borrar
                                </button>
                            </>
                        ) : null
                    }
                </div>
                {
                    history.location.pathname.includes('view') ?
                        null : (
                            <div
                                // style={
                                //     window.innerWidth > 991 ?
                                //         null : { width: "25%" }
                                // }
                                className="col d-flex justify-content-flex-end "
                            >
                                {
                                    history.location.pathname.includes('incomes') ? (
                                        !id ? (
                                            <Link to={back}>
                                                <button type="submit" className="btn btn-outline-secondary  br-btn-t mr-1">
                                                    {
                                                        history.location.pathname.includes('view') ?
                                                            'Volver' : 'Cancelar'
                                                    }
                                                </button>
                                            </Link>
                                        ) : null
                                    ) : (
                                        null
                                    )
                                }
                                {
                                    !disabled ? (
                                        <button type="submit" id='save-btn' className="btn background-secondary  br-btn-t color-white" onClick={e => {
                                            onSubmit(e);
                                        }}>Guardar</button>
                                    ) : (
                                        <button type="submit" className="btn background-secondary  br-btn-t color-white" disabled={true}>
                                            <span className="spinner-border spinner-border-sm">
                                                <span className="sr-only"></span>
                                            </span>
                                        </button>
                                    )
                                }
                            </div>
                        )
                }
            </Row>
            <Modal show={modalNotEnoughStock} className="modal-danger">
                <Modal.Header>
                    <h5 className="modal-title color-primary">No queda stock de ese producto</h5>
                    <button type="button" className="close color-primary" onClick={() => setModalNotEnoughStock(false)}>
                        <span>×</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <p>Pruebe mandando a producir</p>
                </Modal.Body>
            </Modal> 
            <Modal show={modalProductionBlocking} className="modal-danger">
                <Modal.Header>
                    <h5 className="modal-title color-primary">No puedes producir mas de un producto desde ventas</h5>
                    <button type="button" className="close color-primary" onClick={() => setModalProductionBlocking(false)}>
                        <span>×</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <p>Pruebe mandando a producir desde la sección de Productos</p>
                </Modal.Body>
            </Modal>
            <Modal show={modalProduce} className="modal-danger">
                <Modal.Header>
                    <h5 className="modal-title color-primary">{modalProduceTitle} {modalProduce?.name}</h5>
                    <button type="button" className="close color-primary" onClick={() => setModalProduce(null)}>
                        <span>×</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label className="form-label fs-6">Producir una unidad de {modalProduce?.name}</label>
                        {producingError?.message ? <p className='text-danger'>{producingError.message}</p> : null}
                        {
                            producingError?.type === 'without_stock' ? 
                                <div>
                                    <p className='text-danger mb-0'>No se puede producir porque no hay suficiente stock de:</p>
                                    <div>
                                        {producingError.supplies.map((item) => {
                                            return (
                                                <small className='text-danger d-flex align-items-center'>
                                                    <span className="material-icons text-danger fs-6">link</span>
                                                    <Link to={`/supplies/edit/${item.id}`} className='text-danger'>{item.name}</Link>
                                                </small>
                                            )
                                        })}
                                    </div>
                                </div>
                            : null
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className="btn btn-outline-secondary btn-height-3 br-btn-t mr-1" onClick={() => setModalProduce(null)}>Cancelar</button>
                    {
                        !producing ? (
                            <button
                                type="submit"
                                className="btn btn-primary btn-height-3 br-btn-t color-white"
                                onClick={(e) => {
                                    modalProduceTitle === 'Producir' ? setProduce(1, modalProduce) : setDiscard(quantityToProduce, modalProduce)
                                }}
                            >
                                {modalProduceTitle}
                            </button>
                        ) : (
                            <button type="submit" className="btn background-secondary btn-height-3 br-btn-t color-white" disabled={true}>
                                <span className="spinner-border spinner-border-sm">
                                    <span className="sr-only"></span>
                                </span>
                            </button>
                        )
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}
import React, { useState, useEffect } from 'react';
import MenuLateral from '../component/MenuLateral';
import { useHistory, Switch, Route, Redirect, useLocation } from 'react-router';
import MenuSuperior from '../component/MenuSuperior';
import CreateVendor from './createVendor';
import EditVendor from './editVendor';
import Vendors from './Vendors';
import CreateClient from './createClient';
import EditClient from './editClient';
import Clients from './Clients';
import Products from './Products';
import CreateProduct from './createProduct';
import CommercialData from './CommercialData';
import EditProduct from './editProduct';
import { Logged } from '../utils/authentication';
import { toast } from 'react-toastify';
import CreateSupplies from './createSupplies';
import EditSupplies from './editSupplies';
import Supplies from './Supplies';
import Incomes from './Incomes';
import Withdrawals from './Withdrawals';
import CreateIncomes from './createIncomes';
import CreateWithdrawals from './createWithdrawals';
import EditWithdrawals from './editWithdrawals';
import ViewWithdrawals from './viewWithdrawals';
import EditIncomes from './editIncomes';
import Settings from './Settings';
import Profile from './Profile'
import Packaging from './Packaging';
import CreatePackaging from './createPackaging';
import EditPackaging from './editPackaging';
import Dashboard from './Dashboard';
import CreateSuppliesWorkforce from './createSuppliesWorkforce';
import { Invested } from './Invested';
import { CreateInvested } from './createInvested';
import { EditInvested } from './editInvested';
import { Premium } from './Premium';
import SettingInitial from './settingInitial';
import { useSelector } from 'react-redux';
/* import { MercadoPago } from '../component/MercadoPago';
import { MercadoPagoCredit } from '../component/MercadoPagoCredit';
import { MercadoPagoDebit } from '../component/MercadoPagoDebit'; */

export default function Container() {
    const [click, setClick] = useState();
    const history = useHistory();
    const location = useLocation();
    const [data, setData] = useState();
    const [sub, setSub] = useState(false)
    const [reload, setReload] = useState(false);
    const [user, setUser] = useState();

    useEffect(() => {
        toast.dismiss();
    }, [location])

    useEffect(() => {
        window.innerWidth < 992 ? setClick(true) : setClick(false)
    }, [])

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/auth/profile/`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                setUser(json)
            })
    }, [reload])


    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/config/global/?key=supplies`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {

                if(json.detail === 'Token inválido.') {
                    return <Redirect to='/login' />
                }

                setData(json?.results[0]?.value)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])

    useEffect(() => {
        const token = Logged();
        if (!token) {
            toast.success('Debe iniciar sesión nuevamente', {
                className: "Toastify__toast--error"
            })
            setTimeout(() => {
                return history.push('/login')
            }, 50)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const userIsPayment = useSelector(stee => stee?.userPremiumPay)

    const numericInputs = document.querySelectorAll("input[type='number']");
    numericInputs.forEach((input) => {
        input.addEventListener("wheel", (e) => {
            e.preventDefault();
        });
    });

    return (
        <>
            <MenuLateral
                sub={sub}
                setSub={setSub}
                click={click}
                data={data}
                setClick={setClick}
                active={
                    history.location.pathname.includes('dashboard') ? "dashboard" :
                        history.location.pathname.includes('vendors') ? "proveedores" :
                            history.location.pathname.includes('clients') ? "clientes" :
                                history.location.pathname.includes('commercial-data') ? "commercial-data" :
                                    history.location.pathname.includes('products') ? "products" :
                                        history.location.pathname.includes('supplies') ? "supplies" :
                                            history.location.pathname.includes('incomes') ? "incomes" :
                                                history.location.pathname.includes('withdrawals') ? "expenses" :
                                                    history.location.pathname.includes('invested') ? "invested" :
                                                        history.location.pathname.includes('packaging') ? "packaging" :
                                                            history.location.pathname.includes('setting') ? "setting" :
                                                                history.location.pathname.includes('pro') ? "Pro" :
                                                                    history.location.pathname.includes('entrepreneur-salary') ? "entrepreneur-salary" :
                                                                        null
                }
            />
        
            <MenuSuperior setClick={setClick} click={click} user={user} />
            <div
                className={
                    window.innerWidth < 992 ? "content app-content" :
                        click ? "content content-colapse-2 app-content" :
                            "content content-colapse-1 app-content"
                }
            >
                <Switch>
                    {!userIsPayment?.plan?.is_free ? <Route path="/vendors/create" exact component={CreateVendor} /> : null}
                    {!userIsPayment?.plan?.is_free ? <Route path="/vendors/edit/:id" exact component={EditVendor} /> : null}
                    {!userIsPayment?.plan?.is_free ? <Route path="/clients" exact component={Clients} /> : null}
                    {!userIsPayment?.plan?.is_free ? <Route path="/vendors" exact component={Vendors} /> : null}
                    <Route path="/supplies" exact component={Supplies} />
                    {!userIsPayment?.plan?.is_free ? <Route path="/clients/create" exact component={CreateClient} /> : null}
                    {!userIsPayment?.plan?.is_free ? <Route path="/clients/edit/:id" exact component={EditClient} /> : null}
                    <Route path="/products" exact component={Products} />
                    <Route path="/products/create" exact component={CreateProduct} />
                    <Route path="/products/view/:id" exact component={EditProduct} />
                    {!userIsPayment?.plan?.is_free ? <Route path="/vendors/view/:id" exact component={EditVendor} /> : null}
                    {!userIsPayment?.plan?.is_free ? <Route path="/clients/view/:id" exact component={EditClient} /> : null}
                    <Route path="/commercial-data" exact component={CommercialData} />
                    <Route path="/products/edit/:id" exact component={EditProduct} />
                    <Route path="/supplies/create" exact component={CreateSupplies} />
                    {!userIsPayment?.plan?.is_free ? <Route path="/supplies/create-workforce" exact component={CreateSuppliesWorkforce} /> : null}
                    <Route path="/supplies/edit/:id" exact component={EditSupplies} />
                    <Route path="/supplies/view/:id" exact component={EditSupplies} />
                    <Route path="/incomes" exact component={Incomes} />
                    <Route path="/incomes/create" exact component={CreateIncomes} />
                    <Route path="/incomes/edit/:id" exact component={EditIncomes} />
                    <Route path="/incomes/view/:id" exact component={EditIncomes} />
                    {!userIsPayment?.plan?.is_free ? <Route path="/withdrawals" exact component={Withdrawals} /> : null}
                    {!userIsPayment?.plan?.is_free ? <Route path="/withdrawals/create" exact component={CreateWithdrawals} /> : null}
                    {!userIsPayment?.plan?.is_free ? <Route path="/withdrawals/edit/:id" exact component={EditWithdrawals} /> : null}
                    {!userIsPayment?.plan?.is_free ? <Route path="/withdrawals/view/:id" exact component={ViewWithdrawals} /> : null}
                    <Route path="/setting" exact render={() => <Settings setReload={setReload} reload={reload} />} />
                    <Route path="/packaging" exact component={Packaging} />
                    <Route path="/packaging/create" exact component={CreatePackaging} />
                    <Route path="/packaging/edit/:id" exact component={EditPackaging} />
                    <Route path="/dashboard" exact component={Dashboard} />
                    {!userIsPayment?.plan?.is_free ? <Route path="/invested" exact component={Invested} /> : null}
                    {!userIsPayment?.plan?.is_free ? <Route path="/invested/create" exact component={CreateInvested} /> : null}
                    {!userIsPayment?.plan?.is_free ? <Route path="/invested/edit/:id" exact component={EditInvested} /> : null}
                    <Route path="/planes" exact component={Premium} />
                    <Route path="/entrepreneur-salary/:id" exact component={SettingInitial} />
                    <Route path="/profile" exact render={() => <Profile reloadContainer={setReload} bol={reload} />} />
                    
                    <Redirect to="/dashboard" />
                </Switch>
            </div>
        </>
    )
}
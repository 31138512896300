import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import moment from 'moment';

export const ProductsModalFilter = ({setShowFilter, showFilter, handleSubmit, filterProducts, providersList, isWholeSale}) => {
    const [ type, setType ] = useState(false)
    const [ status, setStatus ] = useState(false)
    const [ providers, setProviders] = useState(false)
        
    return (
        <Modal className="modal-danger" show={showFilter} onHide={() => setShowFilter(false)}>
            <Modal.Header>
                <h5 className="modal-title color-primary">Filtrar por</h5>
                <button type="button" className="close color-primary" onClick={() => setShowFilter(false)}>
                    <span>×</span>
                </button>
            </Modal.Header>

            <Modal.Body>
                {
                    isWholeSale ?
                        <div className='border-bottom mb-2' style={{display: 'flex', flexDirection:'column', width:'100%', justifyContent:'left', alignItems:'left'}}>
                        <button onClick={()=>setType(!type)} className='border-none' style={{background:'white'}}>FILTRO POR TIPO </button>
                            {
                                (type === false)?null:(
                                    <>
                                        <p 
                                            style={{color: '#71cabf', cursor:'pointer'}} 
                                            onClick={(e)=>{ 
                                                e.target.value = ''
                                                handleSubmit(e)
                                            }
                                            }
                                        >
                                            Todos
                                        </p>
                                        <p 
                                            style={{color: '#71cabf', cursor:'pointer'}} 
                                            onClick={(e)=>{ 
                                                e.target.value = true
                                                handleSubmit(e)
                                            }
                                            }
                                        >
                                            Mayorista
                                        </p>
                                        <p 
                                            style={{color: '#71cabf', cursor:'pointer'}} 
                                            onClick={(e)=>{ 
                                                e.target.value = false
                                                handleSubmit(e)
                                            }
                                            }
                                        >
                                            Minorista
                                        </p>
                                    </>
                                )
                            }
                        </div>
                    :
                        null
                }
                <div className='border-bottom mb-2' style={{display: 'flex', flexDirection:'column', width:'100%', justifyContent:'left', alignItems:'left'}}>
                    <button onClick={()=>setStatus(!status)} className='border-none' style={{background:'white'}}>FILTRO POR ESTADO </button>
                    {
                        (status === false)?null:(
                            <>
                                <p 
                                    style={{color: '#71cabf', cursor:'pointer'}} 
                                    onClick={(e)=>{ 
                                        e.target.value = 'true'
                                        filterProducts(e, 'is_active')
                                    }
                                    }
                                >
                                    Activos
                                </p>
                                <p 
                                    style={{color: '#71cabf', cursor:'pointer'}} 
                                    onClick={(e)=>{ 
                                        e.target.value = 'false'
                                        filterProducts(e, 'is_active')
                                    }
                                    }
                                >
                                    Desactivados
                                </p>
                                <p 
                                    style={{color: '#71cabf', cursor:'pointer'}} 
                                    onClick={(e)=>{ 
                                        e.target.value = 'true,false'
                                        filterProducts(e, 'is_active')
                                    }
                                    }
                                >
                                    Todos
                                </p>
                            </>
                        )
                    }
                </div>
                <div className='border-bottom mb-2' style={{display: 'flex', flexDirection:'column', width:'100%', justifyContent:'left', alignItems:'left'}}>
                    <button onClick={()=>setProviders(!providers)} className='border-none' style={{background:'white'}}>FILTRO POR PROVEEDOR </button>
                    {
                        (providers === false)?null:(
                            <>
                                <p 
                                    style={{color: '#71cabf', cursor:'pointer'}} 
                                    onClick={(e)=>{ 
                                        e.target.value = ''
                                        filterProducts(e, 'provider')
                                    }
                                    }
                                >
                                    Todos
                                </p>
                                {
                                    providersList.map((provider, key) =>
                                        <p 
                                            key={key}
                                            style={{color: '#71cabf', cursor:'pointer'}} 
                                            onClick={(e)=>{ 
                                                e.target.value = provider.id
                                                filterProducts(e, 'provider')
                                            }
                                            }
                                        >
                                            {provider.name}
                                        </p>
                                    )
                                }
                                
                            </>
                        )
                    }
                </div>
            </Modal.Body>

            <Modal.Footer>
                <button type="submit" className="btn btn-outline-primary btn-height-3 br-btn-t mr-1" onClick={() => setShowFilter(false)}>Aceptar</button>
                <button type="submit" className="btn btn-outline-secondary btn-height-3 br-btn-t mr-1" onClick={() => setShowFilter(false)}>Cancelar</button>
            </Modal.Footer>
        </Modal>
    )
}

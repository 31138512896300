import * as React from 'react';
import { Spinner } from 'reactstrap';

const Loading = () => {
    return (
        <div 
            className='loading d-flex justify-content-center align-items-center'
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(255,255,255,.5)',
                zIndex: 999,
            }} 
        >
            <Spinner  color="primary"/>
        </div>
    )
}

export default Loading;
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import CreateVendor from './createVendor';
import FormPackaging from '../component/FormPackaging';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getPackaging, getProviders, postPackaging } from '../redux/action';

export default function CreatePackaging({ setPackageAdded, setAddPackaging }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const vendors = useSelector(store => store.providers);
    const packaging = useSelector(store => store.packaging);
    const [disabled, setDisabled] = React.useState(false);
    const [idVendor, setId] = useState(null)
    const [addVendors, setAddVendors] = useState(false)
    const [state, setState] = useState({
        name: '',
        unit_measurement: '',
        element_height: 0,
        element_width: 0,
        square_meter: 0,
        cost_unit: 0,
        provider: undefined,
        current_stock_in_units: 0,
        current_stock_in_cost: 0,
        ideal_stock_in_units: 0,
        stock_replenish: 0,
        stock_replenish_in_cost: 0,
        packaging_type: 'simple',
        amount: 0,
        group_amount: 0
    })

    const [group, setGroup] = useState([])

    const [errors, setErrors] = useState({
        name: {
            bol: false,
            msg: ''
        },
        group: {
            bol: false,
            msg: ''
        },
        unit_measurement: {
            bol: false,
            msg: ''
        },
        element_height: {
            bol: false,
            msg: ''
        },
        element_width: {
            bol: false,
            msg: ''
        },
        square_meter: {
            bol: false,
            msg: ''
        },
        cost_unit: {
            bol: false,
            msg: ''
        },
        provider: {
            bol: false,
            msg: ''
        },
        current_stock_in_units: {
            bol: false,
            msg: ''
        },
        current_stock_in_cost: {
            bol: false,
            msg: ''
        },
        ideal_stock_in_units: {
            bol: false,
            msg: ''
        },
        stock_replenish: {
            bol: false,
            msg: ''
        },
        stock_replenish_in_cost: {
            bol: false,
            msg: ''
        }
    })

    useEffect(() => {
        // GET packaging
        dispatch(getPackaging());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        dispatch(getProviders())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addVendors])

    React.useEffect(() => {
        setState({
            ...state,
            provider: idVendor
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idVendor])

    if(state?.stock_replenish < 0) {
        setState({
            ...state, 
            stock_replenish: 0
        })
    }

    if(state?.stock_replenish_in_cost < 0) {
        setState({
            ...state,
            stock_replenish_in_cost: 0
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        let err = {
            group: {
                bol: false,
                msg: ''
            }
        }


        if (state.packaging_type === "simple") {
            Object.keys(state).map(x => {
                if (
                    x === "element_height" ||
                    x === "element_width" ||
                    x === "square_meter" ||
                    x === "stock_replenish" ||
                    x === "stock_replenish_in_cost" ||
                    x === "current_stock_in_cost"
                ) {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    };
                } else {
                    if (
                        x === "cost_unit" ||
                        x === "unit_measurement" ||
                        x === "name" ||
                        x === "provider" 

                    ) {
                        if (state[x] === '') {
                            return err[x] = {
                                bol: true,
                                msg: 'Este campo no puede estar vacío'
                            };
                        } else {
                            return err[x] = {
                                bol: false,
                                msg: ''
                            };
                        }
                    } else {
                        return err[x] = {
                            bol: false,
                            msg: ''
                        };
                    }
                }
            })
        } else {
            Object.keys(state).map(x => {
                if (
                    x === "name"
                ) {
                    if (state[x] === '') {
                        return err[x] = {
                            bol: true,
                            msg: 'Este campo no puede estar vacío'
                        };
                    } else {
                        return err[x] = {
                            bol: false,
                            msg: ''
                        };
                    }
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    };
                }
            })
        }

        const arr  = [];

        if (state.packaging_type === "bundled") {
            if (group.length) {
                group.map(el => {
                    return arr.push({
                        packaging: el.id,
                        quantity: el.quantity
                    })
                })
            } else {
                err = {
                    ...err,
                    group: {
                        bol: true,
                        msg: 'Debe seleccionar al menos un packaging'
                    }
                }
                toast.error(err['group']['msg'], {
                    className: "Toastify__toast--error"
                })
            }
        }

        setErrors(err)

        if (Object.values(err).filter(x => x.bol === true).length === 0) {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
            myHeaders.append("Content-Type", 'application/json')
            setDisabled(true)
            setTimeout(() => {
                setDisabled(false);
            }, 1500)

            console.log('state', state)

            const body = state.packaging_type === "simple" ?
                {
                    ...state,
                    group: arr,
                    amount: state.cost_unit,
                    square_meter: state.element_height * state.element_width
                } :
                {
                    ...state,
                    group: arr,
                    provider: vendors.results[0].id,
                    unit_measurement: 'un',
                    cost_unit: null,
                }
            dispatch(postPackaging(body, function() {
                if(history.location.pathname.includes('product')) {
                    setAddPackaging(false)
                } else {
                    setTimeout(()=> {
                        history.push('/packaging')
                    }, 800)
                }
            }))
        }
        if (setPackageAdded) setPackageAdded(state)
    }

    return (
        <>
            {
                <Modal show={addVendors} className="modal-create">
                    <Modal.Header>
                        <h5 className="modal-title color-primary">{'Agregar proveedor'}</h5>
                        <button type="button" className="close color-primary" onClick={() => setAddVendors(false)}>
                            <span>×</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <CreateVendor setAddVendors={setAddVendors} addVendors={addVendors} setId={setId} />
                    </Modal.Body>
                </Modal>
            }
            <FormPackaging
                title={"Agregar packaging"}
                state={state}
                setState={setState}
                errors={errors}
                back={'/packaging'}
                onSubmit={onSubmit}
                vendors={vendors}
                setAddVendors={setAddVendors}
                addVendors={addVendors}
                idVendor={idVendor}
                setDisabled={setDisabled}
                disabled={disabled}
                group={group}
                setGroup={setGroup}
                packaging={packaging}
            />
        </>
    )
}
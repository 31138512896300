import React,  { useEffect } from 'react';
import { Row, Col, NavItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
// import { Tooltip } from 'reactstrap';
import { useHistory, useParams } from 'react-router';
import { unitsReplenishStock, costReplenishStock, costStockActualSupplies } from '../utils/functions';
import InputControlled from './InputControlled';
import FormNewPackaging from './FormNewPackaging';
import FormGroupItems from './FormGroupItems';
import { useDispatch } from 'react-redux';
import { getPayUser } from '../redux/action';

export default function FormPackaging({ packaging, group, setModal, setGroup, title, state, errors, setState, back, vendors, setAddVendors, addVendors, idVendor, onSubmit, setDisabled, disabled }) {
    const [help, setHelp] = React.useState('');
    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPayUser())
    }, [dispatch])

    return (
        <>
            <div className={history.location.pathname.includes('product') ? null : "card mb-3"}>
                <>
                    {
                        history.location.pathname.includes('product') ? null : (
                            <div className="card-header mt-1">
                                <div className="card-title">
                                    <h2>
                                        {title}
                                    </h2>
                                </div>
                            </div>
                        )
                    }
                    <div className="card-body pt-1">
                        <form>
                            <Row>
                                <Col md="12">
                                    <InputControlled
                                        classContainer={'form-group mb-0'}
                                        classLabel={'form-label fs-6'}
                                        textLabel={'Nombre'}
                                        typeBtn={'text'}
                                        nameBtn={'name'}
                                        styleBtn={errors.name?.bol ?
                                            "form-control p-form br-btn-t error-input" :
                                            "form-control p-form br-btn-t"
                                        }
                                        valueBtn={state.name}
                                        setValue={setState}
                                        state={state}
                                        read={history.location.pathname.includes('view') ?
                                            true : undefined
                                        }
                                        classError={errors.name?.bol ?
                                            "invalid-input" :
                                            "valid-input"
                                        }
                                        textError={errors.name?.msg}
                                    />
                                    {
                                        history.location.pathname.includes('view') ? (
                                            <div className="d-flex align-items-center justify-content-flex-start mb-1">
                                                <div
                                                    className="form-group d-flex align-items-center width-auto margin-bottom-0 margin-0"
                                                >
                                                    <div className="custom-control-primary custom-control custom-radio">
                                                        <input type="radio" id='check-1' name="packaging_type" className="custom-control-input" />
                                                        <label
                                                            htmlFor='check-1'
                                                            className="custom-control-label checked"
                                                            readOnly
                                                        >
                                                            {
                                                                state.packaging_type === "simple" ? "Packaging nuevo" : "Agrupar items"
                                                            }
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : state.packaging_type === "simple" ? (
                                            <div className="d-flex align-items-center justify-content-flex-start mb-1">
                                                <div
                                                    className="form-group d-flex align-items-center width-auto margin-bottom-0 margin-0"
                                                >
                                                    <div className="custom-control-primary custom-control custom-radio">
                                                        <input defaultChecked={state.packaging_type === "simple"} type="radio" id='check-2' name="packaging_type" className="custom-control-input" />
                                                        <label
                                                            htmlFor='check-2'
                                                            className="custom-control-label checked"
                                                            onClick={() => {
                                                                if (!history.location.pathname.includes('view')) {
                                                                    setState({ ...state, packaging_type: "simple" })
                                                                }
                                                            }}
                                                        >
                                                            Packaging nuevo
                                                        </label>
                                                    </div>
                                                </div>
                                                <div
                                                    className="form-group d-flex align-items-center margin-bottom-0 width-auto margin-0-1"
                                                >
                                                    <div className="custom-control-primary custom-control custom-radio">
                                                        <input disabled={!packaging.results.length} defaultChecked={state.packaging_type === "bundled"} type="radio" id='check-3' name="packaging_type" className="custom-control-input" />
                                                        <label
                                                            htmlFor='check-3'
                                                            className="custom-control-label"
                                                            onClick={() => {
                                                                if (!history.location.pathname.includes('view') && packaging.results.length) {
                                                                    setState({ ...state, packaging_type: "bundled" })
                                                                } 
                                                                if(!packaging.results.length) {
                                                                    alert('No hay items para agrupar')
                                                                }
                                                            }}>
                                                            Agrupar items
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="d-flex align-items-center justify-content-flex-start mb-1">
                                                <div
                                                    className="form-group d-flex align-items-center width-auto margin-bottom-0 margin-0"
                                                >
                                                    <div className="custom-control-primary custom-control custom-radio">
                                                        <input defaultChecked={state.packaging_type === "simple"} type="radio" id='check-4' name="packaging_type" className="custom-control-input" />
                                                        <label
                                                            htmlFor='check-4'
                                                            className="custom-control-label"
                                                            onClick={() => {
                                                                setState({ ...state, packaging_type: "simple" })
                                                            }}>
                                                            Packaging nuevo
                                                        </label>
                                                    </div>
                                                </div>
                                                <div
                                                    className="form-group d-flex align-items-center margin-bottom-0 width-auto margin-0-1"
                                                >
                                                    <div className="custom-control-primary custom-control custom-radio">
                                                        <input defaultChecked={state.packaging_type === "bundled"} type="radio" id='check-5' name="packaging_type" className="custom-control-input" />
                                                        <label
                                                            htmlFor='check-5'
                                                            className="custom-control-label checked"
                                                            onClick={() => {
                                                                if (packaging.results.length) {
                                                                    setState({ ...state, packaging_type: "bundled" })
                                                                }
                                                            }}>
                                                            Agrupar items
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </Col>
                            </Row>
                            {
                                state.packaging_type === "simple" ? (
                                    <>
                                        <FormNewPackaging
                                            state={state}
                                            history={history}
                                            setState={setState}
                                            errors={errors}
                                            idVendor={idVendor}
                                            vendors={vendors}
                                            addVendors={addVendors}
                                            setHelp={setHelp}
                                            help={help}
                                            setAddVendors={setAddVendors}
                                            costReplenishStock={costReplenishStock}
                                            costStockActualSupplies={costStockActualSupplies}
                                            id={id}
                                            unitsReplenishStock={unitsReplenishStock}
                                            back={back}
                                            disabled={disabled}
                                            onSubmit={onSubmit}
                                        />
                                    </>
                                ) : (
                                    <FormGroupItems
                                        state={state}
                                        setGroup={setGroup}
                                        group={group}
                                        history={history}
                                        errors={errors}
                                        id={id}
                                        back={back}
                                        disabled={disabled}
                                        onSubmit={onSubmit}
                                        packaging={packaging}
                                        setState={setState}
                                        setDisabled={setDisabled}
                                    />
                                )
                            }
                        </form>
                    </div>
                </>
            </div >
            <Row className="mb-3" style={window.innerWidth > 991 ? null : { display: "flex" }}>
                <div
                    className={window.innerWidth > 991 ? "col" : null}
                    style={
                        history.location.pathname.includes('view') ?
                            { display: "flex", justifyContent: "space-between" } :
                            window.innerWidth < 992 ? { width: "75%" } :
                                null
                    }
                >
                    {
                        id ? (
                            <>
                                <Link to={back}>
                                    <button type="submit" className="btn btn-outline-secondary br-btn-t mr-1">Cancelar</button>
                                </Link>
                                <button
                                    className="btn btn-primary br-btn-t color-white"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setModal(true)
                                    }}
                                >
                                    Borrar
                                </button>
                            </>
                        ) : null
                    }
                </div>
                {
                    history.location.pathname.includes('view') ?
                        null : (
                            <div
                                style={
                                    window.innerWidth > 991 ?
                                        null : { width: "25%" }
                                }
                                className={
                                    window.innerWidth > 991 ?
                                        "col d-flex justify-content-flex-end" :
                                        "d-flex justify-content-flex-end"
                                }
                            >
                                {
                                    history.location.pathname.includes('packaging') ? (
                                        !id ? (
                                            <Link to={back}>
                                                <button type="submit" className="btn btn-outline-secondary br-btn-t mr-1">
                                                    {
                                                        history.location.pathname.includes('view') ?
                                                            'Volver' : 'Cancelar'
                                                    }
                                                </button>
                                            </Link>
                                        ) : null
                                    ) : (
                                        null
                                    )
                                }
                                {
                                    !disabled ? (
                                        <button type="submit" id='save-btn' className="btn background-secondary br-btn-t color-white" onClick={e => {
                                            onSubmit(e);
                                        }}>Guardar</button>
                                    ) : (
                                        <button type="submit" className="btn background-secondary br-btn-t color-white" disabled={true}>
                                            <span className="spinner-border spinner-border-sm">
                                                <span className="sr-only"></span>
                                            </span>
                                        </button>
                                    )
                                }
                            </div>
                        )
                }
            </Row>
        </>
    )
}
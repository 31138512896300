import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Row, Col } from 'reactstrap';
import InputControlled from './InputControlled';

export default function ModalCommercialData({ modal, setModal, input, setInput, disabled, setDisabled, onSubmit }) {
    return (
        <Modal show={modal.bol} size="lg" className="modal-danger">
            <Modal.Header>
                <h5 className="modal-title color-secondary">{modal.title}</h5>
                <button type="button" className="close" onClick={() => setModal({ ...modal, bol: !modal?.bol })}>
                    <span className="color-secondary">×</span>
                </button>
            </Modal.Header>
            <Modal.Body style={{width: '100%'}}>
                <Row >
                    <Col>
                        <InputControlled
                            classContainer={'form-group'}
                            classLabel={'form-label fs-6'}
                            textLabel={'Nombre del gasto'}
                            typeBtn={'text'}
                            nameBtn={'title'}
                            styleBtn={"form-control p-form  br-btn-t"}
                            valueBtn={input?.title}
                            setValue={setInput}
                            state={input}
                        />
                    </Col>
                    <Col>
                        <div className='form-group'>
                            <label className='form-label fs-6'>Se paga cada</label>
                            <select
                                onChange={(e) => setInput({
                                    ...input,
                                    pay_every: e.target.value
                                })}
                                value={input.pay_every}
                                className={"custom-select  br-btn-t"}
                            >
                                <option value={undefined} default>Seleccione cada cuanto se paga</option>
                                <option value={'days'}>Diario</option>
                                <option value={'weeks'}>Semanal</option>
                                <option value={'months'}>Mensual</option>
                                <option value={'bimonthly'}>Bimestral</option>
                                <option value={'other'}>Otro</option>
                            </select>
                        </div>
                    </Col>
                    {input?.pay_every === 'other' ? 
                    <Col>
                    
                        <InputControlled
                            classContainer={'form-group'}
                            classLabel={'form-label fs-6'}
                            textLabel={'Descripción'}
                            typeBtn={'text'}
                            nameBtn={'pay_every_other'}
                            styleBtn={"form-control p-form  br-btn-t"}
                            valueBtn={input?.pay_every_other}
                            setValue={setInput}
                            state={input}
                        />
                    </Col>
                    : null}
                </Row>
                <Row>
                    <Col>
                        <InputControlled
                            classContainer={'form-group'}
                            classLabel={'form-label fs-6'}
                            textLabel={'Monto del gasto'}
                            typeBtn={'number'}
                            nameBtn={'value'}
                            styleBtn={"form-control p-form  br-btn-t"}
                            valueBtn={input?.value}
                            setValue={setInput}
                            state={input}
                        />
                    </Col>
                    {/* <Col>
                        <div className='form-group'>
                            <label className='form-label fs-6'>
                                {
                                    (input?.pay_every === undefined)?'¿Cada cuanto paga?':(
                                        (input?.pay_every === 'days')?'¿Cada cuantos días?':(
                                        (input?.pay_every === 'weeks')?'¿Cada cuantas semanas?':(
                                        (input?.pay_every === 'months')?'¿Cada cuantos meses?':'¿Cada cuanto paga?'))
                                    )
                                }
                            </label>
                            <input
                                type='number'
                                name='pay_quantity'
                                className={"form-control p-form  br-btn-t"}
                                value={input.pay_quantity}
                                onChange={e => setInput({ ...input, pay_quantity: e.target.value })}
                            />
                        </div>
                    </Col> */}
                    {input.pay_every === 'days' || !input.pay_every ?
                        <Col></Col>
                        :
                        input.pay_every === 'weeks' ? 
                            <Col>
                                <div className='form-group'>
                                    <label className='form-label fs-6'>Fecha de pago</label>
                                    <select
                                        onChange={(e) => setInput({
                                            ...input,
                                            payment_week: e.target.value
                                        })}
                                        value={input.payment_week}
                                        className={"custom-select  br-btn-t"}
                                    >
                                        <option value={'monday'} default>Lunes</option>
                                        <option value={'tuesday'}>Martes</option>
                                        <option value={'wednesday'}>Miercoles</option>
                                        <option value={'thursday'}>Jueves</option>
                                        <option value={'friday'}>Viernes</option>
                                        <option value={'saturday'}>Sabado</option>
                                        <option value={'sunday'}>Domingo</option>
                                    </select>
                                </div>
                            </Col>
                        :
                            input.pay_every === 'months' ?
                                <Col>
                                    <div className='form-group'>
                                        <label className='form-label fs-6'>Fecha de pago</label>
                                        <select
                                            onChange={(e) => setInput({
                                                ...input,
                                                payment_month: e.target.value
                                            })}
                                            value={input.payment_month}
                                            className={"custom-select  br-btn-t"}
                                        >
                                            {
                                                Array.from({ length: 30 }, (_, i) => i + 1).map((day)=>
                                                    <option value={`${day}`} key={day}>{day}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                </Col>
                            :
                                input.pay_every === 'bimonthly' ?
                                    <Col>
                                        <div className='form-group'>
                                            <label className='form-label fs-6'>Fecha de pago</label>
                                            <select
                                                onChange={(e) => setInput({
                                                    ...input,
                                                    payment_bimonthly: e.target.value
                                                })}
                                                value={input.payment_bimonthly}
                                                className={"custom-select  br-btn-t"}
                                            >
                                                {
                                                    Array.from({ length: 30 }, (_, i) => i + 1).map((day)=>
                                                        <option value={`${day}`} key={day}>{day}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </Col>
                                :
                                    <Col>
                                        <div className='form-group'>
                                            <label className='form-label fs-6'>Fecha de pago</label>
                                            <input
                                                type='date'
                                                name='payment_date'
                                                className={"form-control p-form  br-btn-t"}
                                                value={input.payment_date}
                                                onChange={e => setInput({ ...input, payment_date: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                    }
                </Row>
            </Modal.Body>
            <Modal.Footer className='pl-3 pr-3'>
                <button
                    type="submit"
                    className="btn btn-outline-secondary  br-btn-t mr-1"
                    onClick={() => {
                        setModal({
                            ...modal,
                            bol: false
                        })
                        setInput({
                            title: '',
                            value: '',
                        })
                    }}
                >
                    Cancelar
                </button>
                {
                    !disabled ? (
                        <button
                            type="submit"
                            className="btn background-secondary  br-btn-t color-white"
                            onClick={(e) => {
                                setDisabled(true)
                                setModal(false);
                                onSubmit(e);
                                setInput({
                                    title: '',
                                    value: 0
                                })
                                setTimeout(() => {
                                    setDisabled(false);
                                }, 1000)
                            }}
                        >
                            {
                                modal?.title === "Agregue el nombre del gasto que desea agregar y su monto" ? "Agregar" : "Guardar"
                            }
                        </button>
                    ) : (
                        <button type="submit" className="btn background-secondary  br-btn-t color-white" disabled={true}>
                            <span className="spinner-border spinner-border-sm">
                                <span className="sr-only"></span>
                            </span>
                        </button>
                    )
                }
            </Modal.Footer>
        </Modal>
    )
}
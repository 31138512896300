import React, { useState, useEffect } from 'react';
import TableCommercialData from '../component/TableCommercialData';
import { toast } from 'react-toastify';
import ModalComponent from '../component/Modal';
import ModalCommercialData from '../component/ModalCommercialData';
import CardTotal from '../component/CardTotal';
import { useHistory } from 'react-router';
import { deleteAllCommercial, deleteCommercial, getPayUser } from '../redux/action';
import { useDispatch } from 'react-redux';

export default function CommercialData() {
    const [state, setData] = useState();
    const [search, setSearch] = useState('')
    
    const [page, setPage] = useState(1);
    const history = useHistory();
    const dispatch = useDispatch();
    const [modalDeleteOne, setModalDeleteOne] = useState(false);
    const [modalDelete, setModalDelete] = useState(false)
    const [sumTotal, setSumTotal] = useState(null);
    const [paymentsMonth, setPaymentsMonth] = useState(null);
    const [modal, setModal] = useState({
        bol: false,
        title: 'Agregue el nombre del gasto que desea agregar y su monto'
    });
    const [disabled, setDisabled] = useState(false);
    const [deleteAll, setDeleteAll] = useState();
    const [input, setInput] = useState({
        id: null,
        title: '',
        value: 0,
        pay_every: undefined,
        pay_every_other: '',
        pay_quantity: 1,
        payment_week: null,
        payment_month: null,
        payment_bimonthly: null,
        payment_date: null,
    })

    useEffect(() => {
        dispatch(getPayUser())
    }, [dispatch])

    const [reload, setReload] = useState(false);

    const [id, setId] = useState();

    useEffect(() => {
        setDisabled(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(()=> {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')
        fetch(`${process.env.REACT_APP_URL_API}/api/commertial-expenses/total/`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setSumTotal(json.value__sum)
                setPaymentsMonth(json.amount__monthly)
            })
    }, [reload, search, disabled, page])

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        if ( search?.length >= 1 ) {
            return
        } else {
            fetch(`${process.env.REACT_APP_URL_API}/api/commertial-expenses/?page=${page}`, {
                'method': "GET",
                'headers': myHeaders
            })
                .then(resp => resp?.json())
                .then(json => {
                    if(json.detail) {
                        setPage(page-1)
                    } else {
                        setData(json)
                    }
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload, search, disabled, page])

    const handleSubmit = (e) => {
        e.preventDefault();
        setDisabled(true)
        setTimeout(() => {
            setDisabled(false);
        }, 1500)
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')
        fetch(`${process.env.REACT_APP_URL_API}/api/commertial-expenses/?search=${e.target.value}`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setData(json)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    const onSubmit = (e) => {
        e.preventDefault();

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        const data = {
            name: input.title,
            value: input.value,
            pay_every: input.pay_every,
            pay_every_other: input.pay_every_other,
            pay_quantity: input.pay_quantity,
            payment_week: input.payment_week,
            payment_month: input.payment_month,
            payment_bimonthly: input.payment_bimonthly,
            payment_date: input.payment_date
        }

        if (input.id) {
            // const obj = state.results.filter(x => x.name === input.title)[0]
            fetch(`${process.env.REACT_APP_URL_API}/api/commertial-expenses/${input.id}/`, {
                'method': "PUT",
                'body': JSON.stringify(data),
                'headers': myHeaders
            })
                .then(resp => resp.json())
                .then(json => {
                    if (json.id) {
                        toast.success("Gasto modificado exitosamente", {
                            className: "Toastify__toast--success"
                        });
                        setReload(!reload)
                        setInput({
                            id: null,
                            title: '',
                            value: 0,
                            pay_every: undefined,
                            pay_every_other: '',
                            pay_quantity: 1,
                            payment_week: null,
                            payment_month: null,
                            payment_bimonthly: null,
                            payment_date: null,
                        })
                    }
                })
        } else {
            fetch(`${process.env.REACT_APP_URL_API}/api/commertial-expenses/`, {
                'method': "POST",
                'body': JSON.stringify(data),
                'headers': myHeaders
            })
                .then(resp => resp.json())
                .then(json => {
                    if (json.id) {
                        toast.success("Gasto agregado exitosamente", {
                            className: "Toastify__toast--success"
                        });
                        setReload(!reload)
                        setInput({
                            id: null,
                            title: '',
                            value: 0,
                            pay_every: undefined,
                            pay_every_other: '',
                            pay_quantity: 1,
                            payment_week: null,
                            payment_month: null,
                            payment_bimonthly: null,
                            payment_date: null
                        })
                    }
                })
        }
    }

    const pageData = (e, url) => {
        e.preventDefault();
        setDisabled(true);
        setTimeout(() => {
            setDisabled(false);
        }, 1500)
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${url}`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                setData(json)
            })
    }

    const onFilter = (name) => {
        console.log(name)
    }

    const onEdit = (id) => {
        setInput({
            id: id,
            title: state?.results.filter(x => x.id === id)[0].name,
            value: state?.results.filter(x => x.id === id)[0].value,
            pay_every: state?.results.filter(x => x.id === id)[0].pay_every,
            pay_every_other: state?.results.filter(x => x.id === id)[0].pay_every_other,
            pay_quantity: state?.results.filter(x => x.id === id)[0].pay_quantity,
            payment_week: state?.results.filter(x => x.id === id)[0].payment_week,
            payment_month: state?.results.filter(x => x.id === id)[0].payment_month,
            payment_bimonthly: state?.results.filter(x => x.id === id)[0].payment_bimonthly,
            payment_date: state?.results.filter(x => x.id === id)[0].payment_date,
        })

        setModal({
            bol: !modal.bol,
            title: 'Editar gasto'
        })
    }

    const onDelete = (e) => {
        e.preventDefault();
        setDisabled(true);
        setTimeout(() => {
            setDisabled(false);
        }, 300)

        dispatch(deleteCommercial(id))
    }

    const onDeleteAll = (e) => {
        e.preventDefault();
        setDisabled(true);
        setTimeout(() => {
            setDisabled(false);
        }, 1500)

        dispatch(deleteAllCommercial(deleteAll));
        setDeleteAll([]);
    }

    const onChange = (json) => {
        setReload(!reload)
    }

    return (
        // state && history.location.pathname.includes(`commercial-data`) ? (
        true ? (
            <>
                {
                    modalDeleteOne ? (
                        <ModalComponent
                            open={modalDeleteOne}
                            title={"Eliminar gasto"}
                            body={"¿Esta segura de eliminar este gasto?"}
                            setModal={setModalDeleteOne}
                            onDelete={onDelete}
                        />
                    ) : null
                }

                {
                    modalDelete ? (
                        <ModalComponent
                            open={modalDelete}
                            title={deleteAll && deleteAll?.length === 1 ? "Eliminar gasto" : "Eliminar gastos"}
                            body={deleteAll && deleteAll?.length === 1 ?
                                "¿Esta segura de eliminar este gasto?" :
                                `¿Esta segura de eliminar ${deleteAll?.length} gastos?`
                            }
                            setModal={setModalDelete}
                            onDelete={onDeleteAll}
                        />
                    ) : null
                }
                {
                    modal ? (
                            <ModalCommercialData
                                modal={modal}
                                setModal={setModal}
                                input={input}
                                setInput={setInput}
                                setDisabled={setDisabled}
                                disabled={disabled}
                                onSubmit={onSubmit}
                            />
                    ) : null
                }
                <CardTotal
                    total={sumTotal}
                    totalPayments={paymentsMonth}
                    titlePayments={'Pagos realizados este mes'}
                    // title={'Gastos totales'}
                    title={'Gastos fijos mensuales'}
                    isTwin={true}
                />
                <div className="card mt-1 mb-2">
                    <div className="card-body">
                        <TableCommercialData
                            id={id}
                            setId={setId}
                            modal={modal}
                            search={search}
                            setSearch={setSearch}
                            handleSubmit={handleSubmit}
                            reload={reload}
                            setReload={setReload}
                            setModal={setModal}
                            page={page}
                            setPage={setPage}
                            next={state?.next}
                            previous={state?.previous}
                            pageData={pageData}
                            data={state}
                            onFilter={onFilter}
                            onEdit={onEdit}
                            setModalDelete={setModalDelete}
                            setDeleteAll={setDeleteAll}
                            setModalDeleteOne={setModalDeleteOne}
                            disabled={disabled}
                            onChange={onChange}
                        />
                    </div>
                </div>
            </>
        ) : (
            <>
                {
                    modal ? (
                        <ModalCommercialData
                            modal={modal}
                            setModal={setModal}
                            input={input}
                            setInput={setInput}
                            setDisabled={setDisabled}
                            disabled={disabled}
                            onSubmit={onSubmit}
                        />
                    ) : null
                }
            </>
        )
    )
}
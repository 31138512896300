import React, { useEffect, useState } from 'react';
import { Table, Row, Col, ButtonGroup } from 'react-bootstrap';
import { totalPackaging } from '../utils/functions';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';
import { getPackagingSimple } from '../redux/action';
import { NumericInput } from './NumericInput';

export default function FormGroupItems({ state, setState, group, setGroup, history, back, id, setDisabled, disabled, onSubmit, packaging, errors }) {
    const dispatch = useDispatch();
    const [dataPackaging, setDataPackaging] = useState([])
    useEffect(()=> {
        dispatch(getPackagingSimple(setDataPackaging))
    }, [dispatch])

    React.useEffect(() => {

        if(!group.length) {
            setGroup([
                ...group,
                {
                    id: null,
                    amount: null,
                    name: null,
                    quantity: 0
                },
                {
                    id: null,
                    amount: null,
                    name: null,
                    quantity: 0
                }
            ])
            setState({
                ...state,
                amount: totalPackaging([
                    ...group,
                    {
                        id: null,
                        name: null,
                        amount: null,
                        quantity: 0
                    },
                    {
                        id: null,
                        name: null,
                        amount: null,
                        quantity: 0
                    }
                ])
            })
        }


        return packaging.results = packaging?.results?.filter(x => parseInt(x.id) !== parseInt(id))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDecrement = (e, el) => {
        e.preventDefault()

        // console.log('handleDecrement', e, el)

        // const newGroup = group.slice().filter(x => parseInt(x.id) !== parseInt(el.id))

        // el.quantity--

        // setGroup([
        //     ...newGroup,
        //     el
        // ])

        let newGroup = JSON.parse(JSON.stringify(group))

        // newGroup.map((item) => {
        //     if(parseInt(item.id) === parseInt(el.id))
        //         item.quantity--
        // })

        newGroup[el-1].quantity = newGroup[el-1].quantity - 1
        
        setGroup(newGroup)
    }

    const handleIncrement = (e, el) => {
        e.preventDefault()

        // console.log('handleIncrement 1', group)

        // const newGroup = group.slice().filter(x => parseInt(x.id) !== parseInt(el.id))

        // console.log('handleIncrement 2', newGroup)

        // el.quantity++

        let newGroup = JSON.parse(JSON.stringify(group))

        // newGroup.map((item) => {
        //     if(parseInt(item.id) === parseInt(el.id))
        //         item.quantity++
        // })

        newGroup[el-1].quantity = newGroup[el-1].quantity + 1
        
        setGroup(newGroup)
    }

    // dataPackaging.sort(function (a, b) {
    //     return a.id - b.id;
    // })
    // group.sort(function (a, b) {
    //     return a.id - b.id;
    // })

    // const getSubtotal = (el) => {
    //     let amount = ((el.amount) ? el.amount : el?.packaging?.amount)
    //     let quantity = el.quantity

    //     const details = dataPackaging.find(item => item.id === el.id)

    //     if(details.unit_measurement === 'pk') {
    //         amount = amount / details.unit_measurement_count
    //     }

    //     return amount * quantity
    // }

    const getSubtotal = (el) => {
        const _dataPackaging = dataPackaging.filter(data => parseInt(data?.id) === parseInt(el.packaging?.id))[0]
        console.log('dataPackaging', el, _dataPackaging)

        // console.log('getSubtotal', el, _dataPackaging?.cost_unit_real)
        let value = ((el.amount) ? el.amount : _dataPackaging?.cost_unit_real) * el.quantity

        return isNaN(value) ? 0 : value.toFixed(2)
        // console.log('getSubtotal_', _dataPackaging, el)
    }
    
    let subTotal = 0;
    group?.forEach(element => {
        // const sellPrice = element.packaging?.amount ? element.packaging?.amount : element.amount;
        // const quantity = element.packaging?.quantity ? element.packaging?.quantity : element.quantity;

        // subTotal += sellPrice*quantity

        subTotal += parseFloat(getSubtotal(element))
    });
    subTotal = subTotal.toFixed(2)
    
    useEffect(()=>{
        setState({
            ...state,
            group_amount: subTotal
        })
    }, [subTotal])

    return (
        <>
            <Table responsive style={group && group.length ? { minHeight: "5rem" } : { minHeight: "3rem" }}>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Cantidad</th>
                        <th>Subtotal</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        group?.length ? group?.map((el, i = -1) => {
                            i++
                            return (
                                <tr key={i}>
                                    <th>
                                        <select
                                            value={el.packaging?.id ? el.packaging.id : el.id}
                                            onChange={e => {
                                                const element = dataPackaging.filter(el => parseInt(el.packaging?.id ? el.packaging.id : el.id) === parseInt(e.target.value))[0]

                                                const arr = group.slice()
                                                
                                                
                                                arr[i - 1] = {
                                                    id: element.id,
                                                    amount: element.cost_unit_real,
                                                    name: element.name,
                                                    quantity: element.quantity?element.quantity:1
                                                }

                                                
                                                
                                                setGroup(arr)
                                                setState({
                                                    ...state,
                                                    amount: totalPackaging(arr)
                                                })
                                            }}
                                            className={errors.group.bol ? "custom-select  error-input" : "custom-select "}
                                        >
                                            <option value="" disabled selected>Seleccione un packaging</option>
                                            {
                                                dataPackaging ? dataPackaging.map((pack, key) => {
                                                    return (
                                                        <option key={key} value={pack.id}>{pack.name}</option>
                                                    )
                                                }) : null
                                            }
                                        </select>
                                    </th>
                                    <th style={{ width: "10%"}}>
                                        <ButtonGroup className="btn-group" role="group">
                                            <button className="btn btn-outline-secondary btn-height br-btn-t" disabled={(el.quantity <= 1)?"disabled":""} onClick={(e)=>handleDecrement(e, i)}>-</button>
                                            <button className="btn btn-outline-secondary btn-height br-btn-t" disabled>{el.quantity}</button>
                                            <button className="btn btn-outline-secondary btn-height br-btn-t" disabled={(el.quantity >= 10)?"disabled":""} onClick={(e)=>handleIncrement(e, i)}>+</button>
                                        </ButtonGroup>
                                    </th>
                                    <th style={{ width: "20%" }}>
                                        <NumericInput
                                            value={getSubtotal(el)}
                                            className="form-control p-form br-btn-t"
                                            readOnly={true}
                                        />
                                    </th>
                                    <th style={{ width: "10%", textAlign: "center" }}>
                                        <button
                                            className="btn-disabled"
                                            onClick={e => {
                                                e.preventDefault()

                                                if (id) {
                                                    const newGroup = group.slice().filter(x => parseInt(x.id) !== parseInt(el.id))
                                                    setGroup(newGroup)
                                                } else {
                                                    if (group.length === 1) {
                                                        setState({
                                                            ...state,
                                                            amount: 0
                                                        })
                                                        return setGroup([])
                                                    } else if (group.length === i) {
                                                        setState({
                                                            ...state,
                                                            amount: totalPackaging(group.slice(0, i - 1))
                                                        })
                                                        return setGroup(group.slice(0, i - 1))
                                                    } else {
                                                        const arr1 = group.slice(0, i - 1)
                                                        const arr2 = group.slice(i, group.length)
                                                        setState({
                                                            ...state,
                                                            amount: totalPackaging(arr1.concat(arr2))
                                                        })
                                                        setGroup(arr1.concat(arr2))
                                                    }
                                                }
                                            }}
                                        >
                                            <DeleteIcon className="color-primary-light" />
                                        </button>
                                    </th>
                                </tr>
                            )
                        }) : null
                    }
                </tbody>
            </Table>
            {/* si esta vacía */}
            <Row>
                <Col sm="9"></Col>
                <Col sm="3" className="mb-1">
                    <button
                        className="linkModal mt-1 color-primary-light background-transparent border-none"
                        onClick={(e) => {
                            e.preventDefault();
                            setGroup([
                                ...group,
                                {
                                    id: null,
                                    amount: null,
                                    name: null,
                                    quantity: 0
                                }
                            ])
                            setState({
                                ...state,
                                amount: totalPackaging([
                                    ...group,
                                    {
                                        id: null,
                                        name: null,
                                        amount: null,
                                        quantity: 0
                                    }
                                ])
                            })
                        }}
                    >
                        + Agregar 
                    </button>
                </Col>
            </Row>
            <Row>
                <Col md="6" className="mb-1">
                    <div className="form-group margin-bottom-0" >
                        <label className="form-label fs-6">Total</label>
                        <NumericInput
                            value={subTotal}
                            className="form-control p-form br-btn-t"
                            readOnly={true}
                        />
                    </div>
                </Col>
            </Row>
        </>
    )
}
import React from 'react';
import Modal from 'react-bootstrap/Modal'

export default function ModalComponent({ open, title, body, setModal, onDelete, label='Eliminar' }) {
    const [disabled, setDisabled] = React.useState(false)

    return (
        <Modal show={open} className="modal-danger">
            <Modal.Header>
                <h5 className="modal-title color-primary">{title}</h5>
                <button type="button" className="close color-primary" onClick={() => setModal(false)}>
                    <span>×</span>
                </button>
            </Modal.Header>

            <Modal.Body>
                {body}
            </Modal.Body>

            <Modal.Footer>
                <button type="submit" className="btn btn-outline-secondary btn-height-3 br-btn-t mr-1" onClick={() => setModal(false)}>Cancelar</button>
                {
                    !disabled ? (
                        <button
                            type="submit"
                            className="btn btn-primary btn-height-3 br-btn-t color-white"
                            onClick={(e) => {
                                setDisabled(true)
                                onDelete(e)
                                setModal(false);

                                setTimeout(() => {
                                    setDisabled(false);
                                }, 1500)
                            }}
                        >
                            {label}
                        </button>
                    ) : (
                        <button type="submit" className="btn background-secondary btn-height-3 br-btn-t color-white" disabled={true}>
                            <span className="spinner-border spinner-border-sm">
                                <span className="sr-only"></span>
                            </span>
                        </button>
                    )
                }
            </Modal.Footer>
        </Modal>
    )
}
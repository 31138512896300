import React from 'react'
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { Col, Row, Table } from 'reactstrap'
import Dropdown from './DropDownMenu'

export const TableInvested = ({ data, toEdit, setModal, modal, setId, setDeleteAll, setModalDelete, disabled }) => {
    const [checkbox, setCheckbox] = React.useState({})
    const [all, setAll] = React.useState(false)
    const [modalDetails, setModalDetails] = React.useState(null)

    React.useEffect(() => {
        setCheckbox({})
    }, [])

    const onChecked = (a) => {
        setCheckbox({
            ...checkbox,
            [a]: !checkbox[a]
        })
    }

    const handleDelete = () => {
        if (all) {
            const arr = []
            data.map(el => arr.push(el.id))
            setDeleteAll(arr)
        } else {
            setDeleteAll(Object.keys(checkbox).filter(x => checkbox[x] === true))
        }
        setModalDelete(true)
        return setCheckbox({})
    }

    return (
        <>
            <Row>
                <Col className="mb-1" sm="12">
                    <Table responsive className="align-middle">
                        <thead>
                            <tr>
                                <th className="text-align-center checkboxContainer">
                                    <label className="containerCheckbox">
                                        <input
                                            type="checkbox"
                                            className={
                                                Object.values(checkbox).filter(x => x === true).length === data.length
                                                ? "checked" : ""
                                            }
                                            onClick={() => {
                                                setAll(!all)
                                                const obj = {}
                                                data.map(el => {
                                                    return obj[el.id] = !all
                                                })
                                                setCheckbox(obj)
                                            }}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </th>
                                <th>Fecha</th>
                                <th>Importe</th>
                                {
                                    window.innerWidth < 992 ? (
                                        null
                                    ) : (
                                        <>
                                            <th>Detalles</th>
                                        </>
                                    )
                                }
                                <th>Concepto</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data && data.map((e, i = -1) => {
                                    i++
                                    return (
                                        <tr key={e.id} >
                                            <th className="text-align-center checkboxContainer">
                                                {
                                                    checkbox[e.id] ? (
                                                        <label className="containerCheckbox">
                                                            <input
                                                                type="checkbox"
                                                                className="checked"
                                                                onClick={() => {
                                                                    onChecked(e.id)
                                                                }}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    ) : (
                                                        <label className="containerCheckbox">
                                                            <input
                                                                type="checkbox"
                                                                onClick={() => {
                                                                    onChecked(e.id)
                                                                }}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    )
                                                }
                                            </th>
                                            <th>
                                                <Link to={`/invested/edit/${e.id}`}>
                                                    {e.date}
                                                </Link>
                                            </th>
                                            <th>{e.amount}</th>
                                            {
                                                window.innerWidth < 992 ? (
                                                    null
                                                ) : (
                                                    <>
                                                        <th>{e.details}</th>
                                                    </>
                                                )
                                            }
                                            <th>{e.concept}</th>
                                            <th className="text-end">
                                                <Dropdown 
                                                    setId={setId} toEdit={toEdit} e={e} setModal={setModal} modal={modal} setModalDetails={setModalDetails} />
                                            </th>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </Col>
                {
                    Object.values(checkbox).filter(x => x === true).length ? (
                        <Col>
                            <footer>
                                {
                                    !disabled ? (
                                        <button
                                            className="btn background-secondary br-btn-t color-white color-white"
                                            onClick={() => handleDelete()}
                                        >
                                            Eliminar seleccionados
                                        </button>
                                    ) : (
                                        <button type="submit" className="btn background-secondary btn-height-3 br-btn-t color-white" disabled={true}>
                                            <span className="spinner-border spinner-border-sm">
                                                <span className="sr-only"></span>
                                            </span>
                                        </button>
                                    )
                                }
                            </footer>
                        </Col>
                    ) : null
                }
            </Row>

            <Modal show={modalDetails} className="modal-danger">
                <Modal.Header>
                    <h5 className="modal-title color-primary">Detalles</h5>
                    <button type="button" className="close color-primary" onClick={() => setModalDetails(null)}>
                        <span>×</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <label className="form-label fs-6 font-weight-bold">Concepto:</label>
                    <p>{modalDetails?.concept}</p>
                    <label className="form-label fs-6 font-weight-bold">Detalles:</label>
                    <p>{modalDetails?.details}</p>
                    <label className="form-label fs-6 font-weight-bold">Fecha:</label>
                    <p>{modalDetails?.date}</p>
                </Modal.Body>
            </Modal>
        </>
    )
}

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormProducts from '../component/FormProducts';
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import CreateVendor from './createVendor';
import CreatePackaging from './createPackaging';
import CreateSupplies from './createSupplies';
import CreateSuppliesWorkforce from './createSuppliesWorkforce';
import { getConfig, getMarginSurcharge, getProviders, postProductResale, postProductProduction, getPackaging, createNewProducts, getSuppliesOff, getConfigSupply, getMarginWholesalerSurcharge } from '../redux/action';
import { Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getPayUser } from '../redux/action';

export default function CreateProduct() {
    const history = useHistory();
    // const marginSurcharge = useSelector(store => store.marginSurcharge)
    // const marginWholesalerSurcharge = useSelector(store => store.marginWholesalerSurcharge)
    const dispatch = useDispatch();
    const vendors = useSelector(store => store.providers);
    const [group, setGroup] = useState([])
    const [groupWorkforce, setGroupWorkforce] = useState([])
    const [idVendor, setId] = useState('')
    const [addVendors, setAddVendors] = useState(false);
    const [addSupplies, setAddSupplies] = useState(false);
    const [addSuppliesWorkfoce, setAddSuppliesWorkforce] = useState(false)
    const [addPackaging, setAddPackaging] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const packaging = useSelector(store => store.packaging);
    const supplyConfig = useSelector(store => store?.supplyConfig);
    const [supplyAdded, setSupplyAdded] = useState(null)
    const [supplyWorkforceAdded, setSupplyWorkforceAdded] = useState(null)
    const [packageAdded, setPackageAdded] = useState(null)
    const [supplies, setSupplies] = useState([])
    const [suppliesProduct, setSuppliesProduct] = useState([])
    
    const [state, setState] = useState({
        name: '',
        provider: '',
        is_active: true,
        product_type: 'resale',
        cost_price: 0,
        sale_price: 0,
        packaging_cost: 0,
        contribution_margin: 0,
        images: [],
        current_stock_in_units: 0,
        automatic_price_calculation: true,
        use_manual_pricing: false,
        current_stock_in_cost: 0,
        sale_actual_stock: '',
        ideal_stock_in_units: 0,
        surcharge_unit: 0,
        surcharge_wholesale: null,
        surcharge_minorista_wholesale: null,
        workforce_cost: 0,
        cost_unit_workforce: 0,
        amount_workforce: 0,
        unit_use_workforce: '',
        cost_unit_packaging: 0,
        amount_packaging: 0,
        unit_use_packaging: '',
        wholesaler: false,
        packaging: [],
        supplies: [],
        workforces: [],
        unit_production: '',
        quantity_per_recipe: 1,
        details: '',
        stock_to_be_replenished_in_cost: 0,
        stock_to_be_replenished: 0
    })

    const [errors, setErrors] = useState({
        name: {
            bol: false,
            msg: ''
        },
        cost_price: {
            bol: false,
            msg: ''
        },
        sale_price: {
            bol: false,
            msg: ''
        },
        packaging_cost: {
            bol: false,
            msg: ''
        },
        wholesaler: {
            bol: false,
            msg: ''
        },
        suppliesProduct: {
            bol: false,
            msg: ''
        },
        contribution_margin: {
            bol: false,
            msg: ''
        },
        surcharge: {
            bol: false,
            msg: ''
        },
        surcharge_wholesale: {
            bol: false,
            msg: ''
        },
        surcharge_unit: {
            bol: false,
            msg: ''
        },
        current_stock_in_units: {
            bol: false,
            msg: ''
        },
        group: {
            bol: false,
            msg: ''
        },
        current_stock_in_cost: {
            bol: false,
            msg: ''
        },
        sale_actual_stock: {
            bol: false,
            msg: ''
        },
        ideal_stock_in_units: {
            bol: false,
            msg: ''
        },
        packaging: {
            bol: false,
            msg: ''
        },
        workforce: {
            bol: false,
            msg: ''
        },
        quantity_per_recipe: {
            bol: false,
            msg: ''
        },
    })

    useEffect(() => {
        dispatch(getPayUser())
    }, [dispatch])

    useEffect(() => {
        // GET packaging
        dispatch(getPackaging(false))
        dispatch(getConfigSupply())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addSupplies, addSuppliesWorkfoce])

    useEffect(() => {
        setState({
            ...state,
            provider: idVendor
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idVendor])

    useEffect(() => {
        dispatch(getProviders())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addVendors])

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/supplies/?pagination=false`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setSupplies({results: json})
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addSupplies, addSuppliesWorkfoce])

    useEffect(() => {
        // dispatch(getMarginSurcharge(function(value) {
        //     setTimeout(() => {
        //         setState({...state, surcharge_wholesale: value})
        //     }, 200)
        // }))
        // dispatch(getMarginWholesalerSurcharge(function(value) {
        //     setTimeout(() => {
        //         setState({...state, surcharge_minorista_wholesale: value})
        //     }, 300)
        // }))
        dispatch(getConfig(function(data) {
            setState({
                ...state,
                surcharge_wholesale: data['margin_surcharge'],
                surcharge_minorista_wholesale: data['margin_wholesaler_surcharge'],
                tax: data['tax'] ? parseFloat(data['tax']) : 0
            })
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSubmit = (e) => {
        e.preventDefault();

        let _state = JSON.parse(JSON.stringify(state))

        const allOkey = _state.provider
        dispatch(createNewProducts(allOkey))
        
        const err = {
            suppliesProduct: {
                bol: false,
                msg: ''
            },
            groupErrorWorkforce: {
                bol: false,
                msg: ''
            },
            group: {
                bol: false,
                msg: ''
            }
        }

        const arr = [];

        if (_state.product_type === "resale") {
            Object.keys(_state).map(x => {
                if (
                    x === "name"
                ) {
                    if (!_state[x]) {
                        return err[x] = {
                            bol: true,
                            msg: 'Este campo no puede estar vacío'
                        };
                    } else {
                        return err[x] = {
                            bol: false,
                            msg: ''
                        };
                    }
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    };
                }
            })

            setErrors(err);
        } else {
            if (!suppliesProduct.length) {
                err['suppliesProduct'] = {
                    bol: true,
                    type: 'empty',
                    msg: 'Debe seleccionar al menos un insumo'
                }
            }
            else {
                suppliesProduct.forEach((item) => {
                    if(item.supply == '') {
                        err['suppliesProduct'] = {
                            bol: true,
                            type: 'empty',
                            msg: 'Debe seleccionar el insumo'
                        }
                        toast.error(err['suppliesProduct']['msg'], {
                            className: "Toastify__toast--error"
                        })
                    }
                    else if(item.unit_use == '') {
                        err['suppliesProduct'] = {
                            bol: true,
                            type: 'empty',
                            msg: 'Debe seleccionar la unidad de uso del insumo'
                        }
                        toast.error(err['suppliesProduct']['msg'], {
                            className: "Toastify__toast--error"
                        })
                    }
                })
            }
            
            if (!groupWorkforce.length) {
                err['groupErrorWorkforce'] = {
                    bol: true,
                    msg: 'Debe seleccionar al menos una mano de obra'
                }
            }
            else {
                groupWorkforce.forEach((item) => {
                    if(item.id == '') {
                        err['groupErrorWorkforce'] = {
                            bol: true,
                            msg: 'Debe seleccionar la mano de obra'
                        }
                        toast.error(err['groupErrorWorkforce']['msg'], {
                            className: "Toastify__toast--error"
                        })
                    }
                })
            }

            if (!group.length) {
                err['group'] = {
                    bol: true,
                    msg: 'Debe seleccionar al menos un packaging'
                }
            }
            else {
                group.forEach((item) => {
                    if(item.id == '') {
                        err['group'] = {
                            bol: true,
                            msg: 'Debe seleccionar al menos un packaging'
                        }
                        toast.error(err['group']['msg'], {
                            className: "Toastify__toast--error"
                        })
                    }
                })
            }

            Object.keys(_state).map(x => {
                if (
                    x === "name" ||
                    x === "cost_price" ||
                    x === "sale_price"
                ) {
                    if (!_state[x]) {
                        return err[x] = {
                            bol: true,
                            msg: 'Este campo no puede estar vacío'
                        };
                    } else {
                        return err[x] = {
                            bol: false,
                            msg: ''
                        };
                    }
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    };
                }
            })

            setErrors(err);

            if (group.length) {
                group.map(el => {
                    return arr.push(el.id)
                })
                
            }
        }

        if(suppliesProduct.length >= 1) {
            const arr23 = []
            suppliesProduct.map(el => arr23.push({
                supply_id: el.supply,
                quantity: el.amount_used?parseInt(el.amount_used):1,
                unit_measurement: el.unit_use
            }))
            arr23.map(el => _state.supplies.push(el))
        }
        if(groupWorkforce.length >= 1) {
            const arr24 = []
            groupWorkforce.map(el => arr24.push({
                workforce_id: el.id,
                quantity: parseInt(el.quantity)
            }))
            arr24.map(el => _state.workforces.push(el))
        }

        if(group.length >= 1) {
            const arr25 = []
            _state.packaging = []

            group.map(el => arr25.push({
                packaging_id: el.id,
                quantity: parseInt(el.quantity_group)
            }))
            
            arr25.map(el => _state.packaging.push(el))
        }

        if (Object.values(err).filter(x => x.bol === true).length === 0) {
            setDisabled(true)
            setTimeout(() => {
                setDisabled(false);
            }, 1500)
            if (_state.product_type === "resale") {
                dispatch(postProductResale(_state, (data) => {
                    history.push('/products')
                    toast.success('Producto creado exitosamente', {
                        className: "Toastify__toast--success"
                    })
                }))
            } else {
                dispatch(postProductProduction(_state, () => {
                    history.push('/products')
                    toast.success('Producto creado exitosamente', {
                        className: "Toastify__toast--success"
                    })
                }))
            }
        }
    }


    return (
        <>
            {
                <Modal show={addVendors} className="modal-create">
                    <Modal.Header>
                        <h5 className="modal-title color-primary">{'Agregar proveedor'}</h5>
                        <button type="button" className="close color-primary" onClick={() => setAddVendors(false)}>
                            <span>×</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <CreateVendor setAddVendors={setAddVendors} addVendors={addVendors} setId={setId} />
                    </Modal.Body>
                </Modal>
            }
            {
                <Modal show={addPackaging} className="modal-create">
                    <Modal.Header>
                        <h5 className="modal-title color-primary">{'Agregar packaging'}</h5>
                        <button type="button" className="close color-primary" onClick={() => setAddPackaging(false)}>
                            <span>×</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <CreatePackaging setPackageAdded={setPackageAdded} setAddPackaging={(e) => [setAddPackaging(e), dispatch(getPackaging(false))]}/>
                    </Modal.Body>
                </Modal>
            }
            {
                <Modal show={addSupplies} className="modal-create">
                    <Modal.Header>
                        <h5 className="modal-title color-primary">{'Agregar insumo'}</h5>
                        <button type="button" className="close color-primary" onClick={() => setAddSupplies(false)}>
                            <span>×</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <CreateSupplies setSupplyAdded={setSupplyAdded} setAddSupplies={setAddSupplies} />
                    </Modal.Body>
                </Modal>
            }
            {
                <Modal show={addSuppliesWorkfoce} className="modal-create">
                    <Modal.Header>
                        <h5 className="modal-title color-primary">{'Agregar mano de obra'}</h5>
                        <button type="button" className="close color-primary" onClick={() => setAddSuppliesWorkforce(false)}>
                            <span>×</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <CreateSuppliesWorkforce setSupplyWorkforceAdded={setSupplyWorkforceAdded} setAddSuppliesWorkforce={(e) => [setAddSuppliesWorkforce(e)]} />
                    </Modal.Body>
                </Modal>
            }
            <FormProducts
                title={"Agregar producto"}
                state={state}
                setState={setState}
                errors={errors}
                back={'/products'}
                onSubmit={onSubmit}
                vendors={vendors}
                setAddVendors={setAddVendors}
                addVendors={addVendors}
                idVendor={idVendor}
                setDisabled={setDisabled}
                disabled={disabled}
                setSuppliesProduct={setSuppliesProduct}
                suppliesProduct={suppliesProduct}
                supplies={supplies}
                group={group}
                setGroup={setGroup}
                packaging={packaging}
                setAddPackaging={setAddPackaging}
                addPackaging={addPackaging}
                setAddSupplies={setAddSupplies}
                setAddSuppliesWorkforce={setAddSuppliesWorkforce}
                supplyConfig={supplyConfig}
                setGroupWorkforce={setGroupWorkforce}
                groupWorkforce={groupWorkforce}
                supplyAdded={supplyAdded}
                setSupplyAdded={setSupplyAdded}
                supplyWorkforceAdded={supplyWorkforceAdded}
                setSupplyWorkforceAdded={setSupplyWorkforceAdded}
                packageAdded={packageAdded}
                setPackageAdded={setPackageAdded}
            />
        </>
    )
}
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';
import ContainerFiles from './ContainerFiles';
import InputControlled from './InputControlled';
import FormReSale from './FormReSale';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FormProduction from './FormProduction';
import { useSelector } from 'react-redux';
import { Tooltip } from 'reactstrap';
import { toast } from 'react-toastify';

export default function FormProducts({ addPackaging, setGroupWorkforce, groupWorkforce, supplyConfig, group, setGroup, title, state, errors, setState, back, onSubmit, vendors, setAddVendors, addVendors, idVendor, disabled, supplies, setSuppliesProduct, suppliesProduct, setModal, deleteSupplies, packaging, setAddPackaging, setAddSupplies, setAddSuppliesWorkforce, deleteWorkforce, setSupplyAdded, supplyAdded, supplyWorkforceAdded, setSupplyWorkforceAdded, packageAdded, setPackageAdded}) {
    const [help, setHelp] = React.useState('');
    const history = useHistory();
    const { id } = useParams();
    const [error, setError] = useState(true)

    const userIsPayment = useSelector(stee => stee?.userPremiumPay)

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/product-supplies/?product=${id}`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                if(json?.results?.length === 0) {
                    setError(false)
                    return
                } else {
                    return
                }
            })
    }, [id])

    const onClickReventa = () => {
        if(id !== undefined) {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
            myHeaders.append("Content-Type", 'application/json')

            fetch(`${process.env.REACT_APP_URL_API}/api/product-supplies/?product=${id}`, {
                'method': "GET",
                'headers': myHeaders
            })
                .then(resp => resp?.json())
                .then(json => {
                    if(json?.results?.length >= 1) {
                        
                        toast.error('Debes eliminar los productos de insumos que existen', {
                            className: "Toastify__toast--error"
                        })
                        return
                    } 

                    setError(false)
                    setState({ ...state, product_type: "resale" })
                })
        }else {
            setError(false)
            setState({ ...state, product_type: "resale" })
            return
        }
    }

    return (
        <>
            <form>
                <Row className="mb-1">
                    <Col xl="12">
                        <Row className="mb-1">
                            <Col>
                                <div className="card">
                                    <div className="card-header mt-1">
                                        <div className="card-title">
                                            <h2>
                                                {title}
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="card-body" >
                                        <InputControlled
                                            classContainer={'form-group mb-1'}
                                            classLabel={'form-label fs-6'}
                                            textLabel={'Nombre'}
                                            typeBtn={'text'}
                                            nameBtn={'name'}
                                            styleBtn={errors.name.bol ?
                                                "form-control p-form  br-btn-t error-input" :
                                                "form-control p-form  br-btn-t"
                                            }
                                            valueBtn={state.name}
                                            setValue={setState}
                                            state={state}
                                            read={history.location.pathname.includes('view') ?
                                                true : undefined
                                            }
                                            classError={errors.name.bol ?
                                                "invalid-input" :
                                                "valid-input"
                                            }
                                            textError={errors.name.msg}
                                        />

                                        {!userIsPayment?.plan?.is_free && id ? <p>Tipo de producto: <b>{state?.product_type === "resale" ? "Reventa" : "Producción"}</b></p> : null}
                                        
                                        {!userIsPayment?.plan?.is_free && !id ?
                                        <>
                                        {
                                            (supplyConfig === 'false')?null:(
                                                history.location.pathname.includes('view') ? (
                                                    <div className="d-flex align-items-center width-50 justify-content-flex-start">
                                                        <div
                                                            className="form-group d-flex align-items-center width-auto margin-bottom-0 margin-0"
                                                        >
                                                            <div className="custom-control-primary custom-control custom-radio">
                                                                <input name="product_type" id='check-1' type="radio" className="custom-control-input" />
                                                                <label
                                                                    htmlFor='check-1'
                                                                    className="custom-control-label checked"
                                                                    readOnly
                                                                >
                                                                    {
                                                                        state?.product_type === "resale" ? "Reventa" : "Producción"
                                                                    }
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : state.product_type === "resale" ? (
                                                    <div className="d-flex align-items-center width-50 justify-content-flex-start">
                                                        <div
                                                            className="form-group d-flex align-items-center width-auto margin-bottom-0 margin-0"
                                                        >
                                                            <div className="custom-control-primary custom-control custom-radio">
                                                                <input defaultChecked={state?.product_type === "resale" ? true : false} name="product_type" id='check-2' type="radio" className="custom-control-input" />
                                                                <label
                                                                    htmlFor='check-2'
                                                                    className="custom-control-label checked"
                                                                    disabled={(error)?'disabled':''}
                                                                    onClick={onClickReventa}
                                                                >
                                                                    {
                                                                        state?.product_type === "resale" ? "Reventa" : "Producción"
                                                                    }
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="form-group d-flex align-items-center margin-bottom-0 width-auto margin-0-1"
                                                        >
                                                            <div className="custom-control-primary custom-control custom-radio">
                                                                <input defaultChecked={state?.product_type === "production" ? true : false} name="product_type" id='check-3' type="radio" className="custom-control-input" />
                                                                <label
                                                                    htmlFor='check-3'
                                                                    className="custom-control-label"
                                                                    onClick={() => {
                                                                        setState({ ...state, product_type: "production" })
                                                                    }}
                                                                >
                                                                    Producción
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="d-flex align-items-center width-50 justify-content-flex-start">
                                                        <div
                                                            className="form-group d-flex align-items-center width-auto margin-bottom-0 margin-0"
                                                        >
                                                            <div className="custom-control-primary custom-control custom-radio">
                                                                <input defaultChecked={state?.product_type === "resale" ? true : false} name="product_type" id='check-4' type="radio" className="custom-control-input" />
                                                                <label
                                                                    htmlFor='check-4'
                                                                    className="custom-control-label"
                                                                    onClick={onClickReventa}
                                                                >
                                                                    Reventa
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="form-group d-flex align-items-center margin-bottom-0 width-auto margin-0-1"
                                                        >
                                                            <div className="custom-control-primary custom-control custom-radio">
                                                                <input defaultChecked={state?.product_type === "production" ? true : false}  name="product_type" id='check-5' type="radio" className="custom-control-input" />
                                                                <label
                                                                    htmlFor='check-5'
                                                                    className="custom-control-label checked"
                                                                    onClick={() => {
                                                                        if (!history.location.pathname.includes('products/view')) {
                                                                            setState({ ...state, product_type: "production" })
                                                                        }
                                                                    }}>
                                                                    {
                                                                        state?.product_type === "resale" ? "Reventa" : "Producción"
                                                                    }
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )
                                        }
                                        </>
                                        : null}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col>
                                <div className="card">
                                    <div className="card-body">
                                        <Row>
                                            {!userIsPayment?.plan?.is_free ?
                                            <Col xl="12" className="mb-1">
                                                <div>
                                                    <div>
                                                        <div className="form-group margin-bottom-0">
                                                            <label className="form-label fs-6">Estado del producto para la venta</label>
                                                            {
                                                                history.location.pathname.includes('/products/view') ? (
                                                                    <input
                                                                        type={'text'}
                                                                        className="form-control p-form  br-btn-t btn-disabled"
                                                                        value={state.is_active ? state.is_active : 'Activo'}
                                                                        readOnly
                                                                    />
                                                                ) : (
                                                                    <select
                                                                        onChange={e => setState({ ...state, is_active: e.target.value })}
                                                                        value={state.is_active}
                                                                        className="custom-select "
                                                                    >
                                                                        <option value={true} default>Activo</option>
                                                                        <option value={false}>No activo</option>
                                                                    </select>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            : null}

                                            {/* <Row className="mb-1 venta-mayorista">
                                                <Col>
                                                {
                                                    (state.product_type === 'resale') ? (
                                                        <>
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="form-group margin-bottom-1">
                                                                        <label className="form-label fs-6">Venta mayorista</label>
                                                                        {
                                                                            history.location.pathname.includes('view') ? (
                                                                                <input
                                                                                    type={'text'}
                                                                                    className="form-control p-form  br-btn-t btn-disabled"
                                                                                    value={state.wholesaler ? true : false}
                                                                                    readOnly
                                                                                />
                                                                            ) : (
                                                                                <>
                                                                                    <div className="d-flex align-items-center width-50 justify-content-flex-start">
                                                                                        <div
                                                                                            className="form-group d-flex align-items-center width-auto margin-bottom-0 margin-0"
                                                                                        >
                                                                                            <div className="custom-control-primary custom-control custom-radio">
                                                                                                <input id='check-7' name='wholesaler' type="radio" className={`custom-control-input ${state.wholesaler === true?'checked':''}`} defaultChecked={state.wholesaler === true}/>
                                                                                                <label
                                                                                                    htmlFor='check-7'
                                                                                                    className={`custom-control-label ${state.wholesaler === true?'checked':''}`}
                                                                                                    onClick={() => {
                                                                                                        setState({ ...state, wholesaler: true })
                                                                                                    }}
                                                                                                >
                                                                                                    Si
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            className="form-group d-flex align-items-center margin-bottom-0 width-auto margin-0-1"
                                                                                        >
                                                                                            <div className="custom-control-primary custom-control custom-radio">
                                                                                                <input id='check-6' name='wholesaler' type="radio" className={`custom-control-input ${state.wholesaler === false?'checked':''}`} defaultChecked={state.wholesaler === false}/>
                                                                                                <label
                                                                                                    htmlFor='check-6'
                                                                                                    className={`custom-control-label ${state.wholesaler === false?'checked':''}`}
                                                                                                    onClick={() => {
                                                                                                        setState({ ...state, wholesaler: false })
                                                                                                    }}
                                                                                                >
                                                                                                    No
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div className="form-group margin-bottom-1" style={{display: state.wholesaler === true ? 'block' : 'none'}}>
                                                                        <label className="form-label fs-6" for="surcharge_wholesale">Márgen de recargo mayorista</label>
                                                                        <HelpOutlineIcon
                                                                            disabled
                                                                            style={{ marginLeft: "1rem", fontSize: "large" }}
                                                                            onClick={()=> {
                                                                                setHelp('provider')
                                                                                setTimeout(() => {
                                                                                    setHelp('')
                                                                                }, 1500)
                                                                                return
                                                                            }}
                                                                            onMouseEnter={() => {
                                                                                setHelp('provider')
                                                                            }}
                                                                            onMouseLeave={() => {
                                                                                setHelp('')
                                                                            }}
                                                                            id="provider"
                                                                        />
                                                                        <Tooltip placement="right" isOpen={help === "provider"} target="provider">
                                                                            Este valor se utilizará para calcular tus precios de venta.
                                                                        </Tooltip>
                                                                        {
                                                                            history.location.pathname.includes('products/view') ? (
                                                                                <input
                                                                                    type={'text'}
                                                                                    className="form-control p-form  br-btn-t btn-disabled"
                                                                                    defaultValue={state.surcharge_wholesale}
                                                                                    value={state.surcharge_wholesale}
                                                                                    readOnly
                                                                                />
                                                                            ) : (
                                                                                <input
                                                                                    type={'text'}
                                                                                    className={errors.surcharge_wholesale.bol ?
                                                                                        "form-control p-form  br-btn-t error-input" :
                                                                                        "form-control p-form  br-btn-t"
                                                                                    }
                                                                                    defaultValue={state.surcharge_wholesale}
                                                                                    value={state.surcharge_wholesale}
                                                                                    onChange={e => {
                                                                                        setState({
                                                                                            ...state,
                                                                                            surcharge_wholesale: e.target.value,
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            )
                                                                        }
                                                                        <div className={errors.surcharge_wholesale.bol ? "invalid-input" : "valid-input"}>
                                                                            {errors.surcharge_wholesale.msg}
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group margin-bottom-0" >
                                                                        <div className='d-flex align-items-start'>
                                                                            <label className="form-label fs-6">Márgen de recargo minorista</label>
                                                                            <HelpOutlineIcon
                                                                                disabled
                                                                                style={{ marginLeft: "1rem", fontSize: "large" }}
                                                                                onClick={()=> {
                                                                                    setHelp('margen_recargo_minorista')
                                                                                    setTimeout(() => {
                                                                                        setHelp('')
                                                                                    }, 1500)
                                                                                    return
                                                                                }}
                                                                                onMouseEnter={() => {
                                                                                    setHelp('margen_recargo_minorista')
                                                                                }}
                                                                                onMouseLeave={() => {
                                                                                    setHelp('')
                                                                                }}
                                                                                id="margen_recargo_minorista"
                                                                            />
                                                                        </div>
                                                                        <Tooltip placement="right" isOpen={help === "margen_recargo_minorista"} target="margen_recargo_minorista">
                                                                            Este valor se utilizará para calcular tus precios de venta.
                                                                        </Tooltip>
                                                                        <input
                                                                            type={'number'}
                                                                            className={"form-control p-form  br-btn-t"}
                                                                            defaultValue={state.surcharge_minorista_wholesale}
                                                                            onChange={e => {
                                                                                setState({
                                                                                    ...state,
                                                                                    surcharge_minorista_wholesale: e.target.value,
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ):(
                                                        <>
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="form-group margin-bottom-1">
                                                                        <label className="form-label fs-6">Venta mayorista</label>
                                                                        {
                                                                            history.location.pathname.includes('view') ? (
                                                                                <input
                                                                                    type={'text'}
                                                                                    className="form-control p-form  br-btn-t btn-disabled"
                                                                                    value={state.wholesaler ? "Sí" : "No"}
                                                                                    readOnly
                                                                                />
                                                                            ) : (
                                                                                <>
                                                                                    <div className="d-flex align-items-center width-50 justify-content-flex-start">
                                                                                        <div
                                                                                            className="form-group d-flex align-items-center width-auto margin-bottom-0 margin-0"
                                                                                        >
                                                                                            <div className="custom-control-primary custom-control custom-radio">
                                                                                                <input type="radio" className={`custom-control-input ${state.wholesaler === true?'checked':''}`} defaultChecked={state.wholesaler === true}/>
                                                                                                <label
                                                                                                    className={`custom-control-label ${state.wholesaler === true?'checked':''}`}
                                                                                                    onClick={() => {
                                                                                                        setState({ ...state, wholesaler: true })
                                                                                                    }}
                                                                                                >
                                                                                                    Si
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            className="form-group d-flex align-items-center margin-bottom-0 width-auto margin-0-1"
                                                                                        >
                                                                                            <div className="custom-control-primary custom-control custom-radio">
                                                                                                <input type="radio" className={`custom-control-input ${state.wholesaler === false?'checked':''}`} defaultChecked={state.wholesaler === false}/>
                                                                                                <label
                                                                                                    className={`custom-control-label ${state.wholesaler === false?'checked':''}`}
                                                                                                    onClick={() => {
                                                                                                        setState({ ...state, wholesaler: false })
                                                                                                    }}
                                                                                                >
                                                                                                    No
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div className="form-group margin-bottom-1" style={{display: state.wholesaler === true ? 'block' : 'none'}}>
                                                                        <label className="form-label fs-6">Márgen de recargo mayorista</label>
                                                                        <HelpOutlineIcon
                                                                            disabled
                                                                            style={{ marginLeft: "1rem", fontSize: "large" }}
                                                                            onClick={()=> {
                                                                                setHelp('provider')
                                                                                setTimeout(() => {
                                                                                    setHelp('')
                                                                                }, 1500)
                                                                                return
                                                                            }}
                                                                            onMouseEnter={() => {
                                                                                setHelp('provider')
                                                                            }}
                                                                            onMouseLeave={() => {
                                                                                setHelp('')
                                                                            }}
                                                                            id="provider"
                                                                        />
                                                                        <Tooltip placement="right" isOpen={help === "provider"} target="provider">
                                                                            Este valor se utilizará para calcular tus precios de venta.
                                                                        </Tooltip>
                                                                        {
                                                                            history.location.pathname.includes('products/view') ? (
                                                                                <input
                                                                                    type={'text'}
                                                                                    className="form-control p-form  br-btn-t btn-disabled"
                                                                                    defaultValue={state.surcharge_wholesale}
                                                                                    value={state.surcharge_wholesale}
                                                                                    readOnly
                                                                                />
                                                                            ) : (
                                                                                <input
                                                                                    type={'text'}
                                                                                    className={errors.surcharge_wholesale.bol ?
                                                                                        "form-control p-form  br-btn-t error-input" :
                                                                                        "form-control p-form  br-btn-t"
                                                                                    }
                                                                                    defaultValue={state.surcharge_wholesale}
                                                                                    value={state.surcharge_wholesale}
                                                                                    onChange={e => {
                                                                                        setState({
                                                                                            ...state,
                                                                                            surcharge_wholesale: e.target.value,
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            )
                                                                        }
                                                                        <div className={errors.surcharge_wholesale.bol ? "invalid-input" : "valid-input"}>
                                                                            {errors.surcharge_wholesale.msg}
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group margin-bottom-0" >
                                                                        <div className='d-flex align-items-start'>
                                                                            <label className="form-label fs-6">Márgen de recargo minorista</label>
                                                                            <HelpOutlineIcon
                                                                                style={{ marginLeft: "1rem", fontSize: "large" }}
                                                                                onClick={()=> {
                                                                                    setHelp('margen_recargo_minorista')
                                                                                    setTimeout(() => {
                                                                                        setHelp('')
                                                                                    }, 1500)
                                                                                    return
                                                                                }}
                                                                                onMouseEnter={() => {
                                                                                    setHelp('margen_recargo_minorista')
                                                                                }}
                                                                                onMouseLeave={() => {
                                                                                    setHelp('')
                                                                                }}
                                                                                id="margen_recargo_minorista"
                                                                            />
                                                                        </div>
                                                                        <Tooltip placement="right" isOpen={help === "margen_recargo_minorista"} target="margen_recargo_minorista">
                                                                            Este valor se utilizará para calcular tus precios de venta.
                                                                        </Tooltip>
                                                                        <input
                                                                            type={'number'}
                                                                            className={"form-control p-form  br-btn-t"}
                                                                            defaultValue={state.surcharge_minorista_wholesale}
                                                                            value={state.surcharge_minorista_wholesale}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                </Col>
                                            </Row> */}
                                            
                                            {
                                                state.product_type === "resale" ? (
                                                    <>
                                                        <Col xl="12" className="mb-1">
                                                            <div>
                                                                <div>
                                                                    <div className="form-group margin-bottom-0" >
                                                                        <label className="form-label fs-6">Código de barra</label>
                                                                    </div>
                                                                    {
                                                                        history.location.pathname.includes('products/view') ? (
                                                                            <input
                                                                                type={'text'}
                                                                                className="form-control p-form  br-btn-t btn-disabled"
                                                                                value={state.code}
                                                                                readOnly
                                                                            />
                                                                        ) : (
                                                                            <input
                                                                                type={'text'}
                                                                                className="form-control p-form  br-btn-t"
                                                                                value={state.code}
                                                                                onChange={e => {
                                                                                    setState({
                                                                                        ...state,
                                                                                        code: e.target.value
                                                                                    })
                                                                                }}
                                                                            />
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Col>

                                                        {!userIsPayment?.plan?.is_free ?
                                                        <Col xl="12" className="mb-1">
                                                            <div className="d-flex flex-direction-column">
                                                                <div>
                                                                    <div>
                                                                        <div className="form-group margin-bottom-0" >
                                                                            <label className="form-label fs-6">Proveedor</label>
                                                                            {
                                                                                history.location.pathname.includes('products/view') ? (
                                                                                    <input
                                                                                        type={'text'}
                                                                                        className="form-control p-form  br-btn-t btn-disabled"
                                                                                        value={state.provider}
                                                                                        readOnly
                                                                                    />
                                                                                ) : (
                                                                                    <>
                                                                                        <select
                                                                                            onChange={(e) => setState({
                                                                                                ...state,
                                                                                                provider: e.target.value
                                                                                            })}
                                                                                            value={state.provider ? state.provider : undefined}
                                                                                            className="custom-select "
                                                                                        >
                                                                                            <option value={''} disabled selected>Seleccione un proveedor</option>
                                                                                            {
                                                                                                vendors.results ? vendors.results.map(el => {
                                                                                                    return (
                                                                                                        el.id === idVendor ? (
                                                                                                            <option key={el.id} default value={el.id}>{el.name}</option>
                                                                                                        ) : (
                                                                                                            <option key={el.id} value={el.id}>{el.name}</option>
                                                                                                        )
                                                                                                    )
                                                                                                }) : null
                                                                                            }
                                                                                        </select>
                                                                                        <button
                                                                                            type='button'
                                                                                            className="linkModal mt-1 color-primary background-transparent border-none"
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                setAddVendors(!addVendors)
                                                                                            }}
                                                                                        >
                                                                                            + Agregar proveedor
                                                                                        </button>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        : null}
                                                    </>
                                                ) : null
                                            }
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        {!userIsPayment?.plan?.is_free ?
                        <Row className="mb-1">
                            <Col>
                                <div className="card" style={{height: '100%'}}>
                                    <div className="card-body">
                                        <div className="form-group mb-1 margin-bottom-0" >
                                            <label className="form-label fs-6">Imágenes</label>
                                        </div>
                                        <div className="d-flex">
                                            <Row className={window.innerWidth > 991 ? "" : "d-flex"}>
                                                <ContainerFiles
                                                    classContainer={window.innerWidth > 991 ?
                                                        "avatar-products col-md-3 margin1 flex-direction-column min-width-10" :
                                                        "avatar-products margin1 flex-direction-column min-width-10"
                                                    }
                                                    classChild={"custom-file b-form-file width-100"}
                                                    classBtn={"custom-file-label"}
                                                    classIcon={"width-50-porcentaje height-20-porcentaje  z-index-10"}
                                                    setState={setState}
                                                    state={state}
                                                />
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        : null}

                        {
                            state.product_type === "resale" ? (
                                <FormReSale
                                    state={state}
                                    setState={setState}
                                    errors={errors}
                                    setHelp={setHelp}
                                    help={help}
                                    history={history}
                                    group={group}
                                    setGroup={setGroup}
                                    suppliesProduct={suppliesProduct}
                                    packaging={packaging}
                                    setAddPackaging={setAddPackaging}
                                    id={id}
                                />
                            ) : (
                                <FormProduction
                                    state={state}
                                    setState={setState}
                                    errors={errors}
                                    setHelp={setHelp}
                                    help={help}
                                    history={history}
                                    supplies={supplies}
                                    setSuppliesProduct={setSuppliesProduct}
                                    suppliesProduct={suppliesProduct}
                                    deleteSupplies={deleteSupplies}
                                    group={group}
                                    setGroup={setGroup}
                                    id={id}
                                    packaging={packaging}
                                    setAddPackaging={setAddPackaging}
                                    addPackaging={addPackaging}
                                    setAddSupplies={setAddSupplies}
                                    setAddSuppliesWorkforce={setAddSuppliesWorkforce}
                                    groupWorkforce={groupWorkforce}
                                    setGroupWorkforce={setGroupWorkforce}
                                    deleteWorkforce={deleteWorkforce}
                                    supplyAdded={supplyAdded}
                                    setSupplyAdded ={setSupplyAdded}
                                    supplyWorkforceAdded={supplyWorkforceAdded}
                                    setSupplyWorkforceAdded={setSupplyWorkforceAdded}
                                    packageAdded={packageAdded}
                                    setPackageAdded={setPackageAdded}
                                />
                            )
                        }                        
                    </Col>
                </Row >
                <Row className="mb-1" >
                    <div
                        className={window.innerWidth > 991 ? "col" : null}
                        style={
                            history.location.pathname.includes('view') ?
                                { display: "flex", justifyContent: "space-between" } :
                                window.innerWidth < 992 ? { width: "75%" } :
                                    null
                        }
                    >
                        {
                            id ? (
                                <>
                                    <Link to={back}>
                                        <button type="submit" className="btn btn-outline-secondary  br-btn-t mr-1">Cancelar</button>
                                    </Link>
                                    <button
                                        className="btn background-primary  br-btn-t color-white"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setModal(true)
                                        }}
                                    >
                                        {state.is_active ? 'Desactivar' : 'Activar'}
                                    </button>
                                </>
                            ) : null
                        }
                    </div>
                    {
                        history.location.pathname.includes('view') ?
                            null :
                            (
                                <div
                                    style={
                                        window.innerWidth > 991 ?
                                            null : { width: "25%" }
                                    }
                                    className={
                                        window.innerWidth > 991 ?
                                            "col d-flex justify-content-flex-end" :
                                            "d-flex justify-content-flex-end"
                                    }
                                >
                                    {
                                        !id ? (
                                            <Link to={back}>
                                                <button type='button' className="btn btn-outline-secondary  br-btn-t mr-1">Cancelar</button>
                                            </Link>
                                        ) : null
                                    }
                                    {
                                        !disabled ? (
                                            <button
                                                type="submit"
                                                id='save-btn'
                                                className="btn background-secondary  br-btn-t color-white"
                                                onClick={e => {
                                                    onSubmit(e)
                                                }}
                                            >Guardar</button>
                                        ) : (
                                            <button type="button" className="btn background-secondary  br-btn-t color-white" disabled={true}>
                                                <span className="spinner-border spinner-border-sm">
                                                    <span className="sr-only"></span>
                                                </span>
                                            </button>
                                        )
                                    }
                                </div>
                            )
                    }
                </Row >
            </form >
        </>
    )
}